import { Dialog, DialogContent, DialogTitle, List, ListItem } from '@mui/material';
import { Container } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import explore from '../../assets/explore.png';
import tool_image_11 from '../../assets/soil_organic.png';
import tool_image_12 from '../../assets/soil_seq_img.png';
import tool_image_2 from '../../assets/economical_benefits.jpg';
import tool_image_3 from '../../assets/regenerative_farms.png';
import tool_image_4 from '../../assets/reg-survey.jpg';
import tool_image_32 from '../../assets/map-home2.png';
import { AppContext } from '../../services/$contextProvider';
import HomeCarousel from './HomeCarousel';


function About(props) {

  const { $t } = useContext(AppContext);
  const { onUpdateState } = useContext(AppContext)

  const [modal, setModal] = useState(false);

  const carouselItems = [
    {
      title: $t.tool_label1,
      description: $t.home_carousel_text1,
      background: `${tool_image_12}`,
      url: '/sequestration'
    },
    {
      title: $t.tool_label2,
      description: $t.home_carousel_text2,
      background: `${tool_image_2}`,
      url: '/benefits'
    },
    {
      title: $t.tool_label3,
      description: $t.home_carousel_text3,
      background: `${tool_image_32}`,
      url: '/map'
    }
  ];



  useEffect(() => {
    onUpdateState({ active: 'home' })
  }, [])
  return <section style={({ overflow: 'auto' })}>
    <section className='exploration-background' style={{ position: 'relative' }}>
      <HomeCarousel items={carouselItems} />
    </section>

    <Container className='ed-c'>
      <article className='exploration-description' style={{ padding: '20px 15px', margin: '20px auto 40px auto' }}>
        <div>
          <img src={explore} width={150} />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div style={{ maxWidth: 450 }}>
          <p></p>
          <p style={{ textAlign: 'center' }}>{$t.about_about_text1}</p>

        </div>
      </article>
    </Container>

    <div className='tools-background'>
      <img src={tool_image_4} />
      <div className='fade'></div>
      <div className='tools-content'>
        <Container className='tool-options-header'>
          <h1 style={{ color: '#555', textAlign: 'center' }}>
            <strong className='text-primary'>{$t.about_tools_text2}</strong>{$t.about_tools_text1}:
          </h1>
        </Container>

        <Container className='max-width2'>
          <article className='exploration-tool-description' style={{ padding: '20px 15px', margin: '20px auto 40px auto' }}>
            <section className="tool-card">
              <div className="tool-image" style={{ backgroundImage: `url("${tool_image_11}")` }}>
                <div className="tool-title"> <h3>{$t.tool_label1}</h3></div>
              </div>
              <div className="overlay">
                <p className="tool-description">{$t.tool_text11}</p>
                <p className="tool-description">{$t.tool_text12}<a href="https://fao-gsp.github.io/GSOCseq/the-rothc-model.html" target={"_blank"} style={{ textDecoration: 'none', color: '#ffff', textTransform: 'unset', display: 'inline' }}><strong>click here</strong></a>.</p>
                <Link className='try-it-out' to="/sequestration"><span>{$t.try_it_out}</span></Link>
              </div>
            </section>
            <section className="tool-card">
              <div className="tool-image" style={{ backgroundImage: `url("${tool_image_2}")` }}>
                <div className="tool-title"><h3>{$t.tool_label2}</h3></div>
              </div>
              <div className="overlay">
                <p className="tool-description">{$t.tool_text21}</p>
                <p className="tool-description">{$t.tool_text12}<a onClick={() => setModal(true)} style={{ cursor: 'pointer', textDecoration: 'none', textTransform: 'unset', display: 'inline' }}><strong>click here</strong></a>.</p>
                <Link className='try-it-out' to="/benefits"><span>{$t.try_it_out}</span> </Link>
              </div>
            </section>
            <section className="tool-card">
              <div className="tool-image" style={{ backgroundImage: `url("${tool_image_3}")` }}>
                <div className="tool-title"><h3>{$t.tool_label3}</h3></div>
              </div>
              <div className="overlay">
                <p className="tool-description">{$t.tool_text31}</p>
                <Link className='try-it-out' to="/map"><span>{$t.try_it_out}</span> </Link>
              </div>
            </section>
          </article>
        </Container>
        <section className='c-farmer-tool-container'>
          <div className='description'>
            <p>Are you already a regenerative farmer?</p>
            <Link to="/survey" className='button-link'>Share your experience </Link>
          </div>
        </section>
      </div>
    </div>

    <Dialog maxWidth="100%" open={modal} onClose={() => setModal(false)}>
      <DialogTitle>{$t.references}</DialogTitle>
      <DialogContent>
        <List>
          <ListItem style={{ wordBreak: 'break-all' }}>
            <a href="https://www.fas.scot/downloads/cover-crops-information-notes/" target="_blank">https://www.fas.scot/downloads/cover-crops-information-notes/</a>
          </ListItem>
          <ListItem style={{ wordBreak: 'break-all' }}>
            <a href="http://tf.llu.lv/conference/proceedings2012/Papers/009_Sarauskis_E.pdf " target="_blank">http://tf.llu.lv/conference/proceedings2012/Papers/009_Sarauskis_E.pdf </a>
          </ListItem>
          <ListItem style={{ wordBreak: 'break-all' }}>
            <a href="https://egnos-user-support.essp-sas.eu/new_egnos_ops/sites/default/files/cbatool/EASE_tool_Methodology.pdf " target="_blank">https://egnos-user-support.essp-sas.eu/new_egnos_ops/sites/default/files/cbatool/EASE_tool_Methodology.pdf </a>
          </ListItem>
          <ListItem style={{ wordBreak: 'break-all' }}>
            <a href="https://projectblue.blob.core.windows.net/media/Default/Research%20Papers/Cereals%20and%20Oilseed/2020/PR620%20Final%20Project%20Report.pdf" target="_blank">https://projectblue.blob.core.windows.net/media/Default/Research%20Papers/Cereals%20and%20Oilseed/2020/PR620%20Final%20Project%20Report.pdf</a>
          </ListItem>
          <ListItem style={{ wordBreak: 'break-all' }}>
            <a href="https://projectblue.blob.core.windows.net/media/Default/Research%20Papers/Cereals%20and%20Oilseed/2020/PR620%20Final%20Project%20Report.pdf" target="_blank">https://projectblue.blob.core.windows.net/media/Default/Research%20Papers/Cereals%20and%20Oilseed/2020/PR620%20Final%20Project%20Report.pdf</a>
          </ListItem>
          <ListItem style={{ wordBreak: 'break-all' }}>
            <a href="https://www.sciencedirect.com/science/article/pii/S0168169920331355?dgcid=rss_sd_all" target="_blank">https://www.sciencedirect.com/science/article/pii/S0168169920331355?dgcid=rss_sd_all</a>
          </ListItem>
          <ListItem style={{ wordBreak: 'break-all' }}>
            <a href="http://www.fao.org/3/y2781e/y2781e04.htm" target="_blank">http://www.fao.org/3/y2781e/y2781e04.htm</a>
          </ListItem>
          <ListItem style={{ wordBreak: 'break-all' }}>
            <a href="https://www.mdpi.com/2073-4395/9/1/36 " target="_blank">https://www.mdpi.com/2073-4395/9/1/36</a>
          </ListItem>
          <ListItem style={{ wordBreak: 'break-all' }}>
            <a href="https://www.sciencedirect.com/science/article/pii/S0167880915000766" target="_blank">https://www.sciencedirect.com/science/article/pii/S0167880915000766</a>
          </ListItem>
          <ListItem style={{ wordBreak: 'break-all' }}>
            <a href="https://research.libraries.wsu.edu:8443/xmlui/bitstream/handle/2376/13104/FS304E.pdf?sequence=1&isAllowed=y" target="_blank">https://research.libraries.wsu.edu:8443/xmlui/bitstream/handle/2376/13104/FS304E.pdf?sequence=1&isAllowed=y</a>
          </ListItem>
          <ListItem style={{ wordBreak: 'break-all' }}>
            <a href="https://www.agriculturejournals.cz/web/pse.htm?type=article&id=804_2014-PS" target="_blank">https://www.agriculturejournals.cz/web/pse.htm?type=article&id=804_2014-PS</a>
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  </section>
}

export default About;


// https://www.mdpi.com/2073-4395/9/1/36 
// https://www.sciencedirect.com/science/article/pii/S0167880915000766
// https://research.libraries.wsu.edu:8443/xmlui/bitstream/handle/2376/13104/FS304E.pdf?sequence=1&isAllowed=y
// https://www.agriculturejournals.cz/web/pse.htm?type=article&id=804_2014-PS
