import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../services/$contextProvider';
import useMergeState from "../../../services/$mergeState";
import { View, Map } from "ol";
import { fromLonLat, toLonLat, transform } from 'ol/proj';
import { defaults as defaultInteractions } from 'ol/interaction';
import GeoJSON from 'ol/format/GeoJSON';
import { getCenter as getExtentCenter } from 'ol/extent';
import _ from 'lodash';
import InputField from '../../ui/InputField';
import CropGroupDropdown from '../bestPracticeComponents/CropGroupDropdown';
import DropdownField from '../../ui/DropdownField';

import $map from '../../../services/$map';


export default function SurveyStepOne(props) {
  const { $t } = useContext(AppContext);
  const [state, setState] = useMergeState({ base: 'map', query_coordinates: false, zoom: 1 });
  const ref = useRef(null);
  const mapRef = useRef(null);
  let basemaps = null

  useEffect(() => {
    if (ref.current && !mapRef.current) {
      mapInit()
    }
  }, [ref, mapRef]);

  useEffect(() => {
    mapRef.current?.getView().setZoom(state.zoom);
  }, [mapRef, state.zoom]);

  useEffect(() => {
    if (basemaps !== null) $map.setLayer(state.base, basemaps);
  }, [state.base]);

  useEffect(() => {
    if (props.model.location !== null)
      handleLocationOptionsChange(props.model.location)
  }, [props.model.location]);

  const onHandleInput = (name, value) => {
    setState({ [name]: value })
  }

  const handleLocationOptionsChange = (geojsonLocations) => {
    $map.removeLocationLayer(mapRef.current);

    if (geojsonLocations && geojsonLocations.geojson && geojsonLocations.geojson.coordinates) {
      const locationLayer = $map.createLocationLayer(geojsonLocations);
      mapRef.current.addLayer(locationLayer);

      props.updateFarm({ country: geojsonLocations.address.country })

      const extent = locationLayer.getSource().getExtent();

      mapRef.current.getView().fit(extent, { duration: 1000 });
    } else {
      props.updateFarm({ country: '' })
    }
  };

  const mapInit = () => {
    basemaps = $map.createBasemaps(state.base);

    mapRef.current = new Map({
      target: ref.current,
      interactions: defaultInteractions({ altShiftDragRotate: false, pinchRotate: false }),
      layers: _.concat(basemaps),
      view: new View({
        zoom: 5,
        center: fromLonLat([9.1733136, 55.1324482])
      })
    });
    mapRef.current.on('singleclick', onClickMap);
  }

  const isActive = (option) => {
    try {
      if (props.model.location !== null) {
        if (option === props.model.location.place_id) {
          return true
        } else return false
      }
    } catch (e) {
      console.log(e)
    }
  }

  function onLocationOptionClick(option) {
    // Reset the related fields first
    props.updateFarm({
      location: '', coordinates: null, poly: null
    });

    let updateObj = {};
    if (option.geojson.type === 'Point') {
      updateObj = {
        location: option,
        coordinates: option.geojson.coordinates
      }
    }
    else if (option.geojson.type === 'Polygon' || option.geojson.type === 'MultiPolygon') {
      const format = new GeoJSON();
      const feature = format.readFeature(option.geojson, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      });

      const geometry = feature.getGeometry();
      const extent = geometry.getExtent();
      const center = getExtentCenter(extent);

      const centerLonLat = transform(center, 'EPSG:3857', 'EPSG:4326');

      updateObj = {
        location: option,
        poly: option.geojson.coordinates,
        coordinates: centerLonLat
      }
    } else {
      updateObj = {
        location: option,
        coordinates: [parseFloat(option.lon), parseFloat(option.lat)]
      }
    }

    // Update the state with the new values
    props.updateFarm(updateObj);
  }

  const renderDropdownOptions = () => {
    if (props.dropdownOptions && props.dropdownOptions.length > 0) {
      return props.dropdownOptions.map((option, index) => (
        <div className={isActive(option.place_id) ? 'location-accordion active' : 'location-accordion'} key={index} onClick={() => onLocationOptionClick(option)}>
          {option.display_name}
        </div >
      ));
    } else {
      return <div className='no-data-container'>
        {$t.no_location_data}
      </div>
    }
  }

  const onClickMap = (event) => {
    $map.removeLocationLayer(mapRef.current);
    const pinLayer = $map.dropCoordinatesPin(event.coordinate)
    mapRef.current.addLayer(pinLayer);

    const lonLat = transform(event.coordinate, 'EPSG:3857', 'EPSG:4326');
    const lonLatString = lonLat[1].toFixed(6) + ', ' + lonLat[0].toFixed(6);
    //todo query props update
    props.updateQuery(lonLatString)
    setState({ query_coordinates: true });
  }

  const renderForm = () => {
    return <React.Fragment>
      <div className='flex-column form-box-essential'>
        <strong className='farm-general-header'>{$t.header_general}</strong>
        <section className='farm-general-fields'>
          <InputField className='input-s' type={'text'} error={props.error.farm_name_error || props.error.error} value={props.model.farm_name} onChange={(evt) => props.updateFarm({ farm_name: evt.target.value })} placeholder={$t.placeholder_farm_name} />
          <InputField className='input-s' type={'email'} error={props.error.contact_error || props.error.error} value={props.model.contact} onChange={(evt) => props.updateFarm({ contact: evt.target.value })} maxLength={36} placeholder={$t.placeholder_contact} />
        </section>
        <section className='farm-general-fields'>
          <InputField className='input-s' type={'text'} value={props.model.source} onChange={(evt) => props.updateFarm({ source: evt.target.value })} maxLength={300} placeholder={$t.placeholder_link} />
          <CropGroupDropdown error={props.error.crop_group_error} value={props.model.crop_group} className='input-s' type={'text'} onHandleInput={props.updateFarm} placeholder={$t.placeholder_crop_group} />
        </section>
      </div>
      <div className='location-form-container'>
        <strong className='farm-location-header'>{$t.header_location}</strong>
        <div className='flex-row location-cnt' style={{ zIndex: '5' }}>
          <DropdownField query={props.locQuery} query_coordinates={state.query_coordinates} updateQuery={props.updateQuery} onHandleInput={onHandleInput} onOptionsUpdate={props.updateDropdownOptions} name={'location'} value={props.model.location} className='input-s' placeholder={$t.placeholder_location} />
        </div>
        <div className='flex-row'>
          <div className='stack-container'>{renderDropdownOptions()}</div>
          <div className='mini-map-container' ref={ref}>
            <section className={state.drawer ? 'map-tools-container' : 'map-tools-container drawer-width'}>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment >
  }


  return (
    <div className='form-container'>
      {renderForm()}
    </div>
  );
}
