import React, { useState, useEffect, useContext } from "react";
import { AppContext } from '../../../services/$contextProvider';

export default function SurveyStepThree(props) {
  const { $t } = useContext(AppContext);

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    const filesArray = Array.from(files);
    let images = props.images

    filesArray.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        images = [
          ...images,
          { src: reader.result, alt: file.name },
        ]
        props.updateImages({ image: images })
      };
      reader.readAsDataURL(file);
    });
  };

  const handleRemoveImage = (indexToRemove) => {
    props.updateImages({ image: props.images.filter((image, index) => index !== indexToRemove) }
    );
  };

  return (
    <div className="image-upload-wrapper">
      <label htmlFor="file-input" className="upload-label">
        <span>{$t.upload_label}</span>
      </label>
      <input
        type="file"
        multiple
        onChange={handleFileInputChange}
        className="file-input"
        accept='.png,.jpg,.jpeg'
        id="file-input"
      />
      <div className="image-list">
        {props.images.map((image, index) => (
          <div key={index} className="image-container">
            <button
              className="remove-button"
              onClick={() => handleRemoveImage(index)}
            >
              &times;
            </button>
            <img src={image.src} alt={image.alt} className="image" />
          </div>
        ))}
      </div>
    </div>
  );
};