import React, { useState, useEffect, useRef } from 'react';

export default function FilterCropGroupDropdown(props) {
  const timeoutId = useRef(null);

  const Crop_Groups = [
    { value: 'Field crop', label: 'Field crop' },
    { value: 'Vegetable crop', label: 'Vegetable crop' },
    { value: 'Fruit crop', label: 'Fruit crop' },
    { value: 'Viticulture', label: 'Viticulture' },
    { value: 'Nuts', label: 'Nuts' },
    { value: 'Animals', label: 'Animals' },
    { value: 'Other', label: 'Other' }
];

  const [state, setState] = useState({
    options: Crop_Groups,
  });

  const [inputFocus, setInputFocus] = useState(false);

  const getOptions = (input) => {
    if (!input || input === '') {
      return Crop_Groups;
    } else {
      return Crop_Groups.filter(
        opt => opt.label.toLowerCase().includes(input.toLowerCase())
      );
    }
  }

  useEffect(() => {
    return () => clearTimeout(timeoutId.current);
  }, []);

  const handleInputChange = (event) => {
    const input = event.target.value;
    props.onChange({ crop_group: input });
    setState({ options: getOptions(input) });
  }

  const handleOptionClick = (option) => {
    props.onChange({ crop_group: option.value });
    setState({ options: Crop_Groups });
  }

  return (
    <React.Fragment>
      <div className='cg-filter-dropdown-container'>
        <input
          autoComplete="off"
          className={`dropdown-input-text ${props.className ? props.className : ''}`}
          type={props.type ? props.type : 'text'}
          name={props.name ? props.name : 'email'}
          placeholder={props.placeholder ? props.placeholder : ''}
          onChange={handleInputChange}
          onFocus={() => setInputFocus(true)}
          onBlur={() => timeoutId.current = setTimeout(() => setInputFocus(false), 200)}
          value={props.value ? props.value : ''}
        />
        {inputFocus && (state.options.length > 0 ? (
          <div className="options_container">
            {state.options.map((option, index) => (
              <div
                key={index}
                className="option"
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        ) : <div className="options_container">
            <div className="no_options">
              {'no results'}
            </div>
          </div>)}
      </div>
      {props.error && (
        <span className='error_text2'>{props.error}</span>
      )}
    </React.Fragment>
  );
}