const en = {
	//COMMON
	yes: 'Yes',
	no: 'No',
	cancel: 'Cancel',
	previous: 'Previous',
	next: 'Next',
	finish: 'Finish',
	add: 'Add',
	submit: 'Submit',
	apply: 'Apply',
	or: 'Or',

	//NAV
	sign_in: 'Sign In',
	sign_out: 'Sign out',
	profile: 'Profile',
	home_link: 'Home',
	home_panel_link: 'HOME',
	soc_link_panel_link: 'SOC SEQUESTRATION',
	eco_panel_link: 'ECONOMICAL BENEFITS',
	best_practice_link: 'MAP',
	soc_link: 'SOC Sequestration',
	eco_link: 'Economical Benefits',
	powered_by: 'Powered by ',
	agreement: 'AgriCaptureCO2 receives funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement ',


	//ERRORS
	error_title: 'Error',
	fields_empty: 'Fields must not be empty',
	blank_field: 'This field must not be blank',
	not_found_title: 'Oops',
	not_found_text: "The Page you're looking for isn't here.",
	not_found_link: "Click here to go back to dashboard.",
	geo_loc_error: 'Unable to get your location!',

	//SUCCESS MESSAGES
	success_title: 'Success|as a title',

	//NAVBAR
	app_name: 'Exploration',
	messages: 'messages',
	exploration_item_1: 'SOC SEQUESTRATION',
	exploration_item_2: 'ECONOMICAL BENEFITS',
	exploration_item_3: 'EXPLORATION 3',
	exploration_item_4: 'EXPLORATION 4',

	//ABOUT
	about_title: 'Exploration ',
	about_description: 'Assess how regenerative practices affects farms, soil and income',
	try_it_outC: 'TRY IT OUT',
	try_it_out: 'Try it out',
	about_about_text1: "Change is never easy, particularly when abandoning tried-and-tested agricultural practices for new ones. Throughout the 'explore’ stage, we estimate the effects of various changes to yield, fuel and input costs, labour and farm income, and also the potential revenue from carbon credits.",
	about_tools_text1: 'exploration service offers 3 free tools',
	about_tools_text2: 'AgriCaptureCO2 ',
	references: 'References',
	tool_label1: 'SOIL ORGANIC CARBON SEQUESTRATION ASSESSMENT TOOL',
	tool_label2: 'ECONOMICAL BENEFITS',
	tool_label3: 'MAP OF REGENERATIVE FARMS WORLDWIDE',
	tool_text11: 'Discover the SOC sequestration potential based on the local climatic conditions, soil characteristics and specific agricultural practices applied. The calculation is based on RothC model.',
	tool_text12: 'For more information on methodology used, ',
	tool_text21: 'Based on user input (crops, yield [total/per ha], agricultural operations used, labour costs etc.), this tool calculates and outputs the economical benefits of using regenerative practices. ',
	tool_text22: '',
	tool_text31: 'Discover regenerative farms in your neighborhood or worldwide and learn about their experience, benefits and challenges. ',
	home_carousel_learn_more_label: 'Learn more',
	home_carousel_text1: 'Discover the SOC sequestration potential based on the local climatic conditions, soil characteristics and specific agricultural practices applied.',
	home_carousel_text2: 'this tool calculates and outputs the economical benefits of using regenerative practices.',
	home_carousel_text3: 'Discover regenerative farms in your neighborhood or worldwide.',

	//AUTH
	register_title: 'Registration',
	register_login_text1: 'Already have an account?',
	register_login_text2: 'Sign in',
	placeholder_email: 'Enter your email...',
	placeholder_password: 'Enter your password...',
	placeholder_password2: 'Repeat your password...',
	login_register_text1: "Don't have an account?",
	login_register_text2: 'Register here',
	login_forgotten_password1: 'Forgot your password? Click ',
	login_forgotten_password2: 'here',
	// email_error: '*Invalid email...',
	password_error: 'Invalid password...',
	login: 'Login',
	email_error: '*Email field is required',
	email2_error: '*Please enter a valid email address',
	password1_error: '*Password field is required',
	password2_error: '*Please repeat your password',
	email_verification: 'Account successfully created, please login to verify your email',
	l_modal_title: 'Unauthorized Access',
	l_modal_text: 'Oops! It appears your token has expired or you are not logged in to access this content.',
	l_modal_continue_label: 'Continue as Guest',

	//PROFILE
	p_menu_farms: 'My Farms',
	p_menu_edit: 'Profile',
	p_menu_add: 'Add farm',
	p_menu_sgui: 'Edit all',
	filter_searchbar_placeholder: 'Search Farms...',
	success_message_profile: 'Farm updated successfully!',
	error_message_profile: 'Error updating the Unauthorized Accessfarm.',
	no_farm_msg: 'There are no farms connected to this account.',
	new_password_label: 'Your new password...',
	repeat_password_label: 'Repeat the new password...',
	cp_button_label: 'Change  your password',
	rm_button_label: 'Delete',
	rm_header_label: 'Delete your profile',
	remove_user_text: '*You can delete your account here. Note that this action is permanent and cannot be undone',
	//PROFILE CONTROL
	access_edit_farm_edit_farm_label: 'Edit farm',
	access_edit_farm_edit_label: 'Edit',
	access_edit_farm_remove_label: 'Remove',



	//FARM PREVIEW
	farm_preview_title: 'Farm Information ',
	farm_preview_editing: '(Editing)',
	farm_preview_go_back: 'Go Back',
	edit_farm_label: 'Edit Farm',
	edit_farm_save: 'Save Changes',
	delete_farm_label: 'Delete Farm',
	delete_farm_confirm: 'Confirm Delete',
	farm_practices_label: 'Practices',
	farm_benefits_label: 'Benefits',
	farm_limitations_label: 'Limitations',
	image_gallery_title: 'Farm Photos',

	//ROTHC
	plant_parcel_tab_title: 'Plant on the parcel:',
	plant_parcel_option1: 'Agricultural crops',
	plant_parcel_option2: 'Grassland and scrub',
	plant_parcel_option3: 'Deciduous or tropical woodland',
	mandatory_option_tab_title: 'Choose one of the options below (mandatory):',
	c_inputs_soil_title: 'Monthly C inputs to soil in t/ha/month',
	c_inputs_litter_title: 'The amount of litter inputs by t/ha/month',
	c_inputs_yard_titles: 'The amount of Farm Yard Manure inputs by t/ha/month',
	optional_parameters_title: 'Optional parameters:',
	prediction_period_future_label: 'Prediction period in future',
	soil_thickness_label: 'Soil thickness (organic layer topsoil) in cm',
	clay_percent_label: 'Clay percentage',
	optional_tabs_title: 'Choose one of the options below (optional):',
	pedotransfer_functions_label: 'Pedotransfer functions',
	manual_pools_label: 'Manually input 5 pools in t/ha',
	zimmermann_fractionation_label: 'Zimmermann’s fractionation scheme',
	soc_unit_label: 'SOC Unit',
	bulk_dens_label: 'Bulk density in g/cm3',
	optional_form_label: 'This form is optional, but in order to be taken into account during calculation, all fields must be filled out.',
	submit_query: 'EXECUTE QUERY',
	location_mandatory_input_label: 'Location (mandatory): ',
	submit_error: 'Something went wrong! Please check if you filled out mandatory information or if you are out of bounds (e.g. location is in water content - oceans, seas, lakes etc.)',


	//ECONOMICAL BENEFITS
	step_1_title: 'My Farm',
	step_1_text: 'Enter the information about your crops',
	step_2_title: 'Operations',
	step_2_text: 'Select/Describe regenerative practices that you apply',
	step_3_title: 'Economic benefits',

	step_1_info: 'Add information about crops',
	crop_field_name: 'Crop',
	crop_field_placeholder: 'Select crop from the list',
	yield_field_name: 'Yield (t/ha)',
	yield_field_placeholder: 'Enter expected yield (t/ha)',
	total_area_input_name: 'Total area (ha)',
	total_area_input_placeholder: 'Enter total crop area (ha)',

	crop_type_error: 'Crop type is missing or invalid.',
	crop_duplicate_error: 'Record with this crop type already exists!',
	record_error: 'You must add at least one record before proceeding to the next step',

	first_step_error: 'Minimum of one record is required',
	second_step_error: 'Minimum of one operation is required',

	crop_title: 'Crop',
	yield_title: 'Yield',
	total_area_title: 'Total area',
	economical_disclaimer: "*The tool is based on a model developed by Agricultural University of Athens. It should be used as an estimation of general impact of the regenerative practices on farm's economy rather then a precise forecast of the financial outcomes.",

	//FIRST STEP MODAL
	modal_first_header: 'Remove record',
	modal_first_text: 'Are you sure you want to remove',
	modal_first_text2: 'from the list?',
	modal_first_error: 'Selected record does not exist.',


	//SECOND STEP
	empty_lists_error: 'Missing required data',
	crop_type: 'Crop type: ',
	operations: 'Operations: ',
	no_operations: 'There are no operations for this type',
	add_operation: 'Add operation',
	operation_type: 'Operation',
	operation_type_placeholder: 'Select operation type',
	type_cover: 'Cover crop',
	type_tillage: 'Tillage',
	type_mulching: 'Mulching',
	cover_crop_label: 'Cover Crop',
	cover_crop_type_placeholder: 'Select cover crop type',
	tillage_operations_tillage: 'Tillage type',
	tillage_type_placeholder: 'Select tillage type',
	cover_crop_labor_form_label: 'Labor Cost (EUR/h)',
	mulching_mulching_type_label: 'Mulching type',
	mulching_type_placeholder: 'Select mulching type',
	mulching_pesticide_form_rate_label: 'Pesticide Rate (EUR/ha)',
	mulching_fertilization_form_rate_label: 'Fertilization Rate (EUR/ha)',
	mulching_irrigation_form_rate_label: 'Irrigation Rate (EUR/ha)',
	add_operation_error: 'Failed to add operation, please check if all fields are valid!',

	card_labour_label: 'Labor Cost',
	card_lunit_label: '(EUR/h)',
	card_pesticide_label: 'Pesticide Rate',
	card_fert_label: 'Fertilization Rate',
	card_irrig_label: 'Irrigation Rate',
	card_haunit_label: '(EUR/ha)',

	//FINAL STEP
	failed_data_error: 'Failed to retrieve data!',
	no_data_error: 'There are no results at the moment.',
	header_crop: 'Crop',
	header_crop_cover: 'Cover crop',
	header_tillage: 'Tillage',
	header_mulching: 'Mulching',
	header_cover_yield: 'Yield increase with Cover crop',
	header_cover_cost: 'Cost of cover crop',
	header_cover_savings: 'Cover crop savings',
	header_tillage_savings: 'Savings of low tillage',
	header_tillage_no_savings: 'Savings of no tillage',
	header_mulching_savings: 'Savings from mulching',
	header_mulching_economic_savings: 'Economic savings from mulching',
	header_total_balance: 'Total balance',
	header_mulching_irrigation: 'Irrigation reduction',
	header_mulching_fertilizer: 'Fertilizer reduction',
	header_mulching_pesticide: 'Pesticide reduction',
	no_results_available: 'There are no results available',
	unit_t_ha: ' t/ha',
	unit_eur_ha: ' EUR/ha',

	//BEST PRACTICE
	import_farm_file_title: 'Import farms from xlsx',
	input_file_placeholder: 'Choose a file...',
	label_strong: 'Chosen file: ',
	location_not_selected: 'Location not selected',
	invalid_format: '(Invalid file format)',
	invalid_type: '(Invalid file type)',
	parsing_file: 'Parsing file...',
	filter_header_title: 'Filter farms by...',
	filter_placeholder_name: 'Filter by farm name...',
	filter_placeholder_crop_group: 'Filter by crop group...',
	filter_placeholder_practice: 'Filter by practice...',
	related_links: 'Related Links',
	label_regenerative_practice: 'Regenerative Practices',
	label_benefits: 'Benefits',
	label_limitations: 'Limitations',
	clear_filter_tooltip: 'Clear Filter',
	contact_label: 'Contact:',

	//BEST PRACTICE LEGEND
	label_text_stack10: ' - location stack size > 10',
	label_text_stack: ' - location stack size < 10',
	label_text_precise: ' - precise location',
	label_text_approx: ' - approximate location',

	//FARM SURVEY
	go_to: 'go to',
	search_placeholder: 'Search locations...',
	my_location: 'Find my location',
	label_owner: 'Owner:',
	tab_label_precise: 'Precise',
	tab_label_approximate: 'Approximate',
	not_defined: 'not defined',
	farm_information: 'General information',
	header_general: 'General',
	header_location: 'Location',
	regenerative_practices: 'Regenerative practices',
	benefits_barriers: 'Benefits and limitations',
	image_upload: 'Upload images',
	placeholder_farm_name: '*Enter farm name...',
	placeholder_contact: 'Contact email...',
	placeholder_link: 'Related website link...',
	placeholder_crop_group: 'Crop group...',
	placeholder_region: 'Enter county...',
	placeholder_location: 'Search location...',
	no_location_data: 'No location matches',
	location_data: 'Match found',
	practice_name_label: 'Name of the practice...',
	farm_name_error: '*Farm name is required',
	crop_group_error: '*Crop group is required',
	contact_error: '*Contact information is required',
	practice_name_error: '*This field is required',
	practice_name2_error: 'Name must be unique',
	no_practice_found: 'There are no regenerative practices for this farm...',
	benefits_label: 'Benefits',
	barriers_label: 'Barriers/Limitations',
	benefits_name_placeholder1: 'Name of the benefit (max. ',
	barriers_name_placeholder1: 'Name of the limitation (max. ',
	benefits_name_placeholder2: ' characters):',
	description_label1: 'Description (max. ',
	description_label2: ' characters):',
	upload_label: 'Upload Images',
	success_message_farm: 'Farm added successfully!',
	error_message_farm: 'Error adding the farm.',


	//FOOTER
	text_contact: 'Contact us:',
	text_pp: 'Privacy policy',
	text_tm: 'Terms of use',


}

export default en;