import React, { useEffect } from 'react';
import useMergeState from "../../services/$mergeState";
import _ from 'lodash';
import BestPracticeMap from './bestPracticeComponents/BestPracticeMap'
import $map from '../../services/$map';
import $data from '../../services/$data';
import Loader from '../ui/Loader';
import $auth from '../../services/$auth';


function BestPractice(props) {
  const [state, setState] = useMergeState({ loading: true, loaded: false, xlsxA: false, farm_points_layer: null, uq_practices: [], farm_polygons_layer: null, error: {} });

  useEffect(() => {
    if (state.loading && !state.loaded) {
      setState({ loading: true, active: 'practice' })
      if (props.loggedIn) {
        checkIsA()
      }
      getFarms()
    } else {
      setState({ active: 'practice' })
    }
  }, []);

  const getFarmLayers = (data) => {
    try {
      const { pointsList } = $map.getFarmLists(data)
      const farm_points_layer = pointsList.features.length > 0 ? $map.createFarmPoints(pointsList) : null;
      return { farm_points_layer }
    } catch (e) {
      console.log(e)
      const farm_points_layer = null
      return { farm_points_layer }
    }
  }


  const checkIsA = () => {
    $auth.isSA()
      .then(data => {
        setState({ xlsxA: data.code })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getFarms = () => {
    $data.getFarms(1)
      .then(data => {
        const { farm_points_layer } = getFarmLayers(data.data)
        setState({ loading: false, loaded: false, farm_points_layer, uq_practices: data.unique_reg_practices })
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <React.Fragment>
      {state.loading ? <div className='best-practice-container'>
        <div className='loader-container'><Loader text={'loading...'} /></div>
      </div> : <BestPracticeMap loggedIn={props.loggedIn} xlsxA={state.xlsxA} uq_practices={state.uq_practices} farm_points_layer={state.farm_points_layer} />}
    </React.Fragment>
  )
}

export default BestPractice;