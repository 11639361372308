import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../services/$contextProvider';
import useMergeState from "../../../services/$mergeState";
import OperationCard from './OperationCards';

import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export default function RegAgriRecords(props) {
  const { $t } = useContext(AppContext);
  const [state, setState] = useMergeState({ active: false, activeOp: 0 })

  const handleChange = (name, value) => {
    setState({ [name]: value })
  }

  const handleOperationTab = (e, value) => {
    setState({ activeOp: value })
  }

  const getContent = () => {
    const tab = getTabNumber()
    switch (state.activeOp) {
      case (tab.cover): return (
        <OperationCard operations={props.crop_cover_operation} operation={1} types={props.typesList.cover} onUpdateOperationState={props.onUpdateOperationState} />
      );
      case (tab.tillage):
        return (
          <OperationCard operations={props.tillage_operation} operation={2} types={props.typesList.tillage} onUpdateOperationState={props.onUpdateOperationState} />);
      case (tab.mulching): return (
        <OperationCard operations={props.mulching_operation} operation={3} types={props.typesList.mulching} onUpdateOperationState={props.onUpdateOperationState} />
      );
      default:
        return null
    }
  }

  const getOperations = (cover_op, till_op, mulch_op) => {
    var operation_cover = null
    var operation_mulching = null
    var operation_tillage = null
    let tab = 0
    if ((mulch_op.length + till_op.length + cover_op.length) === 0) {
      return <span>{$t.no_operations}</span >
    }

    if (cover_op.length > 0) {
      operation_cover = <Tab value={tab} label={$t.type_cover} wrapped />
      tab = tab + 1
    }

    if (till_op.length > 0) {
      operation_tillage = <Tab value={tab} label={$t.type_tillage} />
      tab = tab + 1
    }

    if (mulch_op.length > 0) {
      operation_mulching = <Tab value={tab} label={$t.type_mulching} />
    }

    return <Box
      className='operations-container'
      onClick={(e) => e.stopPropagation()}
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
    >
      <Tabs
        value={state.activeOp}
        onChange={handleOperationTab}
        aria-label={$t.operations}
      >
        {operation_cover}
        {operation_tillage}
        {operation_mulching}
      </Tabs>
    </Box>
  }

  const getTabNumber = () => {
    let tabs = {
      cover: null,
      tillage: null,
      mulching: null
    }
    let count = 0
    if (props.crop_cover_operation.length > 0) {
      tabs.cover = count
      count = count + 1
    }

    if (props.tillage_operation.length > 0) {
      tabs.tillage = count
      count = count + 1
    }

    if (props.mulching_operation.length > 0) {
      tabs.mulching = count
    }
    return tabs
  }

  return (
    <div onClick={() => handleChange('active', !state.active)} className={state.active ? 'reg-agri-record-item' : 'reg-agri-record-item not-active'}>
      <div className="reg-agri-crop">
        <section className='crop_info-container'>
          <span className="crop-title">{$t.crop_type}</span>
          <span className="crop-name">{props.cropTypes.filter(obj => obj.name.toString() === props.record.crop.toString())[0].name}</span>
          <span className="crop-area">({props.record.area}) ha</span>
        </section>
        {state.active ? <section className='crop-operations_container'></section> : <section className='crop-operations_container'>
          <span className="crop_operations_field">{$t.operations}</span>
          <span className="crop-operations_num">&nbsp;{props.operation_count}</span></section>}
        <section className='crop-form-btn-container'>
          <Tooltip title={$t.add_operation} position="bottom"><AddIcon onClick={() => props.updateSecondState({ addOperationModal: true, crop_record: props.record })} /></Tooltip>
        </section>
      </div>
      <div className="reg-agri-operations">
        {props.typesList.cover !== undefined ? getOperations(props.crop_cover_operation, props.tillage_operation, props.mulching_operation) : null}
        {getContent()}
      </div>
    </div>
  );
}