import React, { useContext, useEffect } from 'react';
import Login from './Login';
import Register from './Register';
import background from '../../assets/background_home.webp';
import { AppContext } from '../../services/$contextProvider';
import { Link, Navigate } from 'react-router-dom';
import useMergeState from '../../services/$mergeState';

export default function Auth(props) {
  const { $t, onUpdateState } = useContext(AppContext);
  const [state, setState] = useMergeState({ isLoggedIn: false });

  useEffect(() => {
    if (props.loggedIn) setState({ isLoggedIn: true })
  }, [props.loggedIn])
  useEffect(() => {
    if (props.active !== 'auth') onUpdateState({ active: 'auth' });
  }, [])

  const switchForm = () => {
    switch (props.page) {
      //Login
      case 'login': return (
        <Login />
      );
      //Register
      case 'register':
        return (
          <Register />
        );
      default:
        return <Login />
    }
  }

  if (state.isLoggedIn) {
    return <Navigate to="/home" />
  }

  return (
    <div className='auth-container'>
      <section className='auth-eploration-background' style={{ position: 'relative', backgroundImage: `url("${background}")` }}>
        <div className='exploration-title'><Link className='link-title' to="/home">{$t.about_title}</Link></div>
        {switchForm()}
      </section>
    </div>
  );
}