class $bookmark {

  change(state, option) {
    let validated = this._validate(state, option);
    this._updateURL(validated);
  }

  _updateURL(params) {
    let url = '?' + Object.keys(params).map(param => `${param}=${params[param]}`).join('&');
    window.history.replaceState('', 'exploration', url);
  }

  _validate(params) {
    if (this._validateStep(params.page)) {
      let output = {
        page: this._validatePage(params.page),
        // step: this._validateStep(params.step)
      };
      return output
    } else {
      let output = {
        page: this._validatePage(params.page)
      };
      return output
    }
  }

  _checkParamsForState(name) {
    try {
      let params = window.location.search.split('?')[1].split('&');

      if (params !== '') {
        return params.some(el => el.split('=')[0] === name)
      } else {
        return false
      }
    } catch (err) {
      return false;
    }
  }

  _checkPageUrl() {
    let param = {
      page: null,
      step: null
    }
    try {
      let params = window.location.search.split('?')[1].split('&');
      if (params !== '') {
        params.map(el => {
          let name = el.split('=')[0];
          let value = el.split('=')[1];

          if (name === 'page') {
            param.page = this._validatePage(value);
          }
          if (name === 'step') {
            param.step = this._validateStep(value);
          }
        })
      }
      return param
    } catch (err) {
      return param;
    }
  }

  _validateStep(val) {
    if (val !== null && val !== '') {
      return val
    } else return null
  }

  _validatePage(val) {
    try {
      if (!val) {
        return 0
      }
      return parseInt(val);

    } catch{
      return 0
    }
  }

  _parse(arr) {
    let output = []

    arr.map(el => {
      let name = el.split('=')[0];
      let value = el.split('=')[1];

      if (name === 'page') {
        output.push(parseInt(value));
      }

      if (name === 'step') {
        output.push(parseInt(value));
      }
    })
    return output;
  }
}

export default new $bookmark();