import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../services/$contextProvider';
import $auth from '../../../services/$auth';
import InputField from '../../ui/InputField'
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import useMergeState from '../../../services/$mergeState';

export default function ProfileEdit(props) {
    const { $t, onUpdateState } = useContext(AppContext);
    const [state, setState] = useMergeState({
        password1: '',
        password2: '',
        removeAccount: false,
        loading: false,
        error: {},
        successMessage: '',
    });

    useEffect(() => {
    }, []);

    const onHandleChange = (name, value) => {
        setState({ [name]: value });
    };

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            onRequestSubmit();
        }
    };

    const validateField = (name, value) => {
        //todo validate field
    };

    const onRequestSubmit = async (evt) => {
        evt.preventDefault();

        if (validateField('password1', state.password1) && validateField('password2', state.password2)) {
            try {
                // const response = await $auth.changePassword({
                //     currentPassword: state.currentPassword,
                //     newPassword: state.password1,
                // });

                setState({ successMessage: 'Password changed successfully', error: {} });
            } catch (error) {
                setState({ error: error.response.data });
            }
        }
    };

    const removeAccount = () => {
        if (props.upk !== null && props.upk !== undefined) {

            $auth.removeUser(props.upk)
                .then(data => {
                    return window.location.reload();
                })
                .catch(err => {
                    console.log(err);
                    setState({ error: 'ERROR', text: $t.error_message_profile });
                });
        }
    }

    return (
        <div className="editProfileDashboard">
            <section className="changeProfilePasswordContainer">
                <div className="change-password-form">
                    <h3 className='cp-header'>{$t.cp_button_label}</h3>
                    {state.successMessage && (
                        <MuiAlert elevation={6} variant="filled" severity="success">
                            {state.successMessage}
                        </MuiAlert>
                    )}

                    <Snackbar
                        open={state.error.non_field_errors ? true : false}
                        autoHideDuration={6000}
                        onClose={() => setState({ error: {} })}
                    >
                        <MuiAlert elevation={6} variant="filled" severity="error">
                            {state.error.non_field_errors}
                        </MuiAlert>
                    </Snackbar>

                    <form onSubmit={onRequestSubmit} className='i-width'>
                        <InputField maxLength={32}
                            className='i-width'
                            error={state.error.email_error}
                            type={'password'}
                            value={state.password1}
                            onKeyPress={(evt) => handleKeypress(evt)}
                            name={'password1'}
                            placeholder={$t.new_password_label}
                            onChange={(e) => onHandleChange('password1', e.target.value)} />
                        <InputField error={state.error.password1_error}
                            value={state.password2}
                            type={'password'}
                            onKeyPress={(evt) => handleKeypress(evt)}
                            name={'password2'}
                            placeholder={$t.repeat_password_label}
                            onChange={(e) => onHandleChange('password2', e.target.value)} />
                        <button className='cp-btn' type="submit">{$t.apply}</button>
                    </form>
                </div>
            </section>
            <section className="rm-container">
                <h3 className='rm-header'>{$t.rm_header_label}</h3>
                <span className='rm-text'>{$t.remove_user_text}</span>
                {state.removeAccount ? <div className='rm-btn-container'>
                    <button className='rm-btn' onClick={() => removeAccount()}>{$t.yes}</button>
                    <button className='rm-btn rm-negative' onClick={() => setState({ removeAccount: false })}>{$t.cancel}</button>
                </div> : <div className='rm-btn-container'>
                    <button className='rm-btn' onClick={() => setState({ removeAccount: true })}>{$t.rm_button_label}</button>
                </div>}
            </section>
        </div>
    );
}