import React, { useState, useEffect, useRef } from 'react';

export default function FilterPracticeDropdown(props) {
  const timeoutId = useRef(null);
  // let REG_PRACTICES = props.uq_practices

  const REG_PRACTICES = [
    { value: 'No Data', label: 'No Data' },
    { value: 'No-till', label: 'No-till' },
    { value: 'Reduced or minimum tillage', label: 'Reduced or minimum tillage' },
    { value: 'Cover crops', label: 'Cover crops' },
    { value: 'Crop rotation', label: 'Crop rotation' },
    { value: 'Mulching', label: 'Mulching' },
    { value: 'Agroforestry and silvopasture', label: 'Agroforestry and silvopasture' },
    { value: 'Conservation buffers (hedgerows, trees, flower strips, shrubs)', label: 'Conservation buffers (hedgerows, trees, flower strips, shrubs)' },
    { value: 'Composting', label: 'Composting' },
    { value: 'Hedgerows, windbreaks, flower strips, shrubs', label: 'Hedgerows, windbreaks, flower strips, shrubs' },
    { value: 'Inter-cropping', label: 'Inter-cropping' },
    { value: 'Natural fertilizer', label: 'Natural fertilizer' },
    { value: 'Integrated Pest Management', label: 'Integrated Pest Management' },
    { value: 'Precision farming', label: 'Precision farming' },
    { value: 'Regenerative Livestock Management', label: 'Regenerative Livestock Management' },
    { value: 'Irrigation Efficiency', label: 'Irrigation Efficiency' },
    { value: 'Biochar', label: 'Biochar' },
    { value: 'Holistically managed grazing', label: 'Holistically managed grazing' },
    { value: 'Animal integration into crop production', label: 'Animal integration into crop production' },
    { value: 'Perennial cropping', label: 'Perennial cropping' },
  ];

  const [state, setState] = useState({
    options: REG_PRACTICES,
  });

  const [inputFocus, setInputFocus] = useState(false);

  const getOptions = (input) => {
    if (!input || input === '') {
      return REG_PRACTICES;
    } else {
      return REG_PRACTICES.filter(
        opt => opt.label.toLowerCase().includes(input.toLowerCase())
      );
    }
  }

  useEffect(() => {
    return () => clearTimeout(timeoutId.current);
  }, []);

  const handleInputChange = (event) => {
    const input = event.target.value;
    props.onChange({ regenerative_practice2: input });
    setState({ options: getOptions(input) });
  }

  const handleOptionClick = (option) => {
    props.onChange({ regenerative_practice2: option.value });
    setState({ options: REG_PRACTICES });
  }

  return (
    <React.Fragment>
      <div className='dropdown-container'>
        <input
          autoComplete="off"
          className={`dropdown-input-text ${props.className ? props.className : ''}`}
          type={props.type ? props.type : 'text'}
          name={props.name ? props.name : 'email'}
          placeholder={props.placeholder ? props.placeholder : ''}
          onChange={handleInputChange}
          onFocus={() => setInputFocus(true)}
          onBlur={() => timeoutId.current = setTimeout(() => setInputFocus(false), 200)}
          value={props.value ? props.value : ''}
        />
        {inputFocus && (state.options.length > 0 ? (
          <div className="options_container">
            {state.options.map((option, index) => (
              <div
                key={index}
                className="option"
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        ) : <div className="options_container">
            <div className="no_options">
              {'no results'}
            </div>
          </div>)}
      </div>
      {props.error && (
        <span className='error_text2'>{props.error}</span>
      )}
    </React.Fragment>
  );
}