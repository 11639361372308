import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../services/$contextProvider';
import farm_img from '../../../assets/farm_precise_pin.png';
import farm_und from '../../../assets/farm_unprecise_pin2.png';

function MapFarmLegend(props) {
  const { $t } = useContext(AppContext);

  return (
    <aside className='map-farm-legend-container'>
      <div className='legend-information'>
        {/* <div className='legend-label'><div className='legend-marker' style={{ background: '#54278f', borderColor: '#54278f' }}></div>{$t.label_text_stack10}</div> */}
        {/* <div className='legend-label'><div className='legend-marker' style={{ background: '#756bb1', borderColor: '#756bb1' }}></div>{$t.label_text_stack}</div> */}
        <div className='legend-label'><div className='legend-marker' style={{ background: '#6baed6', borderColor: '#6baed6' }}><img src={farm_und} alt="li unprecise" /></div>{$t.label_text_approx}</div>
        <div className='legend-label'><div className='legend-marker' style={{ background: '#08519c', borderColor: '#08519c' }}><img src={farm_img} alt="li unprecise" /></div>{$t.label_text_precise}</div>
      </div>
    </aside>
  )
}

export default MapFarmLegend;