import React, { useState, useContext } from 'react';
import DropdownField from '../DropdownField';
import { MapComponent, OptionsRenderer } from './LocationComponents';
import { AppContext } from '../../../services/$contextProvider';
import useMergeState from '../../../services/$mergeState';

export default function LocationModal({ onClose, setEditableFarm, editableFarm }) {
  const { $t } = useContext(AppContext);
  const [options, setOptions] = useState([]);
  const [data, setData] = useMergeState({ location: null, coordinates: null, poly: null })
  const [query, setQuery] = useState("");
  const [queryCoordinates, setQueryCoordinates] = useState(null);

  const handleOptionsUpdate = (newOptions) => {
    setOptions(newOptions);
  }

  const handleInput = (type, value) => {
    if (type === 'query') setQuery(value);
    if (type === 'query_coordinates') setQueryCoordinates(value);
  }

  const handleMapClickInput = (query_value, querycoord_value) => {
    setQuery(query_value);
    setQueryCoordinates(querycoord_value);
  }

  const handleOptionSelect = (state) => {
    setData({ location: null, coordinates: null, poly: null })
    setData(state);
  }

  const applyChanges = () => {
    if (data.location !== undefined && data.location !== null) {
      console.log(data.location)
      setEditableFarm({ ...editableFarm, location: data.location.display_name, country: data.location.address.country, poly: data.poly, coordinates: data.coordinates })
      onClose()
    }
  }

  return (
    <div className='location-modal-container'>
      <section className='location-modal-wrapper'>
        <button className='btn-exit' onClick={onClose}>X</button>
        <div className='search-bar-container'>
          <DropdownField
            name='location'
            value={''}
            placeholder={$t.placeholder_location}
            query={query}
            query_coordinates={queryCoordinates}
            onHandleInput={handleInput}
            onOptionsUpdate={handleOptionsUpdate}
          />
        </div>
        <div className='location-container'>
          <OptionsRenderer
            location={data.location}
            options={options}
            onOptionSelect={handleOptionSelect}
          />
          <MapComponent
            location={data.location}
            handleMapClickInput={handleMapClickInput}
            onLocationSelect={handleOptionSelect}
          />
        </div>
        <button className={data.location === null ? 'apply-loc-button disabled' : 'apply-loc-button'} disabled={data.location === null} onClick={applyChanges}>{$t.edit_farm_label}</button>
      </section>
    </div>
  );
}