import $data from '../../services/$data'

class ecoData {
  getCrops = (setState) => {
    $data.ecCropTypes()
      .then(data => {
        return (
          setState({
            cropType: data
          }));
      })
      .catch(err => {
        console.log(err)
      })
  }

  getEcoTypes = (setState) => {
    $data.getEcoBenefitsTypes()
      .then(data => {
        return (
          setState({
            types: data
          }));
      })
      .catch(err => {
        console.log(err)
      })
  }

  getFuelPrices = (setState) => {
    $data.getFuelPrices()
      .then(data => {
        return (
          setState({
            fuel_prices: data,
          }));
      })
      .catch(err => {
        console.log(err)
      })
  }

  getEcoOperations = (setState) => {
    $data.getEcoBenefitsOperations()
      .then(data => {
        return (
          setState({
            operations: data
          }));
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export default new ecoData();