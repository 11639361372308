import React from 'react';

export default function InputField(props) {
  return (
    <React.Fragment>
      <div className="input-wrap">
        <input className={`input_field_text ${props.className ? props.className : ''}`} value={props.value ? props.value : ''} autoComplete={props.autoComplete !== undefined ? props.autoComplete : 'off'} type={props.type ? props.type : 'text'} name={props.name ? props.name : 'email'} placeholder={props.placeholder ? props.placeholder : ''} onChange={props.onChange ? props.onChange : null} onKeyPress={props.onKeyPress ? props.onKeyPress : null} maxLength={props.maxLength ? props.maxLength : 20}></input>
        {(props.error !== null && props.error !== undefined) ? <span className='error_text1'>{props.error}</span> : null}</div>
    </React.Fragment>
  );
}