import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../services/$contextProvider';
import useMergeState from "../../../services/$mergeState";
import $data from '../../../services/$data'

import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CircularProgress from '@mui/material/CircularProgress';

export default function FinalStep(props) {
  const { $t } = useContext(AppContext);
  const [data, setData] = useMergeState({ results: [], active: [], resultsLoaded: false, loading: true, error: {} });


  useEffect(() => {
    if (!data.resultsLoaded && data.loading === true && props.operations !== undefined) {
      getResults()
    }
  }, [])

  const handleActiveRow = (value) => {
    let exists = data.active.some(item => item.toString() === value.toString());
    if (exists) {
      let list = data.active.filter(item => item !== value);
      setData({ active: list })
    } else setData({ active: data.active.concat(value) })
  }

  const countOperations = (operations) => {
    try {
      let count = {}
      if (operations.cover.length > 0) {
        count.cover = operations.cover
      }
      if (operations.tillage.length > 0) {
        count.tillage = operations.tillage
      }
      if (operations.mulching.length > 0) {
        count.mulching = operations.mulching
      }
      return count
    } catch (e) {
      return {}
    }
  }

  const getResults = () => {
    setData({
      loading: true,
      active: [],
      error: {}
    })
    const ops = countOperations(props.operations)
    const lists = {
      part1: props.records,
      part2: ops,
      fuel_price: props.fuel_price
    }
    if (Object.keys(ops).length > 0) {
      $data.getEcResults(lists)
        .then(data => {
          return (
            setData({
              loading: false,
              results: data,
              resultsLoaded: true
            }))
        })
        .catch(err => {
          console.log(err)
          const er = {
            msg: $t.failed_data_error
          }
          setData({
            loading: false,
            resultsLoaded: true,
            error: er
          })
        })
    } else {
      const er = {
        msg: $t.failed_data_error
      }
      setData({
        loading: false,
        resultsLoaded: true,
        error: er
      })
    }
  }

  const GroupByOperation = (result) => {
    console.log(result)
    let cover = {}
    let tillage = {}
    result.map(res => {

    })
  }

  const renderOpeartionRows = (result, cover, tillage, mulching) => {
    return Object.keys(result).map((cover_name, key) => {
      return <React.Fragment key={key + 100}>
        {cover_name === 'cover_crop' && cover ? (
          <TableRow key={key}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={true} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    {$t.header_crop_cover}
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>{$t.header_cover_yield}</TableCell>
                        <TableCell align="right">{$t.header_cover_cost}</TableCell>
                        <TableCell align="right">{$t.header_cover_savings}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {result[cover_name].map((cc, index) => {
                        return <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {cc.data !== 'No data' ? (` ${cc.data.yield_increase.toFixed(1)}${$t.unit_t_ha}`) : 'n/a'}
                          </TableCell>
                          <TableCell>{cc.data !== 'No data' ? (` ${cc.data.cost.toFixed(1)}${$t.unit_eur_ha}`) : 'n/a'}</TableCell>
                          <TableCell align="right"> {cc.data !== 'No data' ? (` ${cc.data.savings.toFixed(1)}${$t.unit_eur_ha}`) : 'n/a'}</TableCell>
                        </TableRow>
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        ) : null}
        {cover_name === 'tillage' && tillage ? (
          <TableRow key={key}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={true} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    {$t.header_tillage}
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>{$t.header_tillage_no_savings}</TableCell>
                        <TableCell>{$t.header_tillage_savings}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {result[cover_name].map((till, index) => {
                        return <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {till.data['Savings of no tillage'] !== undefined ? (`${till.data['Savings of no tillage'].toFixed(1)}${$t.unit_eur_ha}`) : '-'}
                          </TableCell>
                          <TableCell>
                            {till.data['Savings of low tillage'] !== undefined ? (`${till.data['Savings of low tillage'].toFixed(1)}${$t.unit_eur_ha}`) : '-'}
                          </TableCell>
                        </TableRow>
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        ) : null}
        {cover_name === 'mulching' && mulching ? (
          <TableRow key={key}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={true} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    {$t.header_mulching}
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>{$t.header_mulching_irrigation}</TableCell>
                        <TableCell>{$t.header_mulching_fertilizer}</TableCell>
                        <TableCell>{$t.header_mulching_pesticide}</TableCell>
                        <TableCell>{$t.header_total_balance}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {result[cover_name].map((mulch, index) => {
                        return <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {mulch.data.savings.irrigation.toFixed(1)}{$t.unit_eur_ha}
                          </TableCell>
                          <TableCell> {mulch.data.savings.fertilizer.toFixed(1)}{$t.unit_eur_ha}</TableCell>
                          <TableCell> {mulch.data.savings.pesticide.toFixed(1)}{$t.unit_eur_ha}</TableCell>
                          <TableCell align="right">{mulch.data.economic_savings.total.toFixed(1)}{$t.unit_eur_ha}</TableCell>
                        </TableRow>
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        ) : null}
      </React.Fragment>
    })
  }

  const row = (name, cover, tillage, mulching, result, key) => {
    const c = cover ? <CheckOutlinedIcon className='positive' /> : <CloseIcon className='negative' />
    const t = tillage ? <CheckOutlinedIcon className='positive' /> : <CloseIcon className='negative' />
    const m = mulching ? <CheckOutlinedIcon className='positive' /> : <CloseIcon className='negative' />
    const selected = data.active.some(item => item === key)

    return (
      <React.Fragment key={key}>
        <TableRow key={key} sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => handleActiveRow(key)}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
            >
              {selected ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {name}
          </TableCell>
          <TableCell align="right">{c}</TableCell>
          <TableCell align="right">{t}</TableCell>
          <TableCell align="right">{m}</TableCell>
        </TableRow>
        {selected ? renderOpeartionRows(result, cover, tillage, mulching) : null}
      </React.Fragment>
    );
  }

  const renderTable = () => {
    let { results, active } = data;
    return <TableContainer>
      <Table aria-label="collapsible table" size='small'>
        <TableHead className='results-head'>
          <TableRow>
            <TableCell />
            <TableCell>{$t.header_crop}</TableCell>
            <TableCell align="center">{$t.header_crop_cover}</TableCell>
            <TableCell align="center">{$t.header_tillage}</TableCell>
            <TableCell align="center">{$t.header_mulching}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Object.keys(results).map((result, key) => {
              const cover = results[result].cover_crop.length !== 0 ? true : false;
              const tillage = results[result].tillage.length !== 0 ? true : false;
              const mulching = results[result].mulching.length !== 0 ? true : false;
              const crop_name = result
              return (
                row(crop_name !== undefined ? crop_name : 'undefined', cover, tillage, mulching, results[result], key)
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>

  }

  const renderContent = () => {
    let { results } = data;
    try {
      if (results !== undefined && Object.keys(results).length !== 0) {
        return <React.Fragment>{renderTable()}</React.Fragment>
      } else {
        return <div className='loader-container'><p>{$t.no_data_error}</p></div>
      }
    } catch (e) {
      console.log(e)
      return <div className='loader-container'><p>{$t.no_data_error}</p></div>
    }
  }

  return (
    <div className='final-container'>
      {(data.loading || props.types === undefined) ? <div className='loader-container'><CircularProgress size={20} /></div> : <div className='second-container'>
        {data.error.msg ? <Alert variant="outlined" severity="error">
          {data.error.msg}
        </Alert> : null}
        {renderContent()}
      </div>}
    </div>
  );
}