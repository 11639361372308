import { Alert, Button, CircularProgress, Container, ToggleButtonGroup, ToggleButton, Grid, Paper, Slide, Snackbar, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useContext, useEffect, useRef, useState } from "react";

import olMap from 'ol/Map';
import 'ol/ol.css';
import View from 'ol/View';
import { fromLonLat, toLonLat } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import BingMaps from "ol/source/BingMaps";
import MonthsForm from "./MonthsForm";
import MonthsCheckboxes from "./MonthsCheckboxes";
import ManualPools from "./ManualPools";
import ZimmermanForm from "./ZimmermanForm";
import SocForm from "./SocForm";
import Overlay from "ol/Overlay";
import { Room } from "@mui/icons-material";
import { renderToString } from "react-dom/server";
import axios from "axios";
import DataViewer from "./DataViewer";
import url from "../../config/urls";
import { AppContext } from "../../services/$contextProvider";

function RothC(props) {

  const { $t, onUpdateState } = useContext(AppContext);
  const mapRef = useRef(null);
  const mapInstance = useRef(null);

  const [mandatory, setMandatory] = useState(0);
  const [mandatoryOption, setMandatoryOption] = useState('agri');
  const [optional, setOptional] = useState(0);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);


  const [model, setModel] = useState({
    c_inputs: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,],
    dr: 'agri',
    period: 50,
    soil_thick: 30,
    clay: undefined,
    bare: [false, false, false, false, false, false, false, false, false, false, false, false,],
    soc_unit: 'Mg/ha'

  })

  const onSubmit = async () => {
    setLoading(true);
    try {
      let result = await axios.post(url.ROTHC, model);
      setData(result.data);
    } catch (error) {
      setError($t.submit_error);
    } finally {
      setLoading(false);
    }
  }

  const updateModel = (newModel = {}, toRemove = []) => {
    let updated = {
      ...model
    };

    toRemove.map(param => {
      delete updated[param];
    })

    updated = {
      ...updated,
      ...newModel
    }

    setModel(updated);
  }

  const onSelectLocation = (evt) => {
    let coordinates = toLonLat(evt.coordinate);
    mapInstance.current.getOverlays().getArray()[0].setPosition(evt.coordinate);
    updateModel({ lon: coordinates[0], lat: coordinates[1] });
  }

  useEffect(() => {
    if (props.active !== 'soc') {
      onUpdateState({ active: 'soc' })
    }
    let divA = document.createElement('div');
    divA.innerHTML = renderToString(<Room fontSize='large' color="error" style={{ fill: 'red' }} />);
    divA.style.width = '40px';
    let marker = new Overlay({
      id: 'A',
      element: divA,
      positioning: 'bottom-center',
      stopEvent: false,
      offset: [0, 5],
      className: 'co-marker-a',

    })

    mapInstance.current = new olMap({
      target: mapRef.current,
      overlays: [marker],
      layers: [
        new TileLayer({
          name: 'sat',
          zIndex: 0,
          visible: true,
          preload: Infinity,
          source: new BingMaps({
            crossOrigin: 'anonymous',
            key: 'AigxUF97RL2UxyfDdaqHdnHgiU09gnvCMBoNWg43uBo-vDSrw27g3bmhKlUgQEAb',
            imagerySet: "AerialWithLabels",
            wrapX: false,
            maxZoom: 19
          })
        })
      ],
      view: new View({
        zoom: 4,
        center: fromLonLat([20.5, 44])
      })
    })

    mapInstance.current.on('singleclick', onSelectLocation);
    return () => {
      if (mapInstance.current) {
        mapInstance.current.setTarget(null);
        mapInstance.current = null;
      }
    }
  }, [])

  useEffect(() => {
    updateModel(mandatory === 0 ? { c_inputs: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,] } : { c_inputs: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,], fym: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,] }, ['c_inputs', 'fym'])
  }, [mandatory])

  useEffect(() => {
    updateModel(null, ['rpm', 'dpm', 'bio', 'hum', 'iom', 'doc', 'sa', 'pom', 'sc', 'soc_unit', 'soc', 'r_soc', 'soc_year',  'bulk_dens'])

  }, [optional])

  useEffect(() => {
    updateModel({ dr: mandatoryOption })

  }, [mandatoryOption])

  useEffect(() => {
    updateModel({ bulk_dens:  ''})

  }, [model.soc_unit])



  const renderMap = () => {
    return (
      <Grid item xs={12} >
        <Paper ref={mapRef} style={{ marginTop: '20px', height: '400px' }}></Paper>
      </Grid>
    )
  }

  const renderMandatoryForm = () => {
    return (
      <>
        <Grid item xs={12}>
          <Paper className="co-background" sx={{ p: '10px' }}>
            <Typography variant="body1" className="text-primary"><strong>{$t.mandatory_option_tab_title}</strong></Typography>
            <Tabs sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#4A8522;'
              }
            }} value={mandatory} selectionFollowsFocus onChange={(evt, val) => setMandatory(val)}>
              <Tab className={"text-primary" + (mandatory === 0 ? ' text-bold' : '')} label="Option 1"></Tab>
              <Tab className={"text-primary" + (mandatory === 1 ? ' text-bold' : '')} label="Option 2"></Tab>
            </Tabs>
            <Box sx={{ mb: '10px', mt: '10px' }}>

              {mandatory === 0 && (
                <MonthsForm title={$t.c_inputs_soil_title} name="c_inputs" list={model.c_inputs || []} onUpdateModel={updateModel} />
              )}

              {mandatory === 1 && (<>
                <MonthsForm title={$t.c_inputs_litter_title} name="c_inputs" list={model.c_inputs || []} onUpdateModel={updateModel} />
                <br />
                <MonthsForm title={$t.c_inputs_yard_titles} name="fym" list={model.fym || []} onUpdateModel={updateModel} />
              </>
              )}
            </Box>
          </Paper>
        </Grid>
      </>
    )
  }

  const renderMandatoryOption = () => {
    return (
      <Grid item xs={12}>
        <Paper className="co-background" sx={{ p: '10px' }}>
          <Typography variant="body1" className="text-primary"><strong>{$t.plant_parcel_tab_title}</strong></Typography>
          <ToggleButtonGroup
            color="primary"
            className='mandatory-options'
            value={mandatoryOption}
            exclusive
            onChange={(evt, val) => {
              if (val !== null) {
                setMandatoryOption(val)
              }
            }}
            aria-label="DR"
          >
            <ToggleButton value="agri">{$t.plant_parcel_option1}</ToggleButton>
            <ToggleButton value="grass">{$t.plant_parcel_option2}</ToggleButton>
            <ToggleButton value="wood">{$t.plant_parcel_option3}</ToggleButton>
          </ToggleButtonGroup>
          <Box sx={{ mb: '10px', mt: '10px' }}>
          </Box>
        </Paper>
      </Grid>
    )
  }

  const renderOptionalForm = () => {
    return (
      <Grid item xs={12}>
        <Paper className="co-background" sx={{ p: '10px' }}>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1" className="text-primary"><strong>{$t.optional_parameters_title}</strong></Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth type="number" size="small" label={$t.prediction_period_future_label} value={model.period} onChange={(evt) => updateModel({ period: evt.target.value })}></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth type="number" size="small" label={$t.soil_thickness_label} value={model.soil_thick} onChange={(evt) => updateModel({ soil_thick: evt.target.value })}></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth type="number" inputProps={{ min: 0, max: 100 }} size="small" label={$t.clay_percent_label} value={model.clay} onChange={(evt) => updateModel({ clay: evt.target.value })}></TextField>
            </Grid>
            <Grid item xs={12}>
              <MonthsCheckboxes list={model.bare} onUpdateModel={updateModel} name="bare" />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  }

  const renderOptionalTabs = () => {
    return (
      <>
        <Grid item xs={12}>
          <Paper className="co-background" sx={{ p: '10px' }}>
            <Typography variant="body1" className="text-primary"><strong>{$t.optional_tables_title}</strong></Typography>
            <Tabs sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#4A8522;'
              }
            }} value={optional} selectionFollowsFocus onChange={(evt, val) => {
              updateModel(null, ['rpm', 'dpm', 'bio', 'hum', 'iom', 'doc', 'sa', 'pom', 'sc', 'soc_unit', 'soc', 'r_soc', 'soc_year', 'bulk_dens']);
              setOptional(val)
            }}>
              <Tab className={"text-primary" + (optional === 0 ? ' text-bold' : '')} label={$t.pedotransfer_functions_label}></Tab>
              <Tab className={"text-primary" + (optional === 1 ? ' text-bold' : '')} label={$t.manual_pools_label}></Tab>
              <Tab className={"text-primary" + (optional === 2 ? ' text-bold' : '')} label={$t.zimmermann_fractionation_label}></Tab>
            </Tabs>
            <Box sx={{ mb: '10px', mt: '10px' }}>

              {optional === 2 && (
                <ZimmermanForm onUpdateModel={updateModel} model={model} />
              )}

              {optional === 1 && (<>
                <ManualPools onUpdateModel={updateModel} model={model} />

              </>
              )}

              {optional === 0 && (<>
                <SocForm onUpdateModel={updateModel} model={model} />
              </>
              )}
            </Box>


          </Paper>
        </Grid>
      </>
    )
  }

  const renderLoader = () => {
    if (loading) return (
      <Stack sx={{ background: 'rgba(0,0,0,.7)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999999 }} justifyContent="center" alignItems="center">
        <CircularProgress sx={{ color: 'white' }}></CircularProgress>
      </Stack>
    );

    return null;
  }

  const renderContent = () => {
    return (
      <Grid container spacing={2}>
        {renderMap()}

        <Grid item xs={12} >
          <Paper sx={{ p: '10px' }} className="co-background">
            <Typography variant="body1" className="text-primary"><strong>{$t.location_mandatory_input_label}{model.lat && model.lon ? `${model.lat}, ${model.lon}` : ' - '}</strong> </Typography>
          </Paper>
        </Grid>
        {renderMandatoryOption()}
        {renderMandatoryForm()}

        <br />
        {renderOptionalForm()}
        <br />
        {renderOptionalTabs()}
        <Grid item xs={12} sx={{ position: 'sticky', bottom: 0, zIndex: 99 }}>
          <Paper className="co-background" sx={{ textAlign: 'center', padding: '10px', position: 'sticky', top: 0, zIndex: 99 }}>
            <Button className="background-primary" onClick={onSubmit} disabled={loading || !model.lat || !model.lon} variant="contained">{$t.submit_query}</Button>
          </Paper>
        </Grid>

      </Grid>
    )
  }

  return (
    <div className='rothc-content'>
      <Container className='rothc-container' sx={{ pb: '30px' }}>
        <Grid container spacing={2}>
        </Grid>
        {renderContent()}

        {renderLoader()}

        {data && <DataViewer open={Boolean(data)} onClose={() => setData(null)} data={data} />}

        {/* ERROR */}
        <Snackbar
          TransitionComponent={Slide}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          open={Boolean(error)}
          autoHideDuration={10000}
          onClose={(evt, reason) => setError(null)}
        >
          <Alert
            variant="filled"
            onClose={(evt, reason) => setError(null)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  )
}

export default RothC;