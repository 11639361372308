import React, { useContext } from 'react';
import { AppContext } from '../../services/$contextProvider';
import useMergeState from "../../services/$mergeState";
import { Link, Navigate } from 'react-router-dom';
import InputField from '../ui/InputField'
import $cookies from '../../services/$cookies';
import $auth from '../../services/$auth';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';



export default function Register(props) {
  const { $t } = useContext(AppContext);
  const [state, setState] = useMergeState({ email: '', password1: '', password2: '', loading: false, error: {} });

  const onHandleChange = (name, value) => {
    setState({ [name]: value });
  }

  const checkForms = (name, value) => {
    if (value === undefined || value === null || value === '') {
      setState({
        error: {
          [name]: $t[name]
        }
      })
      return false
    }
    if (name === 'email_error') {
      if (!/\S+@\S+\.\S+/.test(value)) {
        setState({
          error: {
            [name]: $t.email2_error
          }
        })
        return false
      }
    }
    return true
  }

  const onSubmit = (evt) => {
    evt.preventDefault();
    setState({ loading: true, error: {} });
    if (checkForms('email_error', state.email) && checkForms('password1_error', state.password1) && checkForms('password2_error', state.password2)) {
      $auth.register(state.email, state.password1, state.password2)
        .then(data => {
          $cookies.set('verification', `true|${$t.email_verification}`);
          setState({ loading: false, verificationSent: true, });
        })
        .catch(err => {
          console.log(err)
          setState({ error: err.message, loading: false });
        })
    } else {
      setState({ loading: false })
    }
  }

  if (state.verificationSent) {
    return <Navigate to="/login" />
  }

  return (
    <div className='auth-form'>
      <Snackbar open={state.error.non_field_errors ? true : false} autoHideDuration={6000} onClose={() => { setState({ error: {} }) }}>
        <MuiAlert elevation={6} variant="filled" severity="error">{state.error.non_field_errors}</MuiAlert>
      </Snackbar>
      <h1>{$t.register_title}</h1>
      <span className='register-link'>{$t.register_login_text1}
      </span>
      <span className='register-link'><Link className="text-bold" to="/login">{$t.register_login_text2}</Link></span>
      <InputField maxLength={32} error={state.error.email_error || state.error.error} value={state.email} type={'text'} name={'email'} placeholder={$t.placeholder_email} onChange={(evt) => onHandleChange('email', evt.target.value)} />
      <InputField error={state.error.password1_error} type={'password'} value={state.password1} name={'password'} placeholder={$t.placeholder_password} onChange={(evt) => onHandleChange('password1', evt.target.value)} />
      <InputField error={state.error.password2_error} type={'password'} value={state.password2} name={'password2'} placeholder={$t.placeholder_password2} onChange={(evt) => onHandleChange('password2', evt.target.value)} />
      <button disabled={state.loading ? true : false} className='signin-btn' onClick={(evt) => onSubmit(evt)}>{$t.submit}</button>
    </div>
  );
}