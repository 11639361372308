import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '../../../services/$contextProvider';
import useMergeState from "../../../services/$mergeState";
import _ from 'lodash';
import DropdownInput from '../../ui/DropdownInput';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SearchOffIcon from '@mui/icons-material/SearchOff';

export default function SurveyStepTwo(props) {
  const { $t } = useContext(AppContext);
  const [form, setForm] = useMergeState({ name: '', error: {} });

  const addPractice = () => {
    if (form.name.length > 0) {
      if (!listHasPracticeName(form.name)) {
        props.updatePractices({ regenerative_practice: (props.practices.length > 0 ? props.practices + ";" : "") + form.name, })
        setForm({
          name: '',
          error: {}
        })
      } else {
        setForm({
          error: {
            name: $t.practice_name2_error
          }
        })
      }
    } else {
      setForm({
        error: {
          name: $t.practice_name_error
        }
      })
    }
  }

  const handleChange = (practice) => {
    let practicesArr = props.practices.split(";");
    if (listHasPracticeName(practice)) {
      practicesArr = practicesArr.filter((item) => item !== practice);
      props.updatePractices({
        regenerative_practice: practicesArr.join(";")
      })
    } else {
      practicesArr.push(practice);
      props.updatePractices({
        regenerative_practice: practicesArr.join(";")
      })
    }
  }

  const listHasPracticeName = (name) => {
    const selected = props.practices.split(";");
    return selected.includes(name);
  }

  const renderForm = () => {
    return <React.Fragment>
      <div className='reg-flex-row'>
        <h2 className='form-header'>{$t.regenerative_practices}</h2>
      </div>
      <div className='reg-flex-row'>
        <div className='input-div pos-index'><DropdownInput error={form.error.name} value={form.name} className='input-s' type={'text'} onHandleInput={setForm} placeholder={$t.practice_name_label} /></div>
        <AddCircleOutlinedIcon className='icon-btn' onClick={addPractice} fontSize='large' />
      </div>
    </React.Fragment >
  }

  const renderAccordions = () => {
    if (props.practices.length === 0) {
      return <div className='reg-flex-row'>
        <div className='no-data-container'>
          <SearchOffIcon fontSize='large' />{$t.no_practice_found}</div>
      </div>
    } else {
      if (props.practices.length !== 0) {
        try {
          return <div className='reg-flex-column accordion-container'>
            <ul>
              {props.practices.split(";").map((practice, index) => {
                return <div className='accordion-item' key={index}>
                  <li
                    key={index}>
                    <h2>{practice}</h2>
                  </li><RemoveCircleIcon fontSize='large' onClick={() => handleChange(practice)} className='icon-btn-negative' />
                </div>
              })}
            </ul>
          </div>
        } catch (e) {
          console.log(e)
        }
      }
    }
  }


  return (
    <div className='form-container form-box'>
      {renderForm()}
      {renderAccordions()}
    </div>
  );
}