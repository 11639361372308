import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../services/$contextProvider';
import FileImport from '../../ui/ImportFile';
import Loader from '../../ui/Loader'
import MyButton from '../../ui/Button';
import useMergeState from '../../../services/$mergeState';
import $data from '../../../services/$data';
import { read, utils } from 'xlsx';
import { getCenter as getExtentCenter } from 'ol/extent';
import { transform } from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';


export default function ImportFarmModal(props) {
  const { $t } = useContext(AppContext);
  const [form, setForm] = useMergeState({ file: null, farm_list: [], isLoading: false, error: {} });
  const [isLoading, setLoading] = useState(false);
  const abortController = new AbortController();

  const cancelAdd = () => {
    abortController.abort();
    props.updatePracticeState({ importFarmModal: false })
  }

  useEffect(() => {
    if (!props.importFarmModal) {
      return () => {
        abortController.abort();
      };
    }
  }, [props.importFarmModal]);


  async function readExcel(event) {
    setForm({ isLoading: true });
    const file = event.target.files[0];
    const data = await file.arrayBuffer();
    const wb = read(data);
    const wsname = wb.SheetNames[0];
    if (checkItems(utils.sheet_to_json(wb.Sheets[wsname]))) {
      const farm_list = await formatItems(utils.sheet_to_json(wb.Sheets[wsname]), { signal: abortController.signal })
      console.log(farm_list)
      setForm({ file: event.target.files[0].name, isLoading: false, farm_list: farm_list })
    }
    else setForm({ isLoading: false, file: event.target.files[0].name + ` ${$t.invalid_format}` })
  }

  const checkItems = (items) => {
    try {
      let allHaveFields = true;
      items.forEach((item) => {
        if (!(!!item.Name && item.Name.trim()) ||
          (!(!!item.Coordinates && item.Coordinates.trim()) && !(!!item.Address && item.Address.trim()) && !(!!item.Country && item.Country.trim()))) {
          allHaveFields = false;
          return;
        }
      });
      return allHaveFields;
    } catch (e) {
      console.log(e);
      setForm({ isLoading: false });
      return false;
    }
  };

  async function formatItems(items) {
    try {
      let newFarmList = [];
      let promises = [];

      items.forEach((item, index) => {
        const practices = item.Practice ? item.Practice : '';
        const practices2 = item.Practice2 ? item.Practice2 : '';
        const benefits = item.Benefits ? item.Benefits : '';
        const barriers = item.Barriers ? item.Barriers : '';
        const crop_group = item.Products ? item.Products : '';

        if (item.Coordinates) {
          const { lng, lat } = convertStringToLatLng(item.Coordinates);
          if (lat !== null && lng !== null) {
            const prec = item.Precise !== undefined ? (item.Precise === 'true' ? true : false) : true
            const farm = returnFarmModel(item.Name, [lng, lat], item.Country ? item.Country : '', item.Region ? item.Region : '', practices, benefits, barriers, crop_group, null, item.Link, [lng, lat], practices2, prec)
            newFarmList.push(farm);
          }
        }
        else if (item.Address) {
          const promise = getCoordinateFromAddress(item, practices, practices2, benefits, barriers, crop_group, index).then(farm => {
            if (farm !== null) {
              newFarmList.push(farm);
            }
          });
          promises.push(promise);
        }
        else if (item.Country) {
          const promise = getFarmCoordinates(item, index, practices, practices2, benefits, barriers, crop_group)
            .then(farm => {
              if (farm !== null) {
                newFarmList.push(farm);
              }
            });
          promises.push(promise);
        }
      });

      await Promise.all(promises);
      console.log(newFarmList)
      return newFarmList;
    } catch (e) {
      return [];
    }
  }

  function convertStringToLatLng(str) {
    const decimalDegreesRegex = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;
    const degreesMinutesRegex = /^([0-9]{1,2})°([0-9]{1,2})’ ([NS]), ([0-9]{1,3})°([0-9]{1,2})’ ([EW])$/;

    if (decimalDegreesRegex.test(str)) {
      const [latStr, lngStr] = str.split(',');
      const lat = parseFloat(latStr.trim());
      const lng = parseFloat(lngStr.trim());
      return { lng, lat };
    } else if (degreesMinutesRegex.test(str)) {
      const [latStr, lngStr] = str.split(', ');
      const lat = parseCoordinate(latStr);
      const lng = parseCoordinate(lngStr);
      return { lng, lat };
    } else return { lng: null, lat: null }
  }

  function parseCoordinate(str) {
    const [degStr, minStr, dir] = str.split(/°|'/);

    const deg = parseInt(degStr);
    const min = parseInt(minStr);

    const dec = deg + (min / 60);

    return (dir === 'S' || dir === 'W') ? -dec : dec;
  }

  //get coordinates from farm 'address'
  const getCoordinateFromAddress = async (item, practices, practices2, benefits, barriers, crop_group, index) => {
    try {
      await new Promise(resolve => setTimeout(resolve, index * 1000));
      let data = await $data.getPointXlsx(`&q=${item.Address}`, { signal: abortController.signal });
      if (data !== undefined && data !== null && data.length !== 0) {
        if (data.geojson.type === 'Point') {
          const prec = item.Precise !== undefined ? (item.Precise === 'true' ? true : false) : true
          const farm = returnFarmModel(item.Name, data.geojson.coordinates, item.Country ? item.Country : '', item.Region ? item.Region : '', practices, benefits, barriers, crop_group, null, item.Link, data.geojson.coordinates, practices2, prec)
          return farm;
        } else {
          return null;
        }
      } else {
        return null;
      }

    } catch (err) {
      console.log(err);
      const er = {
        msg: 'request failed'
      };
      setForm({
        error: er
      });
      return null;
    }
  }

  //get coordinates for farm 'unprecise' location
  const getFarmCoordinates = async (item, index, practices, practices2, benefits, barriers, crop_group) => {
    try {
      await new Promise(resolve => setTimeout(resolve, index * 1000));
      let filter = parseGeographyFilter(item.Country, item.Region ? item.Region : '', item['Town/village/catchment'] ? item['Town/village/catchment'] : '')
      let data = await $data.getPolygonsXlsx(filter, { signal: abortController.signal });

      if (data !== undefined && data !== null && data.length !== 0) {
        if (data.geojson.type === 'Point') {
          const prec = item.Precise !== undefined ? (item.Precise === 'true' ? true : false) : false
          const farm = returnFarmModel(item.Name, data.geojson.coordinates, item.Country ? item.Country : '', item.Region ? item.Region : '', practices, benefits, barriers, crop_group, null, item.Link, data.geojson.coordinates, practices2, prec)
          return farm;
        } else {
          const format = new GeoJSON();
          const feature = format.readFeature(data.geojson, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          });

          const geometry = feature.getGeometry();
          const extent = geometry.getExtent();
          const center = getExtentCenter(extent);

          const centerLonLat = transform(center, 'EPSG:3857', 'EPSG:4326');
          const prec = item.Precise !== undefined ? (item.Precise === 'true' ? true : false) : false
          const farm = returnFarmModel(item.Name, centerLonLat, item.Country ? item.Country : '', item.Region ? item.Region : '', practices, benefits, barriers, crop_group, data.geojson.coordinates, item.Link, centerLonLat, practices2, prec)
          return farm;
        }

      } else {
        return null;
      }

    } catch (err) {
      console.log(err);
      console.log(item)
      const er = {
        msg: 'request failed'
      };
      setForm({
        error: er
      });
      return null;
    }
  }

  const parseGeographyFilter = (country, region, city) => {
    let filter = '&q='
    if (country && country.trim() !== '') {
      filter += `${country.trim()} `
    }
    if (region && region.trim() !== '') {
      filter += `${region.trim()} `
    }
    if (city && city.trim() !== '') {
      filter += `${city.trim()} `
    }
    return filter
  }

  const addFarmsFromFile = async () => {
    setLoading(true)
    if (form.file !== undefined && form.file !== null && form.farm_list.length !== 0) {
      try {
        JSON.parse(JSON.stringify(form.farm_list))
        await $data.postXslxFarm(JSON.parse(JSON.stringify(form.farm_list)));
        setLoading(false);
        props.farmHasUpdated();
        setForm({
          file: null,
          farm_list: []
        });
        cancelAdd();
      } catch (err) {
        console.log(err)
        setLoading(false)
        const er = {
          msg: 'request failed'
        }
        setForm({
          error: er
        })
      }
    }
  }

  const returnFarmModel = (name, coordinates, country, region, practices, benefits, barriers, crop_group, poly, source, centroid, practices2, precise) => {
    return {
      data: {
        farm_name: name,
        country: country,
        location: region,
        coordinates: coordinates,
        regenerative_practice: practices,
        regenerative_practice2: practices2,
        benefits: benefits,
        barriers_limitations: barriers,
        crop_group: crop_group,
        contact: '',
        source: source,
        poly: poly,
        centroid: centroid,
        precise: precise
      },
      image: []
    }
  }

  const renderForm = () => {
    return <FileImport chosen={form.file !== null ? form.file : null} label={$t.input_file_placeholder} selectedLabel={$t.label_strong} onChange={(e) => readExcel(e)} />
  }

  return (
    <div className='modal-container' onClick={() => { props.updatePracticeState({ importFarmModal: false }); abortController.abort(); }}>
      <div className='modal-form-wrapper' onClick={(e) => e.stopPropagation()}>
        <section className='farm-import-modal-header'><h2>{$t.import_farm_file_title}</h2></section>
        <section className='modal-form-content'>
          <div className='modal-form'>
            {renderForm()}
            {form.isLoading ? <Loader text={$t.parsing_file} /> : null}
            {/* {form.error && form.error.msg ? <p>{form.error.msg}</p> : null} */}
          </div>
          <div className='import-modal-btn'>
            <MyButton negative onClick={cancelAdd} label={$t.cancel} />
            <MyButton disabled={form.isLoading || form.file === null || isLoading ? true : false} isLoading={isLoading} onClick={() => { addFarmsFromFile() }} label={$t.add} />
          </div>
        </section>
      </div>
    </div>
  );
}
