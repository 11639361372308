import React from 'react';

export default function MyButton(props) {
  return (
    <React.Fragment>
      {props.negative ? <button disabled={props.disabled ? props.disabled : false} className={`mbtn negative ${props.className ? props.className : ''}`} onClick={props.onClick ? props.onClick : null}>{props.label ? props.label : null}</button> :
        <button disabled={props.disabled ? props.disabled : false} className={`mbtn ${props.className ? props.className : ''}`} onClick={props.onClick ? props.onClick : null}>
          {props.isLoading ? <div className="spinner"></div>
            : props.label ? props.label : null}
        </button>}
    </React.Fragment>
  );
}