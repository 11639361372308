import React, { useContext } from 'react';
import { AppContext } from '../../../services/$contextProvider';
import MyButton from '../../ui/Button';
import useMergeState from '../../../services/$mergeState';
import InputField from '../../ui/InputField';
import FilterPracticeDropdown from './modalComponents/FilterPracticeDropdown';
import FilterCropGroupDropdown from './modalComponents/FilterCropGroupDropdown';



export default function FilterFarmsModal(props) {
  const { $t } = useContext(AppContext);
  const [form, setForm] = useMergeState({ loading: false });

  const cancelAdd = () => {
    props.updatePracticeState({ filterModal: false })
  }

  const applyFilter = () => {
    props.applyFarmFilter()
    props.updatePracticeState({ filterModal: false })
  }

  return (
    <div className='filter-modal-container' onClick={() => props.updatePracticeState({ filterModal: false })}>
      <div className='filter-modal-form-wrapper' onClick={(e) => e.stopPropagation()}>
        <section className='modal-header'><h2>{$t.filter_header_title}</h2> <button
          className="remove-button"
          onClick={cancelAdd}
        >X</button></section>
        <section className='modal-form'>
          <div className='input-container'>
            <InputField className='input-s' type={'text'} value={props.form.farm_name} onChange={(evt) => props.setForm({ farm_name: evt.target.value })} placeholder={$t.filter_placeholder_name} />
          </div>
          <div className='input-container filter-dropdown-wrapper'>
            <FilterPracticeDropdown
              className='input-s'
              type='text'
              value={props.form.regenerative_practice2}
              uq_practices={props.uq_practices}
              onChange={props.setForm}
              placeholder={$t.filter_placeholder_practice}
            />
          </div>
        </section>
        <section className='cg-modal-form'>
          <div className='input-container filter-dropdown-wrapper'>
            <FilterCropGroupDropdown
              className='input-s'
              type='text'
              value={props.form.crop_group}
              onChange={props.setForm}
              placeholder={$t.filter_placeholder_crop_group}
            />
          </div>
        </section>
        <div className='modal-btn'>
          <MyButton disabled={form.isLoading ? true : false} onClick={() => { applyFilter() }} label={$t.apply} />
        </div>
      </div>
    </div>
  );
}