import React, { useState, useEffect, useContext } from 'react';
import { Routes, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import '../styles/main.scss';
import translation from '../config/locale/translation';
import ContextProvider, { AppContext } from '../services/$contextProvider';
import LoginModal from './auth/LoginModal';
import $interceptor from '../services/$interceptor';
import $bookmark from '../services/$bookmark';
import $cookies from '../services/$cookies';
import $auth from '../services/$auth';
import logo from '../assets/ac.webp';
import euflag from '../assets/EU-flag.png';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Drawer, List, ListItem, Paper } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import About from './about/About';
import Auth from './auth/Auth';
import EconomicalBenefits from './eco/EconomicalBenefits';
import BestPractice from './practice/BestPractice';
import FarmSurvey from './practice/FarmSurvey';
import { Menu } from '@mui/icons-material';
import Dashboard from './profile/Dashboard';
import RothC from './rothc2/RothC';

const App = ({ locale }) => {
  const [isLoginModalVisible, setLoginModalVisibility] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [appData, setAppData] = useState({
    locale: locale,
    pane: 1
  });
  const [active, setActive] = useState('home');
  const [drawer, setDrawer] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const $t = translation[appData.locale];

  const showLoginModal = () => {
    setLoginModalVisibility(true);
  };

  useEffect(() => {
    // Initialize interceptor on start
    $interceptor.setShowLoginModal(showLoginModal);
    $interceptor.init();
    userLogon();

    return () => {
      //todo unsubscribe
    };
  }, []);

  const updateState = (newState) => {
    setLoggedIn(newState.loggedIn || loggedIn);
    setUser(newState.user || user);
    setAppData(newState.appData || appData);
    setActive(newState.active || active);
    setDrawer(newState.drawer || drawer);
  };

  const setUrlParams = (page) => {
    // $bookmark.change({
    //   page: page
    // });
  };

  const userLogon = () => {
    if ($cookies.get('user') !== null && $cookies.get('user') !== undefined) {
      try {
        if (!loggedIn) {
          const ut = JSON.parse($cookies.get('user'));
          setLoggedIn(true);
          setUser(ut);
        }
      } catch (e) {
        invalidate();
      }
    }
  };

  const rendProfileItem = ($t) => {
    return (
      <React.Fragment>
        <div className='profile-menu'>
          <div className='dropdown-wrapper'>
            <div onClick={() => setProfileMenu(!profileMenu)} className={profileMenu ? 'auth-in open' : 'auth-in closed'}>
              <AccountCircleIcon />{user.email}
            </div>
            {profileMenu ?
              <div className='menu'>
                <Link className='menu-link' onClick={() => updateState({ active: 'profile', profileMenu: false })} to="/profile">{$t.profile}</Link>
                <span onClick={() => { invalidate(); }}>{$t.sign_out}</span>
              </div>
              : null}
          </div>
        </div>
      </React.Fragment>);
  };

  const invalidate = () => {
    updateState({ loggedIn: false, user: null, profileMenu: false });
    $auth.unauthorize();
  };

  return (
    <ContextProvider value={{ $t: translation[appData.locale], onUpdateState: updateState, showLoginModal, setProfileMenu }}>
      <Router>
        <AppContext.Consumer>
          {({ hideLoginModal }) => (
            <LoginModal
              isVisible={isLoginModalVisible}
              onHide={() => {
                setLoginModalVisibility(false);
                hideLoginModal();
              }}
              onUpdateState={updateState}
            />
          )}
        </AppContext.Consumer>
        <div className="app-container" style={(active === 'home' || active === 'auth') ? {} : { overflow: 'hidden' }}>
          <Paper elevation={3} className={active === 'auth' ? 'exploration-header hidden' : 'exploration-header'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex: 5 }}>
            <a target="_blank" href="https://agricaptureco2.eu/"><img src={logo} width="300" /></a>
            <div className='desktop-only'>
              <Link onClick={() => updateState({ active: 'home' })} style={{ marginLeft: '15px' }} className={active !== 'home' ? 'nav_link' : 'nav_link active'} to="/">{$t.home_link}</Link>
              <Link onClick={() => updateState({ active: 'soc' })} style={{ marginLeft: '15px' }} className={active !== 'soc' ? 'nav_link' : 'nav_link active'} to="/sequestration">{$t.soc_link}</Link>
              <Link onClick={() => updateState({ active: 'econ' })} style={{ marginLeft: '15px' }} className={active !== 'econ' ? 'nav_link' : 'nav_link active'} to="/benefits">{$t.eco_link}</Link>
              <Link onClick={() => updateState({ active: 'practice' })} style={{ marginLeft: '15px' }} className={active !== 'practice' ? 'nav_link' : 'nav_link active'} to="/map">{$t.best_practice_link}</Link>
              {loggedIn ? rendProfileItem(translation[appData.locale]) : <Link onClick={() => updateState({ active: 'login' })} style={{ marginLeft: '15px' }} className={active !== 'auth' ? 'auth nav_link' : 'auth nav_link active'} to="/login"><AccountCircleIcon />{$t.sign_in}</Link>}
            </div>
            <Menu onClick={() => updateState({ drawer: true })} className='exploration-menu-icon' />
          </Paper>
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/login" element={<Auth loggedIn={loggedIn} active={active} page={'login'} />} />
            <Route path="/register" element={<Auth loggedIn={loggedIn} active={active} page={'register'} />} />
            <Route path="/profile" element={<Dashboard loggedIn={loggedIn} user={user} active={active} />} />
            <Route path="/sequestration" element={<RothC />} />
            <Route path="/benefits" element={<EconomicalBenefits />} />
            <Route path="/map" element={<BestPractice loggedIn={loggedIn} active={active} />} />
            <Route path="/survey" element={<FarmSurvey loggedIn={loggedIn} user={user} active={active} />} />
            <Route path="*" element={<About />} />
          </Routes>
          {(active === 'home' || active === 'auth') ? <footer className="exploration-footer">
            <a href="https://gilab.rs/" style={{ marginLeft: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'none', color: '#333' }} target="_blank">{$t.powered_by}<img width="120" src="/assets/gilab.svg" /></a>
            <div className='cc-link-container'>
              <div className='contact'>{$t.text_contact}</div>
              <div>acexploration@gilab.rs</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: 500 }} >
              <div className='pp-link-container'>
                <a className='f-nm-link' href="#" target="_blank">{$t.text_pp}</a>
                <a className='f-nm-link' href="#" target="_blank">{$t.text_tm}</a>
              </div>
              <img src={euflag} width="100" />&nbsp;&nbsp;&nbsp;
              <div style={{ fontSize: '11px', maxWidth: 233 }} className='f-links'>{$t.agreement}<a className='f-nm-link' href="https://cordis.europa.eu/project/id/101004282" target="_blank">no. 101004282.</a></div>
            </div>
          </footer> : null}

          <Drawer open={drawer} onClose={() => updateState({ drawer: false })}>
            <List>
              <ListItem><Link onClick={() => updateState({ drawer: false })} style={{ display: 'block', width: '100%', fontWeight: 'bold', textDecoration: 'none' }} className={active === 'home' ? 'text-secondary' : 'text-primary'} to="/">{$t.home_panel_link}</Link></ListItem>
              <ListItem><Link onClick={() => updateState({ drawer: false })} style={{ display: 'block', width: '100%', fontWeight: 'bold', textDecoration: 'none' }} className={active === 'soc' ? 'text-secondary' : 'text-primary'} to="/sequestration">{$t.soc_link_panel_link}</Link></ListItem>
              <ListItem><Link onClick={() => updateState({ drawer: false })} style={{ display: 'block', width: '100%', fontWeight: 'bold', textDecoration: 'none' }} className={active === 'econ' ? 'text-secondary' : 'text-primary'} to="/benefits">{$t.eco_panel_link}</Link></ListItem>
            </List>
            <div className='panel-sign-out'>{$t.sign_out}&nbsp;<ExitToAppIcon fontSize='medium' /></div>
          </Drawer>
        </div>
      </Router>
    </ContextProvider>
  );
};

export default App;