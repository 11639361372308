import React, { useContext, useEffect, useRef, useState } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { AppContext } from '../../../services/$contextProvider';
import useMergeState from "../../../services/$mergeState";
import { View, Map } from "ol";
import { fromLonLat, transformExtent } from 'ol/proj';
import * as olExtent from 'ol/extent';
import { defaults as defaultInteractions } from 'ol/interaction';
import { ZoomToExtent, defaults as defaultControls } from 'ol/control.js';
import _ from 'lodash';
import SearchLocation from '../../ui/SearchLocation';
import ImportFarmModal from '../modals/ImportFarmModal';
import FilterFarmsModal from '../modals/FilterFarmsModal';
import MapFarmLegend from './MapFarmLegend'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import TuneIcon from '@mui/icons-material/Tune';
import FilterListOffSharpIcon from '@mui/icons-material/FilterListOffSharp';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import MapLegendIcon from '../../../assets/map_legend_white.png'
import $map from '../../../services/$map';
import useMobileScreen from '../../../services/$isMobileScreen';
import FarmInformationTab from './FarmInformationTab';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import $data from '../../../services/$data';
import { Tooltip } from '@mui/material';

function BestPracticeMap(props) {
  const { $t, onUpdateState } = useContext(AppContext);
  const ref = useRef(null);
  const mapRef = useRef(null);
  const isMobile = useMobileScreen();
  const [state, setState] = useMergeState({ base: 'map', geolocation: null, zoom: 1, zoomToFarm: null, isFiltered: false, farms: [], visiblePoints: [], selected: null, drawer: true, filterModal: false, error: {} });
  const [filter, setFilter] = useMergeState({ farm_name: '', regenerative_practice2: '', crop_group: '' });
  const [farm_layer, setFarm_layer] = useState(null)
  const farmLayerRef = useRef(null);
  let basemaps = null


  useEffect(() => {
    if (ref.current && !mapRef.current) {
      onUpdateState({ active: 'practice' })
      mapInit()
    }
  }, [ref, mapRef]);

  useEffect(() => {
    mapRef.current?.getView().setZoom(state.zoom);
  }, [mapRef, state.zoom]);

  useEffect(() => {
    if (basemaps !== null) $map.setLayer(state.base, basemaps);
  }, [state.base]);

  useEffect(() => {
    if (state.zoomToFarm !== null && state.zoomToFarm !== undefined) {
      mapRef.current.getView().fit(state.zoomToFarm, { duration: 1000 });
    }
  }, [state.zoomToFarm]);

  const updateState = (state) => {
    setState(state)
  }

  useEffect(() => {
    if (filter.farm_name === '' && filter.regenerative_practice2 === '', filter.crop_group === '') {
      filterFarms()
    }
  }, [filter]);

  const updateMap = (coords) => {
    if (mapRef.current) {
      const extentTransformed = transformExtent(coords, 'EPSG:4326', 'EPSG:3857');
      mapRef.current?.getView().fit(extentTransformed, { duration: 500 });
    }
  }

  const filterFarms = () => {
    if (farm_layer !== null) {
      const newLayer = $map.filterFeatures(filter);
      mapRef.current.removeLayer(farm_layer);
      mapRef.current.addLayer(newLayer);
      setFarm_layer(newLayer);
      farmLayerRef.current = newLayer;
      resetMapView()
    }

    if (filter.farm_name === '' && filter.regenerative_practice2 === '', filter.crop_group === '') {
      setState({ isFiltered: false })
    } else setState({ isFiltered: true })
  }


  const clearFilter = () => {
    setFilter({ farm_name: '', regenerative_practice2: '', crop_group: '' })
  }

  const fetchUpdatedFarmPoints = () => {
    $data.getFarms(1)
      .then(data => {
        $map.refreshFarmLayer(data.data, mapRef.current);
        updateState({ importFarmModal: false })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const mapInit = () => {
    basemaps = $map.createBasemaps(state.base);
    setFarm_layer(props.farm_points_layer)
    farmLayerRef.current = props.farm_points_layer;
    mapRef.current = new Map({
      target: ref.current,
      controls: defaultControls().extend([
        new ZoomToExtent({
          extent: [-20026376.39, -20048966.10, 20026376.39, 20048966.10],
          label: returnExtentLabel(),
        }),
      ]),
      interactions: defaultInteractions({ altShiftDragRotate: false, pinchRotate: false }),
      layers: _.concat(basemaps, props.farm_points_layer !== null ? props.farm_points_layer : []),
      view: new View({
        zoom: 5,
        maxZoom: 16,
        center: fromLonLat([
          -31.5566806,
          39.4589068
        ])
      })
    });
    onPointHover()
    onPointClick()
    onMapViewLocationFilter()
  }

  const returnExtentLabel = () => {
    var zoom = document.createElement('div');
    zoom.innerHTML = ReactDOMServer.renderToString(<OpenWithIcon />);
    return zoom
  }

  const onPointHover = () => {
    mapRef.current.on('pointermove', evt => {
      if (!evt.dragging) {
        mapRef.current.getTargetElement().style.cursor = mapRef.current.hasFeatureAtPixel(mapRef.current.getEventPixel(evt.originalEvent)) ? 'pointer' : '';
      }
    });
  }

  const onPointClick = () => {
    mapRef.current.on('singleclick', (evt) => {
      let farmClicked = mapRef.current.getFeaturesAtPixel(evt.pixel, { layerFilter: l => l.get('name') === 'farm_points' });

      try {
        if (farmClicked.length > 0) {
          let selectedFeatures = farmClicked[0].values_.features;
          if (selectedFeatures.length > 1) {
            let ext = olExtent.createEmpty();
            selectedFeatures.forEach(feature => {
              olExtent.extend(ext, feature.getGeometry().getExtent());
            });

            mapRef.current.getView().fit(ext);
          }
          else {
            setState({ selected: selectedFeatures[0] });
            let ext = olExtent.createEmpty();
            selectedFeatures.forEach(feature => {
              olExtent.extend(ext, feature.getGeometry().getExtent());
            });

            mapRef.current.getView().fit(ext);
          }
          return
        }
      } catch (e) {
        console.log(e)
        return
      }
    })
  }

  const onMapViewLocationFilter = () => {
    mapRef.current.on('moveend', renderFarmPointsOnView)
  }

  const renderFarmPointsOnView = (evt) => {
    if (farmLayerRef.current !== null) {
      const extent = mapRef.current.getView().calculateExtent(mapRef.current.getSize());
      let farmsAtLocation = [];

      farmLayerRef.current.getSource().forEachFeature((cluster) => {
        const features = cluster.get('features');

        features.forEach((feature) => {
          if (olExtent.intersects(extent, feature.getGeometry().getExtent())) {
            farmsAtLocation.push(feature);
          }
        });
      });

      if (farmsAtLocation.length >= 0) {
        setState({ visiblePoints: farmsAtLocation });
      }
    }
  }

  const resetMapView = () => {
    const view = mapRef.current.getView();
    view.setCenter(fromLonLat([
      -31.5566806,
      39.4589068
    ]));
    view.setZoom(3);
    setTimeout(() => {
      view.setZoom(2);
    }, 100);
  }

  return (
    <div className='best-practice-container'>
      {state.importFarmModal ? <ImportFarmModal farmHasUpdated={fetchUpdatedFarmPoints} updatePracticeState={updateState} /> : null}
      {state.filterModal ? <FilterFarmsModal uq_practices={props.uq_practices} form={filter} setForm={setFilter} applyFarmFilter={filterFarms} updatePracticeState={updateState} /> : null}
      <div className='practice-map-container' ref={ref}>
        <section className={state.drawer ? 'map-tools-container' : 'map-tools-container drawer-width'}>
          <SearchLocation
            className={'loc-search-bar'}
            map={mapRef}
            geolocation={state.geolocation}
            isFiltered={state.isFiltered}
            icon={<TuneIcon style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => setState({ filterModal: !state.filterModal })} size='small' />}
            icon2={<Tooltip title={$t.clear_filter_tooltip}>
              <FilterListOffSharpIcon style={{ marginLeft: '10px', color: 'red', cursor: 'pointer', opacity: '0.8' }} onClick={() => clearFilter()} size='small' />
            </Tooltip>}
            onUpdateState={updateState} updateMap={updateMap} />
          <button className={state.drawer ? 'panel-btn' : 'panel-btn right'} onClick={() => setState({ drawer: state.drawer ? false : true })}><KeyboardArrowRightIcon /></button>
        </section>
        <aside className='map-aside-btns'>
          {props.loggedIn && props.xlsxA ? <button className='import-farm-btn' onClick={() => setState({ importFarmModal: true })}><AddLocationAltIcon /></button> : null}
          {!isMobile ?
            <button className='legend-farm-btn' onMouseEnter={() => setState({ mapLegend: true })} onMouseLeave={() => setState({ mapLegend: false })}><img src={MapLegendIcon} alt="Button Icon" /></button>
            : <button className='legend-farm-btn' onClick={() => setState({ mapLegend: !state.mapLegend })}><img src={MapLegendIcon} alt="Button Icon" /></button>}
          {state.mapLegend ? <MapFarmLegend /> : null}
        </aside>
      </div>

      {!isMobile ? (
        <div className={state.drawer ? 'farm-information-drawer open' : 'farm-information-drawer'}>
          <FarmInformationTab list={state.visiblePoints} selected={state.selected} setMapState={updateState} />
        </div>
      ) : <SwipeableBottomSheet
        open={state.drawer}
        onChange={() => setState({ drawer: !state.drawer })}
        scrollTopAtClose
        style={{ zIndex: '4' }}
        bodyStyle={{ maxWidth: window.innerWidth }}
        overflowHeight={window.innerHeight * 0.3}>
        <div className={state.drawer ? 'farm-information-drawer open' : 'farm-information-drawer'}>
          <FarmInformationTab list={state.visiblePoints} selected={state.selected} setMapState={updateState} isMobile={isMobile} />
        </div>
      </SwipeableBottomSheet>}
    </div>
  )
}

export default BestPracticeMap;

// && props.xlsxA