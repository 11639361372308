import React, { useState, useContext } from 'react';
import InputField from '../../ui/InputField';
import { AppContext } from '../../../services/$contextProvider';

function BenefitsLimitations() {
  const { $t } = useContext(AppContext);
  const [selectedType, setSelectedType] = useState('benefits');
  const [name, setName] = useState('');
  const [benefitsList, setBenefitsList] = useState("");
  const [barriersList, setBarriersList] = useState("");

  const handleNameChange = e => setName(e.target.value);

  const handleAddClick = () => {
    if (name) {
      const newItem = name;
      if (selectedType === 'benefits') setBenefitsList(benefitsList ? benefitsList + ';' + newItem : newItem);
      else if (selectedType === 'barriers') setBarriersList(barriersList ? barriersList + ';' + newItem : newItem);
      setName('');
    }
  };

  const handleDeleteClick = (list, index) => {
    const listArray = list.split(";");
    listArray.splice(index, 1);
    const newList = listArray.join(";");
    if (selectedType === 'benefits') setBenefitsList(newList);
    else if (selectedType === 'barriers') setBarriersList(newList);
  };

  const displayList = () => {
    const list = selectedType === 'benefits' ? benefitsList : barriersList;
    if (!list || !list.split(";").length) return null;
    return (
      <>
        <h2>{selectedType === 'benefits' ? 'Benefits' : 'Barriers'}</h2>
        <ul className='list-row'>
          {list.split(";").map((item, index) => (
            <li className='list-item ' key={index}>
              <strong>{item}</strong>
              <button className='delete-button' onClick={() => handleDeleteClick(list, index)}>Delete</button>
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <div className="benefits-limitations-container">
      <div className='form-area'>
        <h2 className='form-header'>{$t.benefits_barriers}</h2>
        <div className="toggle-buttons">
          <button className={`toggle-button ${selectedType === 'benefits' ? 'active' : ''}`} onClick={() => setSelectedType('benefits')}>{$t.benefits_label}</button>
          <button className={`toggle-button ${selectedType === 'barriers' ? 'active' : ''}`} onClick={() => setSelectedType('barriers')}>{$t.barriers_label}</button>
        </div>
        {selectedType && (
          <div className='input-container'>
            <InputField className='input-field' value={name} type={'text'} onChange={handleNameChange} maxLength={16} placeholder={`${selectedType === 'benefits' ? $t.benefits_name_placeholder1 : $t.barriers_name_placeholder1}${16}${$t.benefits_name_placeholder2}`} />
            <button className="add-button" onClick={handleAddClick}>Add</button>
          </div>
        )}
      </div>
      <div className="list-area">
        {displayList()}
      </div>
    </div>
  );
}

export default BenefitsLimitations;