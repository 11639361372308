import React, { useContext, useEffect, useState } from 'react';
import defaultFarmPic from '../../../assets/not_found.png';
import $data from '../../../services/$data';
import FarmField from './FarmPracticesField';
import LocationModal from '../../ui/location_finder/LocationModal';
import { AppContext } from '../../../services/$contextProvider';

export default function FarmPreview(props) {

  const { $t } = useContext(AppContext);
  const [message, setMessage] = useState(null)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [locationEdit, setLocationEdit] = useState(false);
  const [editableFarm, setEditableFarm] = useState(props.farm);
  const [newItems, setNewItems] = useState({
    regenerative_practice: "",
    benefits: "",
    barriers_limitations: ""
  });

  const farmImage = props.farm.photos ? (props.farm.photos.length > 0 ? props.farm.photos[0].image : defaultFarmPic) : null;

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message]);

  const handleDeleteFarm = () => {
    if (deleteConfirmation) {
      $data.deleteFarm(props.farm.id)
        .then(data => {
          props.loadFarms();
          props.onGoBack()
          setDeleteConfirmation(false);
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      setDeleteConfirmation(true);
    }
  }

  const handleFarmUpdate = async () => {
    const farmToUpdate = {
      ...props.farm,
      ...editableFarm,
    }

    $data.updateFarm(props.farm.id, farmToUpdate)
      .then(data => {
        props.loadFarms();
        props.updateEditedFarm(data);
        setEditMode(false);
        setMessage({ type: 'SUCCESS', text: $t.success_message_profile });
      })
      .catch(err => {
        console.log(err);
        setMessage({ type: 'ERROR', text: $t.error_message_profile });
      });
  };

  const handleFarmImageUpdate = async (images, option) => {
    $data.updateFarmPhotos(props.farm.id, images, option)
      .then(data => {
        props.loadFarms();
        props.updateEditedFarm(data[0]);
        setEditMode(false);
        setMessage({ type: 'SUCCESS', text: $t.success_message_profile });
      })
      .catch(err => {
        console.log(err);
        setMessage({ type: 'ERROR', text: $t.error_message_profile });
      });
  };

  const handleRemoveItem = (itemToRemove, fieldName) => {
    const updatedField = editableFarm[fieldName].replace(`;${itemToRemove}`, '').replace(itemToRemove, '');
    setEditableFarm({ ...editableFarm, [fieldName]: updatedField });
  };

  const handleAddItem = fieldName => {
    const updatedField = editableFarm[fieldName] ? `${editableFarm[fieldName]};${newItems[fieldName]}` : newItems[fieldName];
    setEditableFarm({ ...editableFarm, [fieldName]: updatedField });
    setNewItems({ ...newItems, [fieldName]: "" });
  };

  const handleImageAdd = (event) => {
    const files = event.target.files;
    const filesArray = Array.from(files);
    let images = {
      image: []
    }

    filesArray.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        images.image = [
          ...images.image,
          reader.result,
        ]
        handleFarmImageUpdate(images, 1)
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageRemove = (imageToRemove) => {
    handleFarmImageUpdate(imageToRemove, 2);
  };

  const Message = ({ type, text }) => {
    const messageStyles = {
      SUCCESS: 'success-message',
      ERROR: 'error-message',
    };

    return (
      <div className={messageStyles[type]}>
        {text}
      </div>
    );
  };

  return (
    <div className='FarmPreview'>
      {editMode && locationEdit ? <LocationModal editableFarm={editableFarm} setEditableFarm={setEditableFarm} onClose={() => setLocationEdit(false)} /> : null}
      {message && <div className='message-container'><Message type={message.type} text={message.text} /></div>}
      <h1 className='farm-information-header'>{$t.farm_preview_title}{editMode && $t.farm_preview_editing}</h1>
      <button className="go-back-button" onClick={props.onGoBack}>{$t.farm_preview_go_back}</button>

      <div className="header-row">
        <div className="farm-location-container">
          {editMode ? (
            <input
              style={{ marginBottom: '1rem' }}
              value={editableFarm.farm_name}
              onChange={e => setEditableFarm({ ...editableFarm, farm_name: e.target.value })}
            />
          ) : (
              <h1 className='farm-name'>{props.farm.farm_name}</h1>
            )}
          {props.farm.location ? (editMode ? <p className='location editing' onClick={() => setLocationEdit(true)}>{editableFarm.location}</p> : <p className='location'>{props.farm.location}</p>) : null}
          <div className="buttons-container">
            {!editMode && !deleteConfirmation ? <button className='edit-mode-button' onClick={() => setEditMode(true)}>{$t.edit_farm_label}</button> : null}
            {editMode && <button className='edit-mode-button' onClick={handleFarmUpdate}>{$t.edit_farm_save}</button>}
            {editMode && <button className='cancel-button' onClick={() => { setEditMode(false); setEditableFarm(props.farm); }}>{$t.cancel}</button>}
            {!editMode && deleteConfirmation ? (
              <>
                <button className='delete-farm-button' onClick={handleDeleteFarm}>{$t.delete_farm_confirm}</button>
                <button className='cancel-button' onClick={() => setDeleteConfirmation(false)}>{$t.cancel}</button>
              </>
            ) : !editMode ? (
              <button className='delete-farm-button' onClick={handleDeleteFarm}>{$t.delete_farm_label}</button>
            ) : null}
          </div>
        </div>
        <img src={farmImage} alt="Farm" className="farm-image" />
      </div>
      {(editMode || editableFarm.regenerative_practice) && (
        <div className='regenerative_practice'>
          <h2>{$t.farm_practices_label}</h2>
          <FarmField
            fieldValue={editMode ? editableFarm.regenerative_practice : props.farm.regenerative_practice}
            fieldName='regenerative_practice'
            isEditable={editMode}
            handleRemoveItem={handleRemoveItem}
            handleAddItem={handleAddItem}
            newItems={newItems}
            setNewItems={setNewItems}
          />
        </div>
      )}

      {(editMode || editableFarm.benefits) && (
        <div className='benefits'>
          <h2>{$t.farm_benefits_label}</h2>
          <FarmField
            fieldValue={editMode ? editableFarm.benefits : props.farm.benefits}
            fieldName='benefits'
            isEditable={editMode}
            handleRemoveItem={handleRemoveItem}
            handleAddItem={handleAddItem}
            newItems={newItems}
            setNewItems={setNewItems}
          />
        </div>
      )}

      {(editMode || editableFarm.barriers_limitations) && (
        <div className='barriers_limitations'>
          <h2>{$t.farm_limitations_label}</h2>
          <FarmField
            fieldValue={editMode ? editableFarm.barriers_limitations : props.farm.barriers_limitations}
            fieldName='barriers_limitations'
            isEditable={editMode}
            handleRemoveItem={handleRemoveItem}
            handleAddItem={handleAddItem}
            newItems={newItems}
            setNewItems={setNewItems}
          />
        </div>
      )}


      <section className='gallery-header'><h2 className='gallery-title'>{$t.image_gallery_title}</h2>

        <label htmlFor="file-input" className="add-image-button">
          <span>{$t.upload_label}</span>
        </label>
        <input
          type="file"
          multiple
          disabled={editMode ? true : false}
          onChange={handleImageAdd}
          className="file-input"
          accept='.png,.jpg,.jpeg'
          id="file-input"
        />
      </section>
      <div className="image-gallery">
        {props.farm.photos && props.farm.photos.length > 0 ? (
          props.farm.photos.map((img, index) => (
            <div key={index} className="image-card">
              <img src={img.image} alt="farm" />
              {editMode && <button className="remove-button" onClick={() => handleImageRemove(img.id)}>X</button>}
            </div>
          ))
        ) : (
            <p>There are currently no photos for this farm.</p>
          )}
      </div>
    </div >
  );
}