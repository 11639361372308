import { Grid, TextField, Typography } from "@mui/material";

function ZimmermanForm(props) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body2" color="error">This form is optional, but in order to be taken into account during calculation, all fields must be filled out.</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ doc: evt.target.value })} value={props.model.doc} type="number" variant="outlined" size="small" label="Dissolved organic carbon in t/ha"></TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ sa: evt.target.value })} value={props.model.sa} type="number" variant="outlined" size="small" label="OM in sand plus aggregates in t/ha"></TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ pom: evt.target.value })} value={props.model.pom} type="number" variant="outlined" size="small" label="Particulate organic matter in t/ha"></TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ sc: evt.target.value })} value={props.model.sc} type="number" variant="outlined" size="small" label="OM in silt plus clay in t/ha"></TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ r_soc: evt.target.value })} value={props.model.r_soc} type="number" variant="outlined" size="small" label="Recalcitrant soil organic carbon in t/ha"></TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ soc_year: evt.target.value })} value={props.model.soc_year} type="number" variant="outlined" size="small" label="Year of SOC stock measurement"></TextField>
      </Grid>  
    </Grid>
  )
}

export default ZimmermanForm;