import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../services/$contextProvider';
import useMergeState from "../../../services/$mergeState";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
// import background_img from '../../../assets/not_found.png';
import background_img from '../../../assets/explore_default_icon.png';
import { Link } from '@mui/material';

function FarmInformationTab(props) {
  const { $t, onUpdateState } = useContext(AppContext);

  const [state, setState] = useMergeState({ loading: true, selected: null, error: {}, selectedImageIndex: 0 });

  const renderFarmInfo = () => {
    if (!props.selected) {
      return (
        <div className='farm-info-container'>
          <button className="go-back-button" onClick={() => props.setMapState({ selected: null })}>Go Back</button>
          <div>{$t.failed_data_error}</div>
        </div>
      );
    }

    const farm = props.selected.values_.properties;
    const keysToIgnore = ['point', 'region', 'poly', 'id', 'regenerative_practice2'];
    const importantKeys = ['farm_name', 'country', 'source', 'contact', 'regenerative_practice', 'photos'];
    const customKeys = Object.keys(farm).filter(key => !importantKeys.includes(key) && !keysToIgnore.includes(key));

    const location = [farm['country'], farm['location']].filter(Boolean).join(', ');

    // const farmImage = farm.photos?.[0]?.image || background_img;
    return (
      <div className='farm-info-container'>
        <button className="go-back-button" onClick={() => props.setMapState({ selected: null })}>Go Back</button>
        <img src={farm.photos?.[state.selectedImageIndex]?.image || background_img} alt="Farm" className="farm-image" />
        <div className='farm-name'>{farm.farm_name}</div>
        <div className='farm-location'>{location}</div>
        {renderRelatedLinks(farm)}
        {renderContact(farm.contact)}
        {renderListField($t.label_regenerative_practice, farm.regenerative_practice)}
        {renderListField($t.label_benefits, farm.benefits)}
        {renderListField($t.label_limitations, farm.barriers_limitations)}
        {customKeys.map(key => renderField(key, farm[key]))}
        {farm.photos && farm.photos.length > 0 && (
          <div className='photo-gallery'>
            {farm.photos.map((photo, i) => <img key={i}
              src={photo.image} alt={`Farm ${i}`}
              onClick={() => handleImageClick(i)}
              className={i === state.selectedImageIndex ? 'selected' : ''} />)}
          </div>
        )}
      </div>
    );
  }

  const renderContact = (contact) => {
    if (contact !== '' && contact !== undefined && contact !== null) {
      return <div className='related-links'>
        <h3>{$t.contact_label}</h3>
        <div className='farm-location'>{contact}</div>
      </div>
    }
  }

  const renderRelatedLinks = (farm) => {
    if (!farm.source && !farm.source2) return null;
    return (
      <div className='related-links'>
        <h3>{$t.related_links}</h3>
        {farm.source && <a href={farm.source} target='_blank'>{farm.source}</a>}
        {farm.source2 && <a href={farm.source2} target='_blank'>{farm.source2}</a>}
      </div>
    );
  }

  const parseListString = (str) => {
    try {
      if (str === null || str === undefined || str === '') return null;
      return str.split(';');
    } catch (e) {
      return ''
    }
  }

  const renderListField = (field, value) => {
    const items = parseListString(value);
    if (!items) return null;

    return (
      <div key={field} className='farm-info-item'>
        <h3 className='farm-info-item-key'>{field}</h3>
        <div className='farm-info-item-value'>
          {items.map((item, index) => item.trim() !== '' ? <span key={index} className='item-chip'>{item}</span> : null)}
        </div>
      </div>
    );
  }

  const renderField = (field, value) => {
    if (value === null || value === undefined || (field === 'Regenerative Practices' && value.length === 0)) return null;
    if (Array.isArray(value)) {
      return (
        <div key={field} className='farm-info-item'>
          <h3 className='farm-info-item-key'>{field}</h3>
          <ul className='farm-info-item-value'>
            {value.map((item, index) => <li key={index}>{item.name}</li>)}
          </ul>
        </div>
      );
    } else if (typeof value === 'string' || typeof value === 'number') {
      return (
        <div key={field} className='farm-info-item'>
          <h3 className='farm-info-item-key'>{field}</h3>
          <p className='farm-info-item-value'>{value}</p>
        </div>
      );
    }
    return null;
  }

  const onCardClick = (feature) => {
    setState({ selectedImageIndex: 0 });
    props.setMapState({ selected: feature })
    if (feature.getGeometry().getExtent() !== undefined) props.setMapState({ zoomToFarm: feature.getGeometry().getExtent() })
  }

  const farmCard = (feature) => {
    return <div key={feature.values_.properties.id} className='farm-card' onClick={() => onCardClick(feature)}>
      <section className='card-img-container'><div className="card-img" style={{ backgroundSize: feature.values_.properties.photos?.[0]?.image ? 'cover' : '', backgroundImage: feature.values_.properties.photos?.[0]?.image ? `url("${feature.values_.properties.photos?.[0]?.image}")` : `url("${background_img}")` }}></div></section>
      <div className="card-header">{feature.values_.properties.farm_name ? feature.values_.properties.farm_name : '-'}</div>
      <div className="card-text">{feature.values_.properties.country ? feature.values_.properties.country : '-'}</div>
    </div>
  }

  const renderFarmCards = () => {
    try {
      if (props.selected !== undefined && props.selected !== null) {
        return renderFarmInfo()
      } else {
        return <div className='farm-card-container'>
          {props.list.map(feature => {
            return farmCard(feature)
          })}
        </div>
      }

    } catch (e) {
      console.log(e)
      return <div className='tab-no-data-container'>
        <span className='travel-icon'><TravelExploreIcon fontSize='large' /></span>
        <span>{$t.location_not_selected}</span>
      </div>
    }
  }

  const renderNoData = () => {
    return <div className='tab-no-data-container' style={{ width: props.isMobile ? window.innerWidth : '100%' }}>
      <span className='travel-icon'><TravelExploreIcon fontSize='large' /></span>
      <span>{$t.location_not_selected}</span>
    </div>
  }

  //handle images for the farm
  const handleImageClick = (index) => {
    // Set the selected image index when an image is clicked
    setState({ selectedImageIndex: index });
  };

  return (
    <div className='farm-information-tab'>
      {props.list.length === 0 || props.list === undefined || props.list === null ? renderNoData() : null}
      {props.list.length > 0 ? renderFarmCards() : null}
    </div>
  )
}

export default FarmInformationTab;