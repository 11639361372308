import React from 'react';

export default function FileImport(props) {
  return (
    <div className='import-field-container'>
      <input id="import-input" type="file" className={`import-field-input ${props.className ? props.className : ''}`} accept='application/vnd.ms-excel,.xlsx' onChange={props.onChange ? props.onChange : null} />
      <label htmlFor={'import-input'} className='import-label'>{props.icon ? props.icon : null}{props.label ? props.label : 'Choose a file...'}</label>
      &nbsp;
      <span>
        <strong>{props.selectedLabel ? props.selectedLabel : 'Chosen file:'}</strong>
        <span>{props.chosen ? props.chosen : 'none'}</span>
      </span>
    </div>
  );
}