import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../services/$contextProvider';
import useMergeState from "../../../services/$mergeState";
import RecordRemoveModal from "../ecoViews/RecordRemoveModal";
import $cookies from '../../../services/$cookies';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';

export default function FirstStep(props) {
  const { $t } = useContext(AppContext);
  const [form, setForm] = useMergeState({ crop: 'null', t_yield: 0, area: 0, active: [], loading: true, error: {} });
  const [modal, setModal] = useMergeState({ visible: false, record: null });


  useEffect(() => {
    if (form.loading) {
      try {
        if (props.types !== undefined && props.types.length !== 0) {
          setForm({ crop: props.types[0].name, loading: false })
        }
      } catch (e) {
        console.log(e)
      }
    }
  }, [props.types])

  const onHandleInput = (name, value) => {
    setForm({ [name]: value })
  }

  const onClose = () => {
    setModal({ visible: false, record: null })
  }

  const preventMinus = (e) => {
    if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
      e.preventDefault();
    }
  };

  const cropOptions = () => {
    let { types, records } = props;
    if (records.length !== 0) {
      records.map(record => {
        types = types.filter(type => record.crop !== type.crop)
      })
      return types.map((item) => {
        return <option key={item.id} value={item.name}>{item.name}</option>
      })
    } else {
      if (types !== undefined && types.length !== 0) {
        return types.map((item) => {
          return <option key={item.id} value={item.name}>{item.name}</option>
        })
      } else return <option key={0} value={null}>n/a</option>
    }
  }

  const fuelOptions = () => {
    let { fuel_prices } = props;
    const countries = fuel_prices !== null ? Object.keys(fuel_prices) : []
    if (countries.length !== 0) {
      return countries.map((country, index) => {
        return <optgroup key={index} label={country}>
          {fuel_prices[country][0] !== '–' ? <option value={fuel_prices[country][0]}>gasoline: {fuel_prices[country][0]}</option> : null}
          {fuel_prices[country][1] !== '–' ? <option value={fuel_prices[country][1]}>diesel: {fuel_prices[country][1]}</option> : null}
          {fuel_prices[country][2] !== '–' ? <option value={fuel_prices[country][2]}>lpg: {fuel_prices[country][2]}</option> : null}
        </optgroup >
      })
    } else {
      return <option aria-label="None" value={'–'} />

    }
  }

  const renderTable = () => {
    let { records } = props;
    try {
      if (records !== undefined && records.length !== 0) {
        return (
          <div className='accordion-container'>
            <ul>
              {records.map((record, index) => {
                return <li
                  className={checkActiveList(record.crop) ? 'active' : ''}
                  key={index}
                  onClick={() => recordClick(record)}>
                  <i></i>
                  <h2>{record.crop}</h2>
                  <div className='details-wrap'>
                    <section className='accordion-details'><p>{`${$t.yield_title}: ${record.t_yield} t/ha`}</p>
                      <p>{`${$t.total_area_title}: ${record.area} ha`}</p></section>
                    <section className='details-option'><DeleteForeverIcon onClick={() => { setModal({ visible: true, record: record.crop }) }} /></section>
                  </div>
                </li>
              })}
            </ul>
          </div>
        )
      }
    } catch (e) {
      return null
    }
  }

  const validateRecord = (obj) => {
    let isValid = true;
    let error = {};

    Object.keys(obj).map(key => {
      if (obj[key] === null || obj[key] === '') {
        error[key] = true;
        isValid = false;
      }

    })

    if (props.records.some(record => record.crop === obj.crop)) {
      isValid = false
    }

    if (!isValid) {
      setForm({
        error: {
          msg: $t.crop_duplicate_error
        }
      });
    }

    return isValid
  }

  const addRecord = () => {
    const { crop, t_yield, area } = form;
    let model = { crop, t_yield, area };
    if (validateRecord(model)) {
      try {
        setForm({ loading: true })
        props.onUpdateExplorationState({ records: props.records.concat(model) })
        $cookies.remove('records');
        $cookies.set('records', JSON.stringify(props.records.concat(model)));
        reloadForm()
      } catch (e) {
        console.log(e)
      }
      return
    }
  }

  const reloadForm = () => {
    try {
      if (props.types !== undefined && props.types.length !== 0) {
        setForm({ crop: props.types[0].name, t_yield: 0, area: 0, loading: false })
      }
    } catch (e) {
      setForm({ crop: props.types[0].name, t_yield: 0, area: 0, loading: false })
      console.log(e)
    }
  }

  const recordClick = (record) => {
    if (!checkActiveList(record.crop)) {
      setForm({
        active: form.active.concat(record)
      })
    } else {
      const newList = form.active.filter((item) => item.crop !== record.crop);
      setForm({
        active: newList
      })
    }
  }

  const checkActiveList = (name) => {
    let has = false
    const selected = [...form.active];
    if (selected.some(item => item.crop === name)) {
      has = true
    }
    return has
  }

  const renderForm = () => {
    return (props.types.length !== 0 && props.types !== undefined && !form.loading ? <React.Fragment>
      <div className='form-wrap'>
        <FormControl className='ifield'>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            {$t.crop_field_name}
          </InputLabel>
          <NativeSelect
            value={form.crop}
            onChange={(e) => onHandleInput('crop', e.target.value)}
            placeholder={$t.crop_field_placeholder}
            inputProps={{
              name: 'crop',
              id: 'uncontrolled-native',
            }}
          >
            {cropOptions()}
          </NativeSelect>
          {form.error.msg ? <FormHelperText>{form.error.msg}</FormHelperText> : null}
        </FormControl>
        <TextField
          className='ifield'
          type="number"
          onKeyPress={preventMinus}
          min="0"
          value={form.t_yield}
          onChange={(e) => onHandleInput('t_yield', e.target.value)}
          label={$t.yield_field_name}
          variant="standard" />
        <TextField
          className='ifield'
          type="number"
          onKeyPress={preventMinus}
          value={form.area}
          min="0"
          onChange={(e) => onHandleInput('area', e.target.value)}
          label={$t.total_area_input_name}
          variant="standard" />
        <FormControl className='ifield'>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            {$t.fuel_title}
          </InputLabel>
          <NativeSelect
            defaultValue={props.fuel_price}
            onChange={(e) => props.onUpdateState({ fuel_price: e.target.value })}
            placeholder={$t.crop_field_placeholder}
            inputProps={{
              name: 'fuel_price',
              id: 'uncontrolled-native',
            }}
          >
            {fuelOptions()}
          </NativeSelect>
        </FormControl>
      </div>
      <div className='add'><Button variant='contained' disabled={form.crop === 'null'} onClick={addRecord} endIcon={<AddIcon />}><span className='btn-label'>{$t.add}</span></Button></div>
    </React.Fragment > : <div className='loader-container'><CircularProgress size={20} /></div>)
  }


  return (
    <div className='first-container'>
      {renderForm()}
      {renderTable()}
      <p className='disclaimer-text'>{$t.economical_disclaimer}</p>
      {modal.visible ? <RecordRemoveModal record={modal.record} operations={props.operations} records={props.records} onClose={onClose} onRemoveUpdate={props.onUpdateExplorationState} /> : null}
    </div>
  );
}
