import { LocationOn, Search } from "@mui/icons-material";
import { Autocomplete, CircularProgress, InputAdornment, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";
import { fromLonLat } from "ol/proj";
import { useContext, useEffect } from "react";
import $data from "../../services/$data";
import { AppContext } from '../../services/$contextProvider';
import useMergeState from "../../services/$mergeState";

function SearchLocation(props) {
  const { $t } = useContext(AppContext);

  const [state, setState] = useMergeState({
    options: [],
    query: '',
    active: '',
    loading: false
  });

  const format = (str) => {
    let latLon = str.split(', ').map(v => parseFloat(v));

    return {
      key: -11,
      title: `${$t.go_to} ${state.query}`,
      coordinates: fromLonLat(latLon.reverse())
    }
  }

  const getLocations = _.debounce(() => {
    if (!state.query) return;
    let isLatLon = state.query.match(/^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/);
    if (isLatLon) {
      setState({ loading: false, options: [format(state.query)] })
    } else {
      $data.getLocations(state.query)
        .then(data => {
          setState({ loading: false, options: data })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, 500)

  useEffect(() => {
    setState({ options: [] });
    if (state.query !== '') {
      setState({ loading: true })
      getLocations();
    } else {
      setState({ loading: false, options: [] });
    }

    return getLocations.cancel
  }, [state.query]);


  return (
    <>
      <Autocomplete
        className={props.className ? props.className : ''}
        sx={{ borderRadius: '6px', zIndex: 1, position: 'absolute', left: '7px', top: '7px', background: '#fff', maxWidth: '400px', width: '100%' }}
        id="search-location"
        options={state.options}
        getOptionLabel={(option) => option.title}
        onChange={(evt, value, reason) => {
          if (reason === 'selectOption') {
            props.updateMap(value.extent)
          }
        }}
        onInputChange={(evt, value, reason) => {
          if (reason === 'input') {
            setState({ query: value })
          }
        }}
        filterOptions={(options) => { return options }}
        renderOption={(params, option) => {
          return (<Box key={option.key} {...params} component="li" size="small">
            <Stack direction="row" alignItems="center" sx={{ color: '#555' }}><LocationOn color="primary" />&nbsp;{option.title}</Stack>
          </Box>)
        }}
        renderInput={(params) => <TextField {...params} className="search-location-input" placeholder={$t.search_placeholder} size="small" InputProps={{
          ...params.InputProps,
          endAdornment: <InputAdornment position="end">{state.loading ?
            <CircularProgress size={20} /> : <Search size="small" />}
            {props.icon ? props.icon : null}
            {props.icon2 && props.isFiltered ? props.icon2 : null}
          </InputAdornment>
        }} />}
      />
    </>
  )
}

export default SearchLocation;