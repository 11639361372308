import React from 'react';
import DropdownInput from '../../ui/DropdownInputEdit';

export default function FarmField({ fieldValue, fieldName, isEditable, handleRemoveItem, handleAddItem, newItems, setNewItems }) {
  const items = fieldValue ? fieldValue.split(';') : [];
  if (isEditable) {
    return (
      <div className='dd-input-div'>
        {items.map((item) => item && (
          <div key={item} className='farmfield-container'>
            <p>{item}</p>
            <button className='remove-item-btn' onClick={() => handleRemoveItem(item, fieldName)}>Remove</button>
          </div>
        ))}
        {fieldName === 'regenerative_practice' ? <DropdownInput
          error={null}
          value={newItems[fieldName]}
          className='dd-input'
          type={'text'}
          newItems={newItems}
          onHandleInput={setNewItems}
          placeholder={''} /> : <input
          value={newItems[fieldName]}
          onChange={e => setNewItems({ ...newItems, [fieldName]: e.target.value })}
        />}
        <button className='add-li-btn' onClick={() => handleAddItem(fieldName)}>Add</button>
      </div>
    );
  } else {
    return (
      <div>
        {(fieldValue || '').split(';').map((item, index) => item && <p key={index}>{item}</p>)}
      </div>
    );
  }
}