import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../services/$contextProvider';
import useMergeState from "../../services/$mergeState";
import $data from '../../services/$data';
import $cookies from '../../services/$cookies';
import { Navigate } from 'react-router-dom';
import photoimg from '../../assets/add-image.png';
import addLoc from '../../assets/add-loc.png';
import regPrac from '../../assets/regenerative_practice.svg';
import benLimit from '../../assets/ben-limit.svg';
import SurveyStepOne from './surveysteps/SurveyStepOne';
import SurveyStepTwo from './surveysteps/SurveyStepTwo';
import SurveyStepThree from './surveysteps/SurveyStepThree';
import BenefitsLimitations from './surveysteps/BenefitsLimitationsForm';


function FarmSurvey(props) {
  const { $t, onUpdateState } = useContext(AppContext);
  const [state, setState] = useMergeState({ loading: true, active: 1, error: {} });
  const [message, setMessage] = useState(null);
  const [model, setModel] = useMergeState({ image: [] })
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [query, setQuery] = useState('')
  const [data, setData] = useMergeState({ owner: null, farm_name: '', country: '', location: '', crop_group: '', coordinates: null, poly: null, contact: '', source: '', regenerative_practice: '', benefits: '', barriers_limitations: '' })

  useEffect(() => {
    if (props.loggedIn) {
      if (props.active !== 'farm-survey') {
        onUpdateState({ active: 'farm-survey' })
      }
    }
  }, [props.loggedIn]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message]);

  const updateModel = (state) => {
    setModel(state)
  }

  const updateDropdownOptions = (newOptions) => {
    setDropdownOptions(newOptions);
  }

  const addFarmsFromForms = () => {
    try {
      if (checkFarmForms('farm_name_error', data.farm_name) && checkFarmForms('crop_group_error', data.crop_group) && checkRegenerativePractice(data.regenerative_practice)) {
        let farm_data = data
        farm_data.location = data.location.display_name
        farm_data.owner = props.user.pk
        farm_data.regenerative_practice2 = farm_data.regenerative_practice;
        farm_data.precise = farm_data.poly === null ? true : false;

        let blobList = [];
        if (model.image.length > 0) {
          for (let i = 0; i < model.image.length; i++) {
            let image = model.image[i].src;

            blobList.push(image);
          }
        }

        const farm = [{
          data: farm_data,
          image: blobList
        }]
        $data.postFormFarm(farm)
          .then(data => {
            setData({ farm_name: '', country: '', location: '', coordinates: null, contact: '', source: '', regenerative_practice: '', benefits: '', barriers_limitations: '' })
            setModel({ image: [] })
            setMessage({ type: 'SUCCESS', text: $t.success_message_farm });
          })
          .catch(err => {
            console.log(err)
            setMessage({ type: 'ERROR', text: $t.error_message_farm });
            const er = {
              msg: 'request failed'
            }
            setState({
              error: er
            })
          })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const checkFarmForms = (name, value) => {
    if (name === 'contact_error' && value !== '') {
      if (!/\S+@\S+\.\S+/.test(value)) {
        setMessage({ type: 'ERROR', text: $t.email2_error });
        setState({
          error: {
            [name]: $t.email2_error
          }
        })
        return false
      }
    }
    if (name === 'farm_name_error' && (data.farm_name === undefined || data.farm_name === null || data.farm_name.trim() === '')) {
      setState({
        error: {
          [name]: $t[name]
        }
      })
      return false
    }
    if (name === 'crop_group_error' && (data.crop_group === undefined || data.crop_group === null || data.crop_group.trim() === '')) {
      setState({
        error: {
          [name]: $t[name]
        }
      })
      return false
    }
    return true
  }

  const checkRegenerativePractice = (practices) => {
    if (practices.trim() === '') {
      setMessage({ type: 'ERROR', text: $t.email2_error });
      return false
    }
    return true
  }

  const Message = ({ type, text }) => {
    const messageStyles = {
      SUCCESS: 'success-message',
      ERROR: 'error-message',
    };

    return (
      <div className={messageStyles[type]}>
        {text}
      </div>
    );
  };

  const renderFarmTabs = () => {
    return <React.Fragment>
      <button className={`farm-information-tab ${state.active === 1 ? 'active' : ''}`} onClick={() => setState({ active: 1 })}>
        <div className={'form-btn-container'} >
          <div className={`px-correct tab-image ${state.active === 1 ? 'closed' : ''}`}>
            <img src={addLoc} alt="Farm location icon" />
          </div>
          <div className={`btn-content ${state.active === 1 ? 'opened' : ''}`}>
            {$t.farm_information}
          </div>
        </div>
      </button>
      <button className={`farm-information-tab ${state.active === 2 ? 'active' : ''}`} onClick={() => setState({ active: 2 })}>
        <div className={state.active === 2 ? 'form-btn-container' : 'box-container form-btn-container'}>
          <div className={state.active === 2 ? 'tab-image closed' : 'tab-image'}><img src={regPrac} alt="RegePractice" /></div>
          <div className={state.active === 2 ? 'btn-content opened' : 'btn-content'}>{$t.regenerative_practices}</div>
        </div>
      </button>
      <button className={`farm-information-tab ${state.active === 3 ? 'active' : ''}`} onClick={() => setState({ active: 3 })}>
        <div className={state.active === 3 ? 'form-btn-container' : 'box-container form-btn-container'}>
          <div className={state.active === 3 ? 'tab-image closed' : 'tab-image'}><img src={benLimit} alt="RegePractice" /></div>
          <div className={state.active === 3 ? 'btn-content opened' : 'btn-content'}>{$t.benefits_barriers}</div>
        </div>
      </button>
      <button className={`farm-information-tab ${state.active === 4 ? 'active' : ''}`} onClick={() => setState({ active: 4 })}>
        <div className={state.active === 4 ? 'form-btn-container' : 'box-container form-btn-container'}>
          <div className={state.active === 4 ? 'tab-image closed' : 'tab-image'}><img src={photoimg} alt="UpImage" /></div>
          <div className={state.active === 4 ? 'btn-content opened' : 'btn-content'}> {$t.image_upload}</div>
        </div>
      </button>
    </React.Fragment>
  }

  if (!props.loggedIn) {
    if ($cookies.get('user') === null || $cookies.get('user') === undefined) {
      return <Navigate to='/login' />
    }
  }

  return (
    <article className='farm-add-container'>
      <div className='background-transparent-container'>
        {message && <div className='message-container'><Message type={message.type} text={message.text} /></div>}
        <section className={`farm-tabs-container`}>
          {renderFarmTabs()}
        </section>
        <section className='farm-form-container'>
          {state.active === 1 ? <SurveyStepOne error={state.error} updateFarm={setData} model={data} locQuery={query} dropdownOptions={dropdownOptions} updateQuery={setQuery} updateDropdownOptions={updateDropdownOptions} /> : null}
          {state.active === 2 ? <SurveyStepTwo updatePractices={setData} practices={data.regenerative_practice} /> : null}
          {state.active === 3 ? <BenefitsLimitations updatePractices={setData} /> : null}
          {state.active === 4 ? <SurveyStepThree updateImages={updateModel} images={model.image} /> : null}
        </section>
        <button onClick={() => { addFarmsFromForms() }} disabled={(data.farm_name === '' || data.location === '' || data.regenerative_practice === '') ? true : false} className="big-button">{$t.submit}</button>
      </div>
    </article>
  )
}

export default FarmSurvey;