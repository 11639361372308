import { Grid, TextField, Typography } from "@mui/material";

function ManualPools(props) {
  return (
    <Grid container spacing={1}>
       <Grid item xs={12}>
        <Typography variant="body2" color="error">This form is optional, but in order to be taken into account during calculation, all fields must be filled out.</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ dpm: evt.target.value })} value={props.model.dpm} type="number" variant="outlined" size="small" label="Decomposable plant material pool"></TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ dpm: evt.target.value })} value={props.model.rpm} type="number" variant="outlined" size="small" label="Resistant plant material pool"></TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ dpm: evt.target.value })} value={props.model.bio} type="number" variant="outlined" size="small" label="Microbial biomass pool"></TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ dpm: evt.target.value })} value={props.model.hum} type="number" variant="outlined" size="small" label="Humified organic matter pool"></TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ dpm: evt.target.value })} value={props.model.iom} type="number" variant="outlined" size="small" label="Inert organic matter pool"></TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ soc_year: evt.target.value })} value={props.model.soc_year} type="number" variant="outlined" size="small" label="Year of SOC stock measurement"></TextField>
      </Grid>  
    </Grid>
  )
}

export default ManualPools;