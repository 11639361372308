import React, { useContext, useState } from 'react';
import { AppContext } from '../../services/$contextProvider';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import useMergeState from '../../services/$mergeState';
import $auth from '../../services/$auth';
import InputField from '../ui/InputField';

const LoginModal = ({ isVisible, onHide, onUpdateState }) => {
    const { $t } = useContext(AppContext);
    const [state, setState] = useMergeState({ email: '', password: '', loading: false, error: {} });
    const onHandleChange = (name, value) => {
        setState({ [name]: value });
    }

    const onSubmit = (evt) => {
        handleLogin(evt);
        window.location.reload();
    };

    const handleContinueAsGuest = () => {
        $auth.invalidate();
        onHide()
        window.location.reload();
    };

    const validateField = (name, value) => {
        if (value === undefined || value === null || value === '') {
            setState({
                error: {
                    [name]: $t[name]
                }
            })
            return false
        }
        if (name === 'email_error') {
            if (!/\S+@\S+\.\S+/.test(value)) {
                setState({
                    error: {
                        [name]: $t.email2_error
                    }
                })
                return false
            }
        }
        return true
    }

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    const handleLogin = (evt) => {
        setState({ loading: true, error: {} });
        if (validateField('email_error', state.email) && validateField('password1_error', state.password)) {
            $auth.login(state.email, state.password)
                .then(data => {
                    setState({ loading: false });
                    onUpdateState({
                        loggedIn: true,
                        user: data.user
                    })
                    onHide();
                })
                .catch(err => {
                    console.log(err)
                    setState({ error: err.message, loading: false });
                })
        } else {
            setState({ loading: false })
        }
    }

    return (
        <div className={`login-modal-overlay ${isVisible ? 'visible' : 'hidden'}`}>
            <Snackbar open={state.error.non_field_errors ? true : false} autoHideDuration={6000} onClose={() => { setState({ error: {} }) }}>
                <MuiAlert elevation={6} variant="filled" severity="error">{state.error.non_field_errors}</MuiAlert>
            </Snackbar>
            <div className="login-modal">
                <h2 className='lm-title'>{$t.l_modal_title}</h2>
                <p className='lm-text'>{$t.l_modal_text}</p>
                <form className='lm-form'>
                    <InputField
                        className='lm-input'
                        maxLength={32}
                        error={state.error.email_error}
                        type={'text'}
                        value={state.email}
                        onKeyPress={(evt) => handleKeypress(evt)}
                        name={'email'}
                        placeholder={$t.placeholder_email}
                        onChange={(evt) => onHandleChange('email', evt.target.value)} />
                    <InputField
                        className='lm-input'
                        error={state.error.password1_error}
                        value={state.password}
                        type={'password'}
                        onKeyPress={(evt) => handleKeypress(evt)}
                        name={'password'}
                        placeholder={$t.placeholder_password}
                        onChange={(evt) => onHandleChange('password', evt.target.value)} />
                    <button className='lm-btn' onClick={(evt) => { evt.preventDefault(); handleLogin() }}>{$t.login}</button>
                    <span className='split'>{$t.or}</span>
                    <button className='lm-btn' type="button" onClick={handleContinueAsGuest}>
                        {$t.l_modal_continue_label}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginModal;