// const hos2 = 'https://backend.exploration.gilab.rs//'
const host = 'https://app.croplab.info/';
const host2 = 'https://backend.exploration.gilab.rs/'
const api = 'backend/api/';

const url = {
	//AUTHENTICATION
	LOGIN: host2 + 'auth/login/',
	REGISTRATION: host2 + 'auth/registration/',
	CHANGE_PASSWORD: host2 + 'auth/change_password/',
	LOGOUT: host2 + 'auth/logout/',
	SA: host2 + 'auth/role/',
	DELETE_ACCOUNT: host2 + 'auth/user/delete/',
	//ECONOMICAL BENEFITS
	EC_CROP: host + api + 'ac/exploration/ec/crop/',
	FUEL_PRICE: host + api + 'ac/exploration/ec/fuel_price/',
	CROP_MONITORING: host + api + 'ac/exploration/ec/crop_monitoring/',
	CROP_COVER_TYPE: host + api + 'ac/exploration/ec/cover_crop_type/',
	TILLAGE_TYPE: host + api + 'ac/exploration/ec/tillage_type/',
	MULCHING_TYPE: host + api + 'ac/exploration/ec/mulching_type/',
	CROP_COVER: host + api + 'ac/exploration/ec/cover_crop/',
	TILLAGE: host + api + 'ac/exploration/ec/tillage/',
	MULCHING: host + api + 'ac/exploration/ec/mulching/',
	AREA: host + api + 'ac/exploration/area/',
	EC_RESULTS: host + api + 'ac/exploration/ec/results/',
	ACTIVITIES: host + api + 'activities/',
	//SOC SEQUESTRATION
	EXP_POINT_QUERY: 'https://api-exploration.gilab.rs/point-query/',
	NOMINATIM: 'https://nominatim.openstreetmap.org/search?format=json&limit=4&q=',
	NOMINATIM_POLYGON: 'https://nominatim.openstreetmap.org/search.php?polygon_geojson=1&format=jsonv2&limit=3',
	NOMINATIM_ADDRESS_DETAILS: 'https://nominatim.openstreetmap.org/search.php?polygon_geojson=1&format=jsonv2&addressdetails=1&limit=4',
	GEOCODE_POLYGON: 'https://geocode.maps.co/search?q=',
	//BEST PRACTICES
	FARM: host2 + 'farm/',
	ROTHC: host2 + 'rothc/',
	CCATEGORY: host2 + 'farm/crop-group'
}

export default url;