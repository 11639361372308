import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../services/$contextProvider';
import useMergeState from "../../../services/$mergeState";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import TextField from '@mui/material/TextField';

export default function TillageForm(props) {
  const { $t } = useContext(AppContext);
  const [form, setForm] = useMergeState({ model: {}, error: {} });


  useEffect(() => {
  }, [])

  const onChange = (name, value) => {
    const op_model = {
      ...form.model,
      [name]: value
    }
    setForm({
      model: op_model
    })
    props.updateOp({
      error: {},
      op_model: {
        tillage: op_model
      }
    })
  }

  const setDefault = (item) => {
    setForm({
      model: {
        tillage_type: item.id,
        labour_cost: 0
      }
    })
  }

  const preventMinus = (e) => {
    if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
      e.preventDefault();
    }
  };

  const tillageOptions = () => {
    let tillage_operation = props.operations.tillage.filter(tillage => props.record.crop === tillage.crop_cover)
    let operation = props.types.tillage;
    if (tillage_operation.length !== 0) {
      tillage_operation.map(item => {
        operation = operation.filter(op => item.tillage_type !== op.id)
      })
      if ((form.model.tillage_type === undefined || form.model.labour_cost === undefined) && operation.length !== 0) {
        setDefault(operation[0])
      }
      return operation.map((item) => {
        return <option key={item.id} value={item.id}>{item.options}</option>
      })
    } else {
      if ((form.model.tillage_type === undefined || form.model.labour_cost === undefined) && operation.length !== 0) {
        setDefault(operation[0])
      }
      return operation.map((item) => {
        return <option key={item.id} value={item.id}>{item.options}</option>
      })
    }
  }

  return (
    <React.Fragment>
      <FormControl className='ifield'>
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          {$t.tillage_operations_tillage}
        </InputLabel>
        <NativeSelect
          defaultValue={0}
          onChange={(e) => onChange('tillage_type', e.target.value)}
          placeholder={$t.tillage_type_placeholder}
          inputProps={{
            name: 'till_type',
            id: 'uncontrolled-native',
          }}
        >
          {tillageOptions()}
        </NativeSelect>
      </FormControl>
      <TextField
        className='ifield'
        type="number"
        onKeyPress={preventMinus}
        min={0}
        value={form.model.labour_cost || ''}
        onChange={(e) => onChange('labour_cost', e.target.value)}
        label={$t.cover_crop_labor_form_label}
        variant="standard" />
    </React.Fragment>
  );
}