import React from 'react';

export default function myLoader(props) {
  return (
    <div className="loader-wrapper">
      <div className="loader"></div>
      <div className="progress-bar">
        {props.text ? props.text : ''}
      </div>
    </div>
  );
}