import React, { useContext, useEffect, useState } from 'react';
import defaultFarmPic from '../../../assets/not_found.png';
import $data from '../../../services/$data';
import FarmPreview from './FarmPreview';
import { AppContext } from '../../../services/$contextProvider';

export default function FarmsDashboard() {
  const { $t, onUpdateState } = useContext(AppContext);
  const [farms, setFarms] = useState(null);
  const [search, setSearch] = useState('');
  const [selectedFarm, setSelectedFarm] = useState(null);

  useEffect(() => {
    getFarms()
  }, []);


  const getFarms = () => {
    $data.getFarmsDashboard()
      .then(data => {
        setFarms(data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const updateFarmImage = (farm) => {
    setSelectedFarm(null)
    setSelectedFarm(farm)
  }

  let filteredFarms = farms;
  if (search) {
    filteredFarms = farms.filter(farm => farm.farm_name.toLowerCase().includes(search.toLowerCase()));
  }

  return (
    <div className="FarmsDashboard">
      {selectedFarm ? (
        <FarmPreview farm={selectedFarm} updateEditedFarm={setSelectedFarm} updateFarmImage={updateFarmImage} loadFarms={getFarms} onGoBack={() => setSelectedFarm(null)} />
      ) : (
        <>
          <input
            type="text"
            placeholder={$t.filter_searchbar_placeholder}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />

          <div className="farm-list">
            {farms ? (farms.length !== 0 ? filteredFarms.map(farm => (
              <div key={farm.id} className="farm-card" onClick={() => setSelectedFarm(farm)}>
                <img className="farm-picture" src={farm.photos.length > 0 ? farm.photos[0].image : defaultFarmPic} alt="Farm" />
                <h2>{farm.farm_name}</h2>
                <p>{farm.country}</p>
              </div>
            )) : <div className='text-holder'>{$t.no_farm_msg}</div>
            ) : (
              [...Array(10)].map((e, i) => (
                <div key={i} className="farm-card placeholder">
                  <div className="farm-picture"></div>
                  <h2>&nbsp;</h2>
                  <p>&nbsp;</p>
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
}