import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Chart from 'chart.js/auto';
import { Line } from "react-chartjs-2";

function DataViewer(props) {

  const generateDatasets = (datasets) => {
    return Object.keys(datasets).map(name => {
      if(name === 'month') return null;
      return {
        label: name,
        type: 'line',
        data: datasets[name],
        pointHoverRadius: 10,
        borderWidth: name !== 'Total SOC' ? 2 : 4,
        pointRadius: 0
      }

    }).filter(p => p !== null);
  }

  const renderContent = () => {
    try {
      return (
        <Grid item xs={12}>
          <Line data={{labels: props.data.month, datasets: generateDatasets(props.data)}} options={{interaction: {intersect: false, mode: 'index'}, animation: false, plugins: {tooltip: {
            callbacks: {
              label: (context) => {
                return ` ${context.dataset.label}: ${(context.parsed.y).toFixed(2)} t/ha`
              }
            }
          }}}} />
        </Grid>
      )
    } catch (e) {
      return <Grid item xs={12}><Typography variant="body1" color="error">Something went wrong with tha data viewer. Please contact our technical support for more information.</Typography></Grid>
    }
  }


  return (
    <Dialog fullWidth maxWidth="lg" PaperProps={{ className: 'co-background-white' }} open={props.open} onClose={props.onClose}>
      <DialogTitle className="text-primary" sx={{ position: 'sticky', top: 0, zIndex: 10 }} color="primary">Results</DialogTitle>
      <DialogContent>
        <Container sx={{ background: '#fff', mt: '20px', pt: '10px', pb: '10px'}}>
          <Grid container spacing={2}>
            {renderContent()}
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions sx={{ position: 'sticky', bottom: 0 }} className="co-background">
        <Button style={{ lineHeight: 'unset' }} color="error" onClick={props.onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DataViewer;