import React, { useContext } from 'react';
import { AppContext } from '../../../services/$contextProvider';

import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import CropCoverForm from '../forms/CropCoverForm';
import useMergeState from '../../../services/$mergeState';
import MulchingForm from '../forms/MulchingForm';
import TillageForm from '../forms/TillageForm';

export default function RegAgriFormModal(props) {
  const { $t } = useContext(AppContext);
  const [form, setForm] = useMergeState({ operation: 'cover', op_model: null, loading: true, error: {} });

  const onHandleInput = (name, value) => {
    setForm({ [name]: value, op_model: null })
  }

  const onHandleOperationInput = (state) => {
    setForm(state)
  }

  const cancelAdd = () => {
    props.updateSecondState({ addOperationModal: false, crop_record: null })
  }

  const addOperation = () => {
    if (form.op_model[form.operation] !== undefined) {
      if (form.operation == 'cover') {
        addCoverCrop(form.op_model[form.operation])
      }
      if (form.operation == 'tillage') {
        addTillage(form.op_model[form.operation])
      }
      if (form.operation == 'mulching') {
        addMulching(form.op_model[form.operation])
      }
    } else {
      const err = {
        msg: $t.add_operation_error
      }
      props.updateSecondState({ error: err })
    }
  }

  const addCoverCrop = (cc) => {
    let model = {
      crop_cover: props.record.crop,
      cover_crop_type: cc.cover_crop_type,
      labour_cost: cc.labour_cost,
    }
    if (validateInput(model)) {
      props.onUpdateOperationState(form.operation, model, 1)
      return
    } else {
      const err = {
        msg: $t.add_operation_error
      }
      props.updateSecondState({ error: err })
    }
  }
  const addTillage = (till) => {
    let model = {
      crop_cover: props.record.crop,
      tillage_type: till.tillage_type,
      labour_cost: till.labour_cost,
    }
    if (validateInput(model)) {
      props.onUpdateOperationState(form.operation, model, 1)
      return
    } else {
      const err = {
        msg: $t.add_operation_error
      }
      props.updateSecondState({ error: err })
    }
  }

  const addMulching = (mulch) => {
    let model = {
      crop_cover: props.record.crop,
      mulching_type: mulch.mulching_type,
      pesticide_rate: mulch.pesticide_rate,
      fertilization_rate: mulch.fertilization_rate,
      irrigation_rate: mulch.irrigation_rate
    }
    if (validateInput(model)) {
      props.onUpdateOperationState(form.operation, model, 1)
      return
    } else {
      const err = {
        msg: $t.add_operation_error
      }
      props.updateSecondState({ error: err })
    }
  }

  const validateInput = (obj) => {
    let isValid = true;
    let error = {};

    Object.keys(obj).map(key => {
      if (obj[key] === null || obj[key] === '') {
        error[key] = true;
        isValid = false;
      }
    })

    if (!isValid) {
      setForm({ error });
    }

    return isValid
  }

  const renderForm = () => {
    switch (form.operation) {
      case 'cover': return <CropCoverForm operations={props.operations} types={props.types} updateOp={onHandleOperationInput} record={props.record} />;
      case 'tillage': return <TillageForm operations={props.operations} types={props.types} updateOp={onHandleOperationInput} record={props.record} />;
      case 'mulching': return <MulchingForm operations={props.operations} types={props.types} updateOp={onHandleOperationInput} record={props.record} />
      default: return <CropCoverForm operations={props.operations} types={props.types} updateOp={onHandleOperationInput} record={props.record} />;
    }
  }

  return (
    <div className='operation-modal-container' onClick={() => props.updateSecondState({ addOperationModal: false, crop_record: null })}>
      {props.record !== undefined && props.types !== undefined ? <div className='modal-form-wrapper' onClick={(e) => e.stopPropagation()}>
        <section className='modal-header'><h2>{$t.add_operation}</h2></section>
        <section className='modal-form'>
          <FormControl className='ifield'>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              {$t.operation_type}
            </InputLabel>
            <NativeSelect
              defaultValue={0}
              onChange={(e) => onHandleInput('operation', e.target.value)}
              placeholder={$t.operation_type_placeholder}
              inputProps={{
                name: 'op_type',
                id: 'uncontrolled-native',
              }}
            >
              <option value={'cover'}>{$t.type_cover}</option>
              <option value={'tillage'}>{$t.type_tillage}</option>
              <option value={'mulching'}>{$t.type_mulching}</option>
            </NativeSelect>
          </FormControl>
          {renderForm()}
        </section>
        <div className='modal-btn'>
          <Button variant='contained' className='negative-btn' onClick={cancelAdd}>{$t.cancel}</Button>
          <Button variant='contained' disabled={form.op_model == null} onClick={addOperation}>{$t.add}</Button>
        </div>
      </div> : <div className='modal-form-wrapper' onClick={(e) => e.stopPropagation()}>
          <CircularProgress size={20} />
        </div>}
    </div>
  );
}