import { Grid, TextField, Typography, InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import { AppContext } from '../../services/$contextProvider';
import { useContext } from "react";

function SocForm(props) {

  const { $t } = useContext(AppContext);

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
          <InputLabel size="small" id='soc-unit-label'>{$t.soc_unit_label}</InputLabel>
          <Select
            labelId="soc-unit-label"
            size="small"
            label={$t.soc_unit_label}
            onChange={(evt) => props.onUpdateModel({ soc_unit: evt.target.value })}
            value={props.model.soc_unit}
          >
            <MenuItem value={'Mg/ha'}>Mg/ha</MenuItem>
            <MenuItem value={'%'}>%</MenuItem>
          </Select>
        </FormControl>

      </Grid>
      <Grid item xs={12} sm={6} md={4}>

      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" color="error">{$t.optional_form_label}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ soc: evt.target.value })} value={props.model.soc} type="number" variant="outlined" size="small" label="Soil organic carbon stock in t/ha for specified year"></TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ soc_year: evt.target.value })} value={props.model.soc_year} type="number" variant="outlined" size="small" label="Year of SOC stock measurement"></TextField>
      </Grid>
      {props.model.soc_unit === '%' ? <Grid item xs={12} sm={6} md={4}>
        <TextField fullWidth onChange={(evt) => props.onUpdateModel({ bulk_dens: evt.target.value })} value={props.model.bulk_dens} type="number" variant="outlined" size="small" label={$t.bulk_dens_label}></TextField>
      </Grid> : null}
    </Grid>
  )
}

export default SocForm;