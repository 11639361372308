import React, { useState, useEffect, useRef } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../../services/$contextProvider';
import useMergeState from '../../../services/$mergeState';

const Crop_Groups = [
    { value: 'Field crop', label: 'Field crop' },
    { value: 'Vegetable crop', label: 'Vegetable crop' },
    { value: 'Fruit crop', label: 'Fruit crop' },
    { value: 'Viticulture', label: 'Viticulture' },
    { value: 'Nuts', label: 'Nuts' },
    { value: 'Animals', label: 'Animals' },
    { value: 'Other', label: 'Other' },
];

export default function DropdownField(props) {
    const { $t } = useContext(AppContext);
    const timeoutId = useRef(null);

    const [state, setState] = useMergeState({
        options: Crop_Groups,
    });

    const [inputFocus, setInputFocus] = useState(false);

    const getOptions = (input) => {
        const selectedValues = props.value ? props.value.split(';') : [];
        const availableOptions = Crop_Groups.filter((opt) => !selectedValues.includes(opt.label));

        if (input === '') {
            return availableOptions;
        } else {
            return availableOptions.filter((opt) => opt.label.toLowerCase().includes(input.toLowerCase()));
        }
    };

    useEffect(() => {
        return () => clearTimeout(timeoutId.current);
    }, []);

    const handleInputChange = (event) => {
        const input = event.target.value;
        props.onHandleInput({ crop_group: input });
        setState({ options: getOptions(input) });
    };

    const handleOptionClick = (option) => {
        const selectedCropGroupsSplit = (props.value ? `${props.value};${option.label}` : option.label).split(";");
        const remainingOptions = Crop_Groups.filter(opt => !selectedCropGroupsSplit.includes(opt.label));
        props.onHandleInput({ crop_group: props.value ? `${props.value};${option.label}` : option.label });
        console.log(remainingOptions)
        setState({ options: remainingOptions });
    };

    const handleTagRemove = (tag) => {
        const updatedValue = props.value
            .split(';')
            .filter((item) => item !== tag)
            .join(';');

        props.onHandleInput({ crop_group: updatedValue });
        const selectedCropGroupsSplit = (updatedValue).split(";");
        const remainingOptions = Crop_Groups.filter(opt => !selectedCropGroupsSplit.includes(opt.label));
        setState({ options: remainingOptions });
    };

    return (
        <React.Fragment>
            <div className='cg-dropdown-container'>
                <div className='tags-container'>
                    {props.value &&
                        props.value.split(';').map((tag, index) => (
                            <div key={index} className='tag'>
                                {tag}
                                <span className='remove-tag' onClick={() => handleTagRemove(tag)}>
                                    &times;
                                </span>
                            </div>
                        ))}
                </div>
                <input
                    autoComplete='off'
                    className={`dropdown-input-text ${props.error ? 'dd-error' : ''} ${props.className ? props.className : ''}`}
                    type={props.type ? props.type : 'text'}
                    name={props.name ? props.name : 'crop_group'}
                    placeholder={props.placeholder ? props.placeholder : ''}
                    onChange={handleInputChange}
                    onFocus={() => setInputFocus(true)}
                    onBlur={() => (timeoutId.current = setTimeout(() => setInputFocus(false), 200))}
                    value={props.value ? '' : ''}
                />
                {inputFocus && state.options.length > 0 ? (
                    <div className='options_container'>
                        {state.options.map((option) => (
                            <div key={option.value} className='option' onClick={() => handleOptionClick(option)}>
                                {option.label}
                            </div>
                        ))}
                    </div>
                ) : (inputFocus && state.options.length === 0) ? (
                    <div className='options_container'>
                        <div className='no_options'>{'no results'}</div>
                    </div>
                ) : null}
            </div>
            {/* {props.error && (
        <span className='error_text2'>{props.error}</span>
      )} */}
        </React.Fragment>
    );
}