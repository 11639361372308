import Bing from 'ol/source/BingMaps';
import { Tile as TileLayer } from 'ol/layer';
import { Vector as VectorSource, Cluster as ClusterSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import { Style, Text, Icon, Fill, Stroke, Circle } from 'ol/style';
import farm_img from '../../src/assets/farm_precise_pin.png';
import farm_und from '../../src/assets/farm_unprecise_pin2.png';
import { fromLonLat } from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';
import MarkerImg from '../../src/assets/marker.png';


class $map {
  createBasemaps(active) {
    return [
      new TileLayer({
        preload: Infinity,
        name: 'sat',
        zIndex: 1,
        source: new Bing({
          key: "Am5pr8Q-dkmubEMkSwAsIfSkH8UrJjTHt-rMwQUfjjaG5ioU5prI9XSVfT9hjA8G",
          imagerySet: "AerialWithLabels",
          maxZoom: 19
        }),
        visible: active === 'sat'
      }),
      new TileLayer({
        preload: Infinity,
        name: 'map',
        zIndex: 1,
        source: new Bing({
          key: "Am5pr8Q-dkmubEMkSwAsIfSkH8UrJjTHt-rMwQUfjjaG5ioU5prI9XSVfT9hjA8G",
          imagerySet: "Road",
          maxZoom: 19
        }),
        visible: active === 'map'
      })
    ]
  }

  setLayer(name, layers) {
    layers.map(layer => {
      if (layer.get('name') === name) {
        layer.setVisible(true)
      } else {
        layer.setVisible(false);
        return false
      }
    });
  }

  createFarmPoints = (pointsList) => {
    this.allFeatures = pointsList.features.map(this.createFeature);
    return this.createLayerWithFeatures(this.allFeatures);
  };

  createFeature = (item) => {
    let feature;

    if (item.geometry.type === 'Point') {
      const coordinates = fromLonLat(item.geometry.coordinates);
      feature = new Feature({
        geometry: new Point(coordinates),
        properties: item.properties,
        isPrecise: item.properties.precise,
      });
    } else if (item.geometry.type === 'Polygon') {
      const centroid3857 = fromLonLat(item.centroid);
      feature = new Feature({
        geometry: new Point(centroid3857),
        originalGeometry: item.geometry,
        properties: item.properties,
        isPrecise: item.properties.precise,
      });
    }

    return feature;
  };

  createLayerWithFeatures = (features) => {
    const source = new VectorSource({
      features,
    });

    const clusterSource = new ClusterSource({
      distance: 40,
      source,
    });

    const farmPointsLayer = new VectorLayer({
      name: 'farm_points',
      source: clusterSource,
      style: this.farmStyle,
      zIndex: 10,
    });

    return farmPointsLayer;
  };

  farmStyle = (feature) => {
    const size = feature.get('features').length;

    let color;
    if (size === 1) {
      color = feature.get('features')[0].get('isPrecise') ? '#08519c' : '#a7c3dc';
    } else if (size > 10) {
      color = '#54278f';
    } else {
      color = '#756bb1';
    }

    const preciseStyle = new Style({
      image: new Circle({
        radius: 11,
        scale: 1.8,
        fill: new Fill({
          color: color,
        }),
        stroke: new Stroke({ width: 2, color: 'rgba(255,255,255,0.5)' })
      }),
      text: new Text({
        text: size > 1 ? size.toString() : '',
        font: 'bold 14px sans-serif',
        fill: new Fill({
          color: '#fff',
        }),
        textAlign: 'center',
        // offsetY: -0.2
      }),
    });

    if (size === 1) {
      const iconStyle = new Style({
        image: new Icon({
          src: feature.get('features')[0].get('isPrecise') ? farm_img : farm_und,
          scale: 0.6
        })
      });

      return [preciseStyle, iconStyle];
    }

    return preciseStyle;
  };

  getCentroid = (coords) => {
    let centerX = 0;
    let centerY = 0;
    const n = coords.length;

    coords.forEach((coord) => {
      centerX += coord[0];
      centerY += coord[1];
    });

    return [centerX / n, centerY / n];
  };

  filterFeatures = (filter) => {
    const filteredFeatures = this.allFeatures.filter(feature => {
      const properties = feature.getProperties().properties;
      const matchesCriteria = Object.entries(filter).every(([key, value]) => {
        if (value !== '') {
          if (Array.isArray(properties[key])) {
            return properties[key].some(item => item.name.toLowerCase().includes(value.toLowerCase()));
          } else if (properties[key]) {
            return properties[key].toLowerCase().includes(value.toLowerCase());
          } else {
            return false;
          }
        } else {
          return true;
        }
      });

      return matchesCriteria;
    });

    return this.createLayerWithFeatures(filteredFeatures);
  };

  refreshFarmLayer = (data, map) => {
    const { pointsList } = this.getFarmLists(data)
    const newFarmLayer = this.createFarmPoints(pointsList);
    const oldFarmLayer = map.getLayers().getArray().find(layer => layer.get('name') === 'farm_points');
    if (oldFarmLayer) {
      map.removeLayer(oldFarmLayer);
    }

    map.addLayer(newFarmLayer);
  }

  getFarmLists = (response) => {
    const pointsList = {
      type: 'FeatureCollection',
      features: []
    };

    response.forEach(item => {
      if (item.poly !== null && item.poly !== undefined) {
        const polygonCoordinates = item.poly;
        const centroid = [item.point.lon, item.point.lat];
        pointsList.features.push({
          type: 'Feature',
          properties: {
            id: item.id, farm_name: item.farm_name, country: item.country, region: item.region ? item.region : '', contact: item.contact,
            source: item.source ? item.source : '', crop_group: item.crop_group ? item.crop_group : [], regenerative_practice: item.regenerative_practice ? item.regenerative_practice : [],
            regenerative_practice2: item.regenerative_practice2 ? item.regenerative_practice2 : [], photos: item.photos, precise: item.precise
          },
          geometry: {
            type: 'Polygon',
            coordinates: [polygonCoordinates],
          },
          centroid: centroid,
        });
      } else {
        const coordinates = [item.point.lon, item.point.lat];
        pointsList.features.push({
          type: 'Feature',
          properties: {
            id: item.id, farm_name: item.farm_name, country: item.country, region: item.region ? item.region : '', contact: item.contact,
            source: item.source ? item.source : '', crop_group: item.crop_group ? item.crop_group : [], regenerative_practice: item.regenerative_practice ? item.regenerative_practice : [],
            regenerative_practice2: item.regenerative_practice2 ? item.regenerative_practice2 : [], photos: item.photos, precise: item.precise
          },
          geometry: {
            type: 'Point',
            coordinates,
          },
        });
      }
    });

    return {
      pointsList
    };
  };

  //SurveyStepOne options
  createLocationLayer = (geojsonLocations) => {
    let features = []
    const featureItem = new GeoJSON().readFeature(geojsonLocations.geojson, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    });
    const type = geojsonLocations.geojson.type
    features.push(featureItem)

    return new VectorLayer({
      source: new VectorSource({ features }),
      zIndex: 11,
      style: type === "Point" ? new Style({
        image: new Circle({
          radius: 11,
          scale: 1.0,
          fill: new Fill({
            color: 'rgba(255, 0, 0, 0.2)',
          }),
          stroke: new Stroke({
            color: '#ff0000',
            width: 2,
          })
        }),
      }) : new Style({
        fill: new Fill({
          color: 'rgba(255, 0, 0, 0.1)',
        }),
        stroke: new Stroke({
          color: '#ff0000',
          width: 2,
        }),
      }),
      name: 'location_options',
    });

  };

  removeLocationLayer = (map) => {
    const layer = map.getLayers().getArray().find(layer => layer.get('name') === 'location_options');
    if (layer) {
      map.removeLayer(layer);
    }
  };

  dropCoordinatesPin = (coords) => {
    const pinFeature = new Feature({
      geometry: new Point(coords)
    });
    const pinSource = new VectorSource({
      features: [pinFeature]
    });
    return new VectorLayer({
      source: pinSource,
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: MarkerImg
        })
      }),
      zIndex: 11,
      name: 'location_options'
    });
  }

}

export default new $map();