import React, { useContext, useEffect, useRef, useState } from 'react';
import useMergeState from "../../services/$mergeState";
import defaultProfilePic from '../../assets/not_found.png';
import FarmDashboard from './dashboard/FarmDashboard';
import { Link, Navigate } from 'react-router-dom';
import $cookies from '../../services/$cookies';
import { AppContext } from '../../services/$contextProvider';
import ProfileEdit from './dashboard/ProfileEdit';
import SADashboard from './dashboard/SADashboard';
import $auth from '../../services/$auth';


export default function Dashboard(props) {
  const { $t, onUpdateState } = useContext(AppContext);
  const [state, setState] = useMergeState({ loading: false, active: 0, xlsxA: false });


  useEffect(() => {
    if (props.loggedIn) {
      checkIsA()
      if (props.active !== 'profile') {
        onUpdateState({ active: 'profile' })
      }
    }
  }, [props.loggedIn]);

  if (!props.loggedIn) {
    if ($cookies.get('user') === null || $cookies.get('user') === undefined) {
      return <Navigate to='/home' />
    }
  }

  const checkIsA = () => {
    $auth.isSA()
      .then(data => {
        setState({ xlsxA: data.code })
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <div className="Profile">
      <aside className="dashboard-menu">
        <div className="profile-header">
          <img className="profile-picture" src={props.user ? defaultProfilePic : defaultProfilePic} alt="Profile" />
          <h1>{props.user ? props.user.username : 'Anonymous'}</h1>
        </div>
        <ul>
          <li className={state.active === 0 ? 'active' : ''} onClick={() => { setState({ active: 0 }) }}>{$t.p_menu_farms}</li>
          <Link className='prof-menu-item' onClick={() => onUpdateState({ active: 'survey' })} to="/survey">{$t.p_menu_add}</Link>
          <li className={state.active === 2 ? 'active' : ''} onClick={() => { setState({ active: 2 }) }}>{$t.p_menu_edit}</li>
          {/* {state.xlsxA ? <li className={state.active === 3 ? 'active' : ''} onClick={() => { setState({ active: 3 }) }}>{$t.p_menu_sgui}</li> : null} */}
        </ul>
      </aside>
      <div className='dashboard-content'>
        {state.active === 0 ? <FarmDashboard /> : null}
        {state.active === 2 ? <ProfileEdit upk={props.user ? props.user.pk : null} /> : null}
        {/* {state.xlsxA && state.active === 3 ? <SADashboard upk={props.user ? props.user.pk : null} /> : null} */}
      </div>
    </div >
  );
}