import React, { useState, useEffect, useRef } from 'react';
import { useContext } from "react";
import { AppContext } from '../../services/$contextProvider';
import useMergeState from "../../services/$mergeState";
import _ from "lodash";
import $data from "../../services/$data";

export default function DropdownField(props) {
  const { $t } = useContext(AppContext);
  const timeoutId = useRef(null);

  const [state, setState] = useMergeState({
    options: [],
    loading: false
  });

  const [inputFocus, setInputFocus] = useState(false);

  const getOptions = useRef(_.debounce((input) => {
    setState({ loading: true });
    $data.getPolygons(`&q=${input}`).then(data => {
      let results = data.map(item => item);
      setState({ options: results, loading: false });
      props.onOptionsUpdate(results);
    }).catch(error => {
      console.error(error);
      setState({ loading: false });
    });
  }, 1000)).current;

  useEffect(() => {
    return () => clearTimeout(timeoutId.current);
  }, []);

  useEffect(() => {
    getOptions(props.query);
    props.onHandleInput('query_coordinates', false)
  }, [props.query_coordinates]);

  useEffect(() => {
    return () => {
      getOptions.cancel();
    };
  }, [getOptions]);

  const handleInputChange = (event) => {
    const input = event.target.value;
    setState({ loading: true, options: [] });
    props.updateQuery(input)
    getOptions(input);
  }

  return (
    <React.Fragment>
      <div className='dropdown-container'>
        <input
          autoComplete="off"
          className={`dropdown-input-text ${props.className ? props.className : ''}`}
          type={props.type ? props.type : 'text'}
          name={props.name ? props.name : 'email'}
          placeholder={props.placeholder ? props.placeholder : ''}
          onChange={handleInputChange}
          onFocus={() => setInputFocus(true)}
          onBlur={() => timeoutId.current = setTimeout(() => setInputFocus(false), 200)}
          value={props.query}
        />
      </div>
      {props.error && (
        <span className='error_text2'>{props.error}</span>
      )}
    </React.Fragment>
  );
}