import { Grid, TextField, Typography } from "@mui/material";

function MonthsForm(props) {

  const onChangeMonthlyList = (index, value) => {
    let newArr = [...props.list];
    newArr.splice(index, 1, value);
    props.onUpdateModel({[props.name]: newArr});
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body2" className="text-primary"><strong>{props.title}</strong></Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
        <TextField onChange={(evt) => onChangeMonthlyList(0, evt.target.value)} value={props.list[0]} type="number" variant="outlined" size="small" label="January"></TextField>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
        <TextField onChange={(evt) => onChangeMonthlyList(1, evt.target.value)} value={props.list[1]} type="number" variant="outlined" size="small" label="February"></TextField>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
        <TextField onChange={(evt) => onChangeMonthlyList(2, evt.target.value)} value={props.list[2]} type="number" variant="outlined" size="small" label="March"></TextField>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
        <TextField onChange={(evt) => onChangeMonthlyList(3, evt.target.value)} value={props.list[3]} type="number" variant="outlined" size="small" label="April"></TextField>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
        <TextField onChange={(evt) => onChangeMonthlyList(4, evt.target.value)} value={props.list[4]} type="number" variant="outlined" size="small" label="May"></TextField>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
        <TextField onChange={(evt) => onChangeMonthlyList(5, evt.target.value)} value={props.list[5]} type="number" variant="outlined" size="small" label="June"></TextField>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
        <TextField onChange={(evt) => onChangeMonthlyList(6, evt.target.value)} value={props.list[6]} type="number" variant="outlined" size="small" label="Jully"></TextField>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
        <TextField onChange={(evt) => onChangeMonthlyList(7, evt.target.value)} value={props.list[7]} type="number" variant="outlined" size="small" label="August"></TextField>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
        <TextField onChange={(evt) => onChangeMonthlyList(8, evt.target.value)} value={props.list[8]} type="number" variant="outlined" size="small" label="September"></TextField>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
        <TextField onChange={(evt) => onChangeMonthlyList(9, evt.target.value)} value={props.list[9]} type="number" variant="outlined" size="small" label="October"></TextField>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
        <TextField onChange={(evt) => onChangeMonthlyList(10, evt.target.value)} value={props.list[10]} type="number" variant="outlined" size="small" label="November"></TextField>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
        <TextField onChange={(evt) => onChangeMonthlyList(11, evt.target.value)} value={props.list[11]} type="number" variant="outlined" size="small" label="December"></TextField>
      </Grid>
    </Grid>
  )
}

export default MonthsForm;