import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../services/$contextProvider';
import useMergeState from "../../../services/$mergeState";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import TextField from '@mui/material/TextField';

export default function CropCoverForm(props) {
  const { $t } = useContext(AppContext);
  const [form, setForm] = useMergeState({ model: {}, error: {} });


  useEffect(() => {
  }, [])

  const onChange = (name, value) => {
    const op_model = {
      ...form.model,
      [name]: value
    }
    setForm({
      model: op_model
    })
    props.updateOp({
      error: {},
      op_model: {
        cover: op_model
      }
    })
  }

  const setDefault = (item) => {
    setForm({
      model: {
        cover_crop_type: item.id,
        labour_cost: 0
      }
    })
  }

  const coverOptions = () => {
    let crop_cover_operation = props.operations.cover.filter(cover => props.record.crop === cover.crop_cover)
    let operation = props.types.cover;
    if (crop_cover_operation.length !== 0) {
      crop_cover_operation.map(item => {
        operation = operation.filter(op => item.cover_crop_type.toString() !== op.id.toString())
      })
      if ((form.model.cover_crop_type === undefined || form.model.labour_cost === undefined) && operation.length !== 0) {
        setDefault(operation[0])
      }
      return operation.map((item) => {
        return <option key={item.id} value={item.id}>{item.name}</option>
      })
    } else {
      if ((form.model.cover_crop_type === undefined || form.model.labour_cost === undefined) && operation.length !== 0) {
        setDefault(operation[0])
      }
      return operation.map((item) => {
        return <option key={item.id} value={item.id}>{item.name}</option>
      })
    }
  }

  return (
    <React.Fragment>
      <FormControl className='ifield'>
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          {$t.cover_crop_label}
        </InputLabel>
        <NativeSelect
          value={form.model.cover_crop_type || ''}
          onChange={(e) => onChange('cover_crop_type', e.target.value)}
          placeholder={$t.cover_crop_type_placeholder}
          inputProps={{
            name: 'cc_type',
            id: 'uncontrolled-native',
          }}
        >
          {coverOptions()}
        </NativeSelect>
      </FormControl>
      <TextField
        className='ifield'
        type="number"
        value={form.model.labour_cost || ''}
        onChange={(e) => onChange('labour_cost', e.target.value)}
        label={$t.cover_crop_labor_form_label}
        variant="standard" />
    </React.Fragment>
  );
}