import axios from 'axios';
let showLoginModal;

const $interceptors = {
  _default(err) {
    return {
      status: err.response.status,
      message: err.response.data,
      statusText: err.response.statusText,
    };
  },

  _network() {
    return {
      status: -999,
      message: {
        non_field_errors: [
          'Service is unavailable. Please check your internet connection. If connection is not the problem, please contact our technical support.',
        ],
      },
      statusText: 'Network Error',
    };
  },

  _internal(err) {
    return {
      status: err.response.status,
      message: {
        non_field_errors: [
          'Error occurred on the server. Please contact our technical support.',
        ],
      },
      statusText: err.response.statusText,
    };
  },

  _not_found(err) {
    return {
      status: 404,
      message: {
        non_field_errors: ['URL not found!'],
      },
      statusText: err.response.statusText,
    };
  },

  _not_authorized(err) {
    return {
      status: 401,
      message: {
        non_field_errors: ['Your session has expired!'],
      },
      statusText: err.response.statusText,
    };
  },

  setShowLoginModal: (showLoginFunc) => {
    showLoginModal = showLoginFunc;
  },

  init() {
    axios.interceptors.response.use(
      (response) => response,
      async (err) => {
        if (!err.response) {
          // NETWORK ERROR
          return Promise.reject(this._network());
        } else {
          // INTERNAL SERVER ERROR
          if (err.response.status === 500) {
            return Promise.reject(this._internal(err));
          }

          if (err.response.status === 404) {
            return Promise.reject(this._not_found(err));
          }

          if (err.response.status === 401) {
            try {
              showLoginModal();
              return Promise.reject(this._not_authorized(err));
            } catch (refreshError) {
              console.log(refreshError)
              // window.location.href = '/login';
              return Promise.reject(this._not_authorized(err));
            }
          }

          // DEFAULT ERROR
          return Promise.reject(this._default(err));
        }
      }
    );
  },
};

export default $interceptors;