import React, { useContext } from 'react';
import { AppContext } from '../../../services/$contextProvider';
import $cookies from '../../../services/$cookies';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export default function RecordRemoveModal(props) {
  const { $t } = useContext(AppContext);

  const getOperationsForRecord = (record_name) => {
    let list = []
    list = props.operations
    list.tillage = (props.operations.tillage !== null && props.operations.tillage !== undefined) ? props.operations.tillage.filter(till => record_name !== till.crop_cover) : []
    list.cover = (props.operations.cover !== null && props.operations.cover !== undefined) ? props.operations.cover.filter(cover => record_name !== cover.crop_cover) : []
    list.mulching = (props.operations.mulching !== null && props.operations.mulching !== undefined) ? props.operations.mulching.filter(mulch => record_name !== mulch.crop_cover) : []

    return list
  }

  const removeRecord = () => {
    const rc = props.records.filter(item => item.crop !== props.record)
    if (props.operations !== undefined && props.operations !== []) {
      const op = getOperationsForRecord(props.record)
      props.onRemoveUpdate({
        records: rc,
        operations: op
      });
      $cookies.remove('records');
      $cookies.remove('operations');
      $cookies.set('records', JSON.stringify(rc));
      $cookies.set('operations', JSON.stringify(op));
    } else {
      props.onRemoveUpdate({
        records: rc
      });
      $cookies.remove('records');
      $cookies.set('records', JSON.stringify(rc));
    }
    props.onClose()
  }


  return (
    <div className='modal-container' onClick={props.onClose}>
      {props.record !== undefined ? <div className='modal-form-wrapper' onClick={(e) => e.stopPropagation()}>
        <section className='modal-header'><h2>{$t.modal_first_header}</h2></section>
        <section className='modal-text'>{$t.modal_first_text} {props.record} {$t.modal_first_text2}</section>
        <section className='modal-buttons'>
          <Button className='negative-btn' variant='contained' onClick={props.onClose}>{$t.cancel}</Button>
          <Button className='positive-btn' variant='contained' disabled={props.record === null && props.record === undefined} onClick={removeRecord} >{$t.yes}</Button>
        </section>
      </div> : <div className='modal-form-wrapper' onClick={(e) => e.stopPropagation()}>
          <CircularProgress size={20} />
        </div>}
    </div>
  );
}
