import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../services/$contextProvider';
import useMergeState from "../../../services/$mergeState";
import $cookies from '../../../services/$cookies';

import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import RegAgriRecords from '../ecoViews/RegAgriRecords';
import RegAgriFormModal from '../ecoViews/RegAgriFormModal';

export default function SecondStep(props) {
  const { $t } = useContext(AppContext);
  const [form, setForm] = useMergeState({ addOperationModal: false, crop_record: null, loading: true, error: {} });


  useEffect(() => {
    if (form.loading && props.records !== undefined) {
      if (!checkLists()) {
        setForm({ loading: false })
      } else {
        setForm({ loading: false, error: { msg: $t.empty_lists_error } })
      }
    }
  }, [])

  const handleChange = (form) => {
    setForm(form)
  }

  const checkLists = () => {
    let empty = false
    if (props.records.length === 0) {
      empty = true
    }
    if (props.types.cover.length === 0) {
      empty = true
    }
    if (props.types.mulching.length === 0) {
      empty = true
    }
    if (props.types.tillage.length === 0) {
      empty = true
    }
    return empty
  }

  const updateOperationState = (name, value, operation, type) => {
    let list = null
    if (name === 'cover') {
      list = props.operations.cover;
    }
    if (name === 'tillage') {
      list = props.operations.tillage;
    }
    if (name === 'mulching') {
      list = props.operations.mulching;
    }
    if (operation === 1) {
      list.push(Object.assign(value))
      const newList = {
        ...props.operations,
        [name]: list
      }
      props.onUpdateExplorationState({
        operations: newList
      })
      $cookies.remove('operations');
      $cookies.set('operations', JSON.stringify(newList));
    } else if (operation === 2) {
      list = list.filter(item => item.crop_cover === value.crop_cover && item[type] !== value[type])
      const newList = {
        ...props.operations,
        [name]: list
      }
      props.onUpdateExplorationState({
        operations: newList
      })
      $cookies.remove('operations');
      $cookies.set('operations', JSON.stringify(newList));
    }
    setForm({ addOperationModal: false, crop_record: null })
  }

  const renderOperations = () => {
    try {
      if ((props.operations !== undefined && props.records.length !== 0) && !form.error.msg && props.types !== undefined) {
        return props.records.map((record, key) => {
          let tl_list = props.operations.tillage.filter(till => record.crop === till.crop_cover)
          let cc_list = props.operations.cover.filter(cover => record.crop === cover.crop_cover)
          let ml_list = props.operations.mulching.filter(mulch => record.crop === mulch.crop_cover)
          let operation_count = cc_list.length + tl_list.length + ml_list.length
          return (
            <RegAgriRecords key={key}
              updateSecondState={handleChange}
              operation_count={operation_count}
              cropTypes={props.cropTypes}
              mulching_operation={ml_list}
              crop_cover_operation={cc_list}
              tillage_operation={tl_list}
              typesList={props.types}
              record={record} records={props.records}
              onUpdateExplorationState={props.onUpdateExplorationState}
              onUpdateOperationState={updateOperationState} />
          )
        });
      } else {
        return <div className='md-container'>{form.error.msg}</div>
      }
    } catch (e) {
      console.log(e)
      return <div className='md-container'>{form.error.msg}</div>
    }
  }

  return (
    <div className='second-container'>
      {form.error.msg ? <Alert variant="outlined" severity="error">
        {form.error.msg}
      </Alert> : null}
      {!form.loading ? renderOperations() : <div className='loader-container'><CircularProgress size={20} /></div>}
      {(props.types !== undefined && form.crop_record !== null) && form.addOperationModal ? <RegAgriFormModal record={form.crop_record} operations={props.operations} types={props.types} updateSecondState={handleChange} onUpdateOperationState={updateOperationState} /> : null}
    </div>
  );
}