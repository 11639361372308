import axios from 'axios';
import $cookies from './$cookies';
import moment from 'moment';
import URL from '../config/urls';

class $auth {

  storeLoginData(token, refresh, user) {
    if (user) {
      $cookies.set('user', JSON.stringify(user));
      $cookies.set('locale', user.language || 'en');
      axios.defaults.headers.common['Accept-Language'] = user.language || 'en';
      moment.locale(user.language || 'en');
    }

    if (token && refresh) {
      $cookies.set('token', token);
      $cookies.set('refresh', refresh);
      // console.log(token)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
  }

  updateUser(model) {
    return axios.patch(URL.USER, model)
      .then(result => {
        this.storeLoginData(null, null, result.data)
        return result.data
      })
      .catch(err => { throw err })
  }

  login(email, password) {
    const model = {
      email: email,
      password: password
    }
    return axios.post(URL.LOGIN, model)
      .then(result => {
        this.storeLoginData(result.data.access_token, result.data.refresh_token, result.data.user);

        moment.locale(result.data.user.language || 'en');

        return result.data;
      })
      .catch(err => { throw err })
  }

  unauthorize() {
    $cookies.remove('user');
    return axios.post(URL.LOGOUT)
      .then(result => this.invalidate())
      .catch(err => { this.invalidate(); throw err })
  }

  register(email, password1, password2) {
    const model = {
      email: email,
      password1: password1,
      password2: password2
    }
    return axios.post(URL.REGISTRATION, model)
      .then(result => {
        return result.data
      })
      .catch(err => { throw err })
  }

  refreshToken(refresh) {
    return axios.post(URL.TOKEN_REFRESH, { refresh })
      .then(result => {
        this.storeLoginData(result.data.access, $cookies.get('refresh'), null);
        return result.access
      })
      .catch(err => {
        this.unauthorize();
        throw err
      })
  }

  isSA() {
    const token = {
      token: $cookies.get('token')
    }
    const auth = {
      headers: { Authorization: 'Bearer ' + $cookies.get('token') }
    }
    return axios.post(URL.SA, token, { ...auth })
      .then(result => {
        return result.data
      })
      .catch(err => {
        console.log(err)
        throw err
      })
  }

  isLoggedIn(token) {
    return axios.post(URL.TOKEN_VERIFY, { token })
      .then(result => result.data.access_token)
      .catch(err => {
        this.unauthorize();
        throw err
      })
  }

  invalidate() {
    $cookies.remove('user');
    $cookies.remove('token');
    $cookies.remove('refresh');
    delete axios.defaults.headers.common['Authorization'];
    delete axios.defaults.headers.common['Accept-Language'];
  }


  changePassword(password1, password2) {
    return axios.post(URL.CHANGE_PASSWORD, { password1, password2 })
      .then(result => {
        // console.log(result)
        return result
      })
      .catch(err => {
        this.unauthorize();
        throw err
      })
  }

  removeUser(id) {
    return axios.delete(URL.DELETE_ACCOUNT + `${id}`,)
      .then(result => {
        this.unauthorize();
        return result
      })
      .catch(err => {
        throw err
      })
  }
}

export default new $auth();