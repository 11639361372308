import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../services/$contextProvider';
import { Link } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const HomeCarousel = ({ items }) => {
    const { $t } = useContext(AppContext);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            handleNextSlide();
        }, 6000);

        return () => clearInterval(intervalId);
    }, [currentSlide]);

    const handlePrevSlide = () => {
        const newSlide = currentSlide === 0 ? items.length - 1 : currentSlide - 1;
        setCurrentSlide(newSlide);
        setActiveIndex(newSlide);
    };


    const handleNextSlide = () => {
        const newSlide = currentSlide === items.length - 1 ? 0 : currentSlide + 1;
        setCurrentSlide(newSlide);
        setActiveIndex(newSlide);
    };

    return (
        <div className='exploration-home-carousel'>
            <div className="carousel-background" style={{
                transform: `translateX(-${currentSlide * 100}%)`
            }}>
                {items.map((item, index) => (
                    <div key={index} className={`carousel-item ${index === currentSlide ? 'active' : ''}`}>
                        <img src={item.background} alt={item.title} />
                        <div className="carousel-content">
                            <h1>{item.title}</h1>
                            <p>{item.description}</p>
                            <Link className='learn' to={item.url}><span>{$t.home_carousel_learn_more_label}</span></Link>
                        </div>
                    </div>
                ))}
            </div>
            <div className="carousel-overlay">
                <button className="prev" onClick={handlePrevSlide}>
                    <ArrowBackIosNewIcon />
                </button>
                <div className="carousel-indicators">
                    {items.map((_, index) => (
                        <div
                            key={index}
                            className={`indicator ${index === activeIndex ? 'active' : ''}`}
                            onClick={() => {
                                setActiveIndex(index);
                                setCurrentSlide(index);
                            }}
                        ></div>
                    ))}
                </div>
                <button className="next" onClick={handleNextSlide}>
                    <ArrowForwardIosIcon />
                </button>
            </div>
        </div>
    );
};

export default HomeCarousel;