import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";
import { useState } from "react";

function MonthsCheckboxes(props) {


  const onChange = (index, checked) => {
    let newArr = [...props.list];
    newArr.splice(index, 1, checked);
    props.onUpdateModel({[props.name]: newArr});
  }

  return (
    <Grid sx={{'& .MuiSvgIcon-root': {color: '#4A8522'}}} container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body2" className="text-primary"><strong>Under bare soil conditions (or under vegetated soil) by month</strong></Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} >
        <FormGroup>
          <FormControlLabel control={<Checkbox  checked={props.list[0]} onChange={(evt, checked) => onChange(0, checked)} />} label="January" />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} >
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={props.list[1]} onChange={(evt, checked) => onChange(1, checked)} />} label="February" />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} >
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={props.list[2]} onChange={(evt, checked) => onChange(2, checked)} />} label="March" />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} >
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={props.list[3]} onChange={(evt, checked) => onChange(3, checked)} />} label="April" />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} >
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={props.list[4]} onChange={(evt, checked) => onChange(4, checked)} />} label="May" />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} >
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={props.list[5]} onChange={(evt, checked) => onChange(5, checked)} />} label="June" />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} >
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={props.list[6]} onChange={(evt, checked) => onChange(6, checked)} />} label="Jully" />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} >
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={props.list[7]} onChange={(evt, checked) => onChange(7, checked)} />} label="August" />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} >
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={props.list[8]} onChange={(evt, checked) => onChange(8, checked)} />} label="September" />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} >
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={props.list[9]} onChange={(evt, checked) => onChange(9, checked)} />} label="October" />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} >
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={props.list[10]} onChange={(evt, checked) => onChange(10, checked)} />} label="November" />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2} >
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={props.list[11]} onChange={(evt, checked) => onChange(11, checked)} />} label="December" />
        </FormGroup>
      </Grid>
      
    </Grid>
  )
}

export default MonthsCheckboxes;