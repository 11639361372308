import React, { useContext } from 'react';
import { AppContext } from '../../../services/$contextProvider';
import useMergeState from "../../../services/$mergeState";

import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function OperationCard(props) {
  const { $t } = useContext(AppContext);
  const [state, setState] = useMergeState({ selected: null })

  const getCropCard = () => {
    return props.operations.map((op, index) => {
      return <Card key={index} sx={{ maxWidth: 345 }} className='card'>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {getName(op.cover_crop_type, 1)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {$t.card_labour_label}: {op.labour_cost}{$t.card_lunit_label}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => removeOperation('cover', op, 'cover_crop_type')}>X</Button>
        </CardActions>
      </Card>
    })
  }

  const getTillageCard = () => {
    return props.operations.map((op, index) => {
      return <Card key={index} sx={{ maxWidth: 345 }} className='card'>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {getName(op.tillage_type, 2)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {$t.card_labour_label}: {op.labour_cost}{$t.card_lunit_label}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => removeOperation('tillage', op, 'tillage_type')}>X</Button>
        </CardActions>
      </Card>
    })
  }

  const getMulchingCard = () => {
    return props.operations.map((op, index) => {
      return <Card key={index} sx={{ maxWidth: 345 }} className='card'>
        {/* <CardMedia
          sx={{ height: 120 }}
          image={nf} /> */}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {getName(op.mulching_type, 1)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {$t.card_pesticide_label}: {op.pesticide_rate}{$t.card_haunit_label}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {$t.card_fert_label}: {op.fertilization_rate}{$t.card_haunit_label}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {$t.card_irrig_label}: {op.irrigation_rate}{$t.card_haunit_label}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => removeOperation('mulching', op, 'mulching_type')}>X</Button>
        </CardActions>
      </Card>
    })
  }

  const getName = (id, option) => {
    try {
      if (option == 2) {
        return (props.types.filter((item) => item.id == id))[0].options
      } else return (props.types.filter((item) => item.id == id))[0].name

    } catch (e) {
      return 'n/a'
    }
  }

  const removeOperation = (name, model, type) => {
    props.onUpdateOperationState(name, model, 2, type)
  }

  const renderCards = () => {
    if (props.operations.length > 0) {
      return <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        onClick={(e) => e.stopPropagation()}>
        {props.operation == 1 ? getCropCard() : null}
        {props.operation == 2 ? getTillageCard() : null}
        {props.operation == 3 ? getMulchingCard() : null}
      </Stack>
    } else return <div></div>
  }

  return (
    <div className='cards-carousel'>
      {props.operations !== undefined ? renderCards() : <div></div>}
    </div>
  );
}