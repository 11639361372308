import React, { useState } from 'react';

export const AppContext = React.createContext();

const ContextProvider = (props) => {

  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);

  const showLoginModal = () => setIsLoginModalVisible(true);
  const hideLoginModal = () => setIsLoginModalVisible(false);

  const contextValue = {
    ...props.value,
    
    //values for login modal
    isLoginModalVisible,
    showLoginModal,
    hideLoginModal,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {props.children}
    </AppContext.Provider>
  )
}

export default ContextProvider
