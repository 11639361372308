import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../services/$contextProvider';
import useMergeState from "../../services/$mergeState";
import $cookies from '../../services/$cookies'
import $auth from '../../services/$auth';
import { Link } from 'react-router-dom';
import InputField from '../ui/InputField'
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export default function Login(props) {
  const { $t, onUpdateState } = useContext(AppContext);
  const [state, setState] = useMergeState({ email: '', password: '', loading: false, error: {} });

  useEffect(() => {
    if ($cookies.get('verification') !== null && $cookies.get('verification') !== undefined) {
      const verification = $cookies.get('verification')
      const verified = verification.split("|")[0]
      if (verified === 'true') {
        const verificationMessage = verification.split("|")[1]
        setState({ verification: 'true', verified, verificationMessage });
      }

      if (verified === 'false') {
        setState({ verification: 'false', verified, verificationMessage: '' });
      }
    }
    $cookies.remove('verification');
  }, [])

  const onHandleChange = (name, value) => {
    setState({ [name]: value });
  }

  const validateField = (name, value) => {
    if (value === undefined || value === null || value === '') {
      setState({
        error: {
          [name]: $t[name]
        }
      })
      return false
    }
    if (name === 'email_error') {
      if (!/\S+@\S+\.\S+/.test(value)) {
        setState({
          error: {
            [name]: $t.email2_error
          }
        })
        return false
      }
    }
    return true
  }

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const onSubmit = (evt) => {
    setState({ loading: true, error: {} });
    if (validateField('email_error', state.email) && validateField('password1_error', state.password)) {
      $auth.login(state.email, state.password)
        .then(data => {
          setState({ loading: false });
          onUpdateState({
            loggedIn: true,
            user: data.user
          })
        })
        .catch(err => {
          console.log(err)
          setState({ error: err.message, loading: false });
        })
    } else {
      setState({ loading: false })
    }
  }

  return (
    <div className='auth-form'>
      <Snackbar open={state.error.non_field_errors ? true : false} autoHideDuration={6000} onClose={() => { setState({ error: {} }) }}>
        <MuiAlert elevation={6} variant="filled" severity="error">{state.error.non_field_errors}</MuiAlert>
      </Snackbar>
      {state.verified === 'true' ? <MuiAlert severity="success">{state.verificationMessage}</MuiAlert> : null}
      <h1>{$t.sign_in}</h1>
      <span className='register-link'>{$t.login_register_text1}
      </span>
      <span className='register-link'><Link className="text-bold" to="/register">{$t.login_register_text2}</Link></span>
      <InputField maxLength={32} error={state.error.email_error} type={'text'} value={state.email} onKeyPress={(evt) => handleKeypress(evt)} name={'email'} placeholder={$t.placeholder_email} onChange={(evt) => onHandleChange('email', evt.target.value)} />
      <InputField error={state.error.password1_error} value={state.password} type={'password'} onKeyPress={(evt) => handleKeypress(evt)} name={'password'} placeholder={$t.placeholder_password} onChange={(evt) => onHandleChange('password', evt.target.value)} />
      <span className='link-start'>{$t.login_forgotten_password1}<a>{$t.login_forgotten_password2}</a></span>
      <button className='signin-btn' onClick={(evt) => { evt.preventDefault(); onSubmit() }}>{$t.login}</button>
    </div >
  );
}