import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../services/$contextProvider';
import $cookies from '../../services/$cookies';
import ecoData from './ecoData'
import useMergeState from "../../services/$mergeState";
import FirstStep from './steps/FirstStep';
import SecondStep from './steps/SecondStep';
import FinalStep from './steps/FinalStep';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Container } from '@mui/system';

export default function EconomicalBenefits() {
  const { $t, onUpdateState } = useContext(AppContext);
  const [activeStep, setActiveStep] = React.useState(1);
  const [data, setData] = useMergeState({ cropType: [], types: {}, fuel_price: '–', fuel_prices: null, records: [], operations: [], loading: true, loaded: false, error: null, labelProps: {} });

  const steps = ($t) => {
    const steps = [$t.step_1_text,
    $t.step_2_text,
    $t.step_3_title,]
    return steps
  }


  useEffect(() => {
    onUpdateState({ active: 'econ' });
    if (data.loaded === false) {
      fetchEcoData()
    }
  }, [])

  const onUpdateLocalState = (state) => {
    setData(state);
  }

  const previousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const fetchEcoData = () => {
    setData({ loading: true })
    const fetchRecords = ($cookies.get('records') !== undefined && $cookies.get('records') !== null) ? JSON.parse($cookies.get('records')) : [];
    const fetchCrops = ecoData.getCrops(onUpdateLocalState)
    const fetchTypes = ecoData.getEcoTypes(onUpdateLocalState)
    const fetchFuelPrices = ecoData.getFuelPrices(onUpdateLocalState)
    const fetchOperations = ($cookies.get('operations') !== undefined && $cookies.get('operations') !== null) ? JSON.parse($cookies.get('operations')) : ecoData.getEcoOperations(onUpdateLocalState);

    Promise.all([fetchRecords, fetchCrops, fetchTypes, fetchFuelPrices, fetchOperations]).then((responses) => {
        ($cookies.get('operations') !== undefined && $cookies.get('operations') !== null) ? setData({
          loading: false,
          loaded: true,
          records: fetchRecords,
          operations: fetchOperations
        }) :
        setData({
          loading: false,
          loaded: true,
          records: fetchRecords,
        });
    });
  }

  const setError = (msg) => {
    const labelProps = {};
    labelProps.optional = (
      <Typography variant="caption" color="error">
        {msg}
      </Typography>
    );
    labelProps.error = true;
    return labelProps
  }

  const checkError = (index) => {
    try {
      if (data.error[index] !== undefined) return true
      else return false
    } catch (e) {
      return false
    }
  }

  const nextStep = () => {
    let { records, operations } = data;

    if (activeStep === 1) {
      if (records.length > 0) {
        setData({ error: null, labelProps: {} });
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        return
      }
      const msg = {
        0: true
      }

      setData({ error: msg, labelProps: setError($t.first_step_error) })

      return
    }

    if (activeStep === 2) {
      let isEmpty = false;
      records.map(item => {
        if (!operations.cover.some(op => item.crop === op.crop_cover) && !operations.tillage.some(op => item.crop === op.crop_cover) && !operations.mulching.some(op => item.crop === op.crop_cover)) {
          isEmpty = true
        }
      })
      if (!isEmpty) {
        setData({ error: null, labelProps: {} });
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        return
      }
      const msg = {
        1: true
      }

      setData({ error: msg, labelProps: setError($t.second_step_error) })

      return
    }

    if (activeStep === 3) {
      return
    }

  }

  return (
    <Container style={{ height: '100%' }}>
      <Box className='steps-container' sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep - 1} alternativeLabel>
          {steps($t).map((label, index) => (
            checkError(index) === true ? <Step key={label}>
              <StepLabel {...data.labelProps}>{label}</StepLabel>
            </Step> : <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
          ))}
        </Stepper>
        <div>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              className='btn previous'
              color="inherit"
              disabled={activeStep === 1}
              classes={{ disabled: 'btn-disabled' }}
              onClick={previousStep}
              sx={{ mr: 1 }}
            >
              {$t.previous}
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep === 1 || activeStep === 2 ? <Button
              className='btn next'
              onClick={nextStep} sx={{ mr: 1 }}>
              {activeStep === 3 ? $t.finish : $t.next}
            </Button> : null}
          </Box>
        </div>
        {!data.loading && data.loaded ? <div className='step-container'>
          <div className='step-title'>
            {activeStep === 1 ? $t.step_1_title : null}
            {activeStep === 2 ? $t.step_2_title : null}
            {activeStep === 3 ? $t.step_3_title : null}
          </div>
          {activeStep === 1 && <FirstStep fuel_prices={data.fuel_prices} fuel_price={data.fuel_price} types={data.cropType} records={data.records} operations={data.operations} onUpdateState={onUpdateLocalState} onUpdateExplorationState={onUpdateLocalState} />}
          {activeStep === 2 && <SecondStep cropTypes={data.cropType} types={data.types} records={data.records} operations={data.operations} onUpdateExplorationState={onUpdateLocalState} />}
          {activeStep === 3 && <FinalStep fuel_price={data.fuel_price} types={data.types} records={data.records} operations={data.operations} />}
        </div> : null}
      </Box>
    </Container>
  );
}