import axios from 'axios';
import URL from '../config/urls';
import { fromLonLat } from 'ol/proj';
import $cookies from './$cookies';

class $data {

  //ECONOMICAL BENEFITS
  getResultsFormatted = (data) => {
    //todo return ovde
    let obj = {}
    try {
      data.map(item => {
        obj[Object.keys(item)[0]] = {
          cover_crop: [],
          tillage: [],
          mulching: []
        }
      })
      data.map(item => {
        const key = Object.keys(item)[0]
        obj[key][item[key][0].operation].push({ data: item[key][0].data })
        return obj
      })
      return obj
    } catch (e) {
      return {
        cover_crop: [],
        tillage: [],
        mulching: []
      }
    }
  }

  ecCropTypes() {
    const instance = axios.create();
    instance.CancelToken = axios.CancelToken;
    let src = instance.CancelToken.source();
    src.cancel('cancel');
    src = instance.CancelToken.source();
    const auth = {
      headers: {}
    }
    return axios.get(URL.EC_CROP, { cancelToken: src.token, ...auth })
      .then(result => result.data)
      .catch(err => { throw err })
  }

  ecCropMonitoring() {
    const instance = axios.create();
    instance.CancelToken = axios.CancelToken;
    let src = instance.CancelToken.source();
    src.cancel('cancel');
    src = instance.CancelToken.source();
    return axios.get(URL.CROP_MONITORING, { cancelToken: src.token })
      .then(result => result.data)
      .catch(err => { throw err })
  }

  getEcResults(lists) {
    return axios.post(URL.EC_RESULTS, lists)
      .then(result => this.getResultsFormatted(result.data))
      .catch(err => { throw err })
  }

  getEcoBenefitsTypes() {
    return axios.all([
      axios.get(URL.CROP_COVER_TYPE),
      axios.get(URL.TILLAGE_TYPE),
      axios.get(URL.MULCHING_TYPE)
    ])
      .then(axios.spread((cover, tillage, mulching) => {
        let data = {
          cover: cover.data,
          tillage: tillage.data,
          mulching: mulching.data
        };
        return data
      }))
      .catch(err => { throw err })
  }
  getEcoBenefitsOperations() {
    return axios.all([
      axios.get(URL.CROP_COVER),
      axios.get(URL.TILLAGE),
      axios.get(URL.MULCHING)
    ])
      .then(axios.spread((cover, tillage, mulching) => {
        let data = {
          cover: cover.data,
          tillage: tillage.data,
          mulching: mulching.data
        };
        return data
      }))
      .catch(err => { throw err })
  }

  getFuelPrices() {
    return axios.get(URL.FUEL_PRICE)
      .then((result) => {
        return result.data
      })
      .catch(err => { throw err })
  }

  //SOC SEQUESTRATION
  rothcPointQuery(lon, lat) {
    const instance = axios.create();
    instance.CancelToken = axios.CancelToken;
    let src = instance.CancelToken.source();
    src.cancel('cancel');
    src = instance.CancelToken.source();
    return axios.get(URL.EXP_POINT_QUERY + `?lon=${lon}&lat=${lat}`, { cancelToken: src.token })
      .then(result => {
        return result.data
      })
      .catch(err => { throw err })
  }
  getLocations(query) {
    if (!query) return;
    return axios.get(URL.NOMINATIM + query)
      .then((result) => {
        return result.data.map(obj => ({
          key: JSON.stringify(obj.place_id + Math.random()),
          title: obj.display_name,
          description: `${obj.lat}, ${obj.lon}`,
          extent: [
            parseFloat(obj.boundingbox[2]),
            parseFloat(obj.boundingbox[0]),
            parseFloat(obj.boundingbox[3]),
            parseFloat(obj.boundingbox[1])
          ]
        }))
      })
      .catch(err => { throw err })
  }

  //BESTPRACTICE
  getFarms(option) {
    const instance = axios.create();
    instance.CancelToken = axios.CancelToken;
    let src = instance.CancelToken.source();
    src.cancel('cancel');
    src = instance.CancelToken.source();
    // console.log(option, $cookies.get('token'))
    if (option === 1 & $cookies.get('token')) {
      const auth = {
        headers: { Authorization: 'Bearer ' + $cookies.get('token') }
      }
      return axios.get(URL.FARM, { ...auth, cancelToken: src.token })
        .then(result => {
          console.log(result)
          return result.data
        })
        .catch(err => { throw err })
    }
    else {
      return axios.get(URL.FARM, { cancelToken: src.token })
        .then(result => {
          return result.data
        })
        .catch(err => { throw err })
    }
  }

  getFarmsDashboard() {
    const instance = axios.create();
    instance.CancelToken = axios.CancelToken;
    let src = instance.CancelToken.source();
    src.cancel('cancel');
    src = instance.CancelToken.source();
    if ($cookies.get('token')) {
      const auth = {
        headers: { Authorization: 'Bearer ' + $cookies.get('token') }
      }
      const e_url = URL.FARM + '?private=True'
      return axios.get(e_url, { ...auth, cancelToken: src.token })
        .then(result => {
          return result.data
        })
        .catch(err => { throw err })
    }
    else {
      return axios.get(URL.FARM, { cancelToken: src.token })
        .then(result => {
          return result.data
        })
        .catch(err => { throw err })
    }
  }

  getFarmsSADashboard() {
    const instance = axios.create();
    instance.CancelToken = axios.CancelToken;
    let src = instance.CancelToken.source();
    src.cancel('cancel');
    src = instance.CancelToken.source();
    if ($cookies.get('token')) {
      const auth = {
        headers: { Authorization: 'Bearer ' + $cookies.get('token') }
      }
      const e_url = URL.FARM + '?private=False'
      return axios.get(e_url, { ...auth, cancelToken: src.token })
        .then(result => {
          return result.data
        })
        .catch(err => { throw err })
    }
    else {
      return axios.get(URL.FARM, { cancelToken: src.token })
        .then(result => {
          return result.data
        })
        .catch(err => { throw err })
    }
  }

  postXslxFarm(model) {
    const auth = {
      headers: { Authorization: 'Bearer ' + $cookies.get('token') }
    }
    return axios.post(URL.FARM, model, { ...auth })
      .then(result => result.data)
      .catch(err => { throw err })
  }

  postFormFarm(model) {
    const instance = axios.create();
    instance.CancelToken = axios.CancelToken;
    let src = instance.CancelToken.source();
    src.cancel('cancel');
    src = instance.CancelToken.source();
    const auth = {
      headers: { Authorization: 'Bearer ' + $cookies.get('token') }
    }
    return axios.post(URL.FARM, model, { cancelToken: src.token, ...auth })
      .then(result => result.data)
      .catch(err => { throw err })
  }

  getPolygonsXlsx(filter, signal) {
    if (!filter) return;

    const config = {
      headers: {},
      ...signal
    };

    // Temporarily remove the Authorization header
    let tempAuthHeader = null;
    if (axios.defaults.headers.common["Authorization"]) {
      tempAuthHeader = axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers.common["Authorization"];
    }

    return axios.get(URL.NOMINATIM_POLYGON + filter, config)
      .then((result) => {
        // Restore the Authorization header
        if (tempAuthHeader) {
          axios.defaults.headers.common["Authorization"] = tempAuthHeader;
        }

        if (!Array.isArray(result.data) || result.data.length === 0) {
          return null;
        }

        const polygonItem = result.data.find(item => item.geojson && (item.geojson.type === 'Polygon' || item.geojson.type === 'MultiPolygon'));
        const itemToReturn = polygonItem || result.data[0];
        if (itemToReturn.geojson === undefined) {
          // No suitable data found, create a Point geojson
          const lat = parseFloat(itemToReturn.lat);
          const lon = parseFloat(itemToReturn.lon);
          const pointGeojson = {
            type: 'Point',
            coordinates: [lon, lat]
          };

          const formattedData = {
            name: itemToReturn.name,
            type: 'Point',
            geojson: pointGeojson,
            //add more?
          };

          return formattedData;
        }
        console.log(itemToReturn)
        return itemToReturn;
      })
      .catch(err => {
        // Restore the Authorization header
        if (tempAuthHeader) {
          axios.defaults.headers.common["Authorization"] = tempAuthHeader;
        }
        throw err;
      });
  }

  getPointXlsx(filter, signal) {
    if (!filter) return;

    const config = {
      headers: {},
      ...signal
    };

    // Temporarily remove the Authorization header
    let tempAuthHeader = null;
    if (axios.defaults.headers.common["Authorization"]) {
      tempAuthHeader = axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers.common["Authorization"];
    }

    return axios.get(URL.NOMINATIM_POLYGON + filter, config)
      .then((result) => {
        // Restore the Authorization header
        if (tempAuthHeader) {
          axios.defaults.headers.common["Authorization"] = tempAuthHeader;
        }

        if (!Array.isArray(result.data) || result.data.length === 0) {
          return null;
        }


        return result.data[0];
      })
      .catch(err => {
        // Restore the Authorization header
        if (tempAuthHeader) {
          axios.defaults.headers.common["Authorization"] = tempAuthHeader;
        }
        throw err;
      });
  }

  //Survey
  getPolygons(filter) {
    if (!filter) return;

    const config = {
      headers: {}
    };

    // Temporarily remove the Authorization header
    let tempAuthHeader = null;
    if (axios.defaults.headers.common["Authorization"]) {
      tempAuthHeader = axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers.common["Authorization"];
    }

    return axios.get(URL.NOMINATIM_ADDRESS_DETAILS + filter, config)
      .then((result) => {
        // Restore the Authorization header
        if (tempAuthHeader) {
          axios.defaults.headers.common["Authorization"] = tempAuthHeader;
        }
        return result.data;
      })
      .catch(err => {
        // Restore the Authorization header
        if (tempAuthHeader) {
          axios.defaults.headers.common["Authorization"] = tempAuthHeader;
        }
        throw err;
      });
  }

  getGeoPolygons(filter) {
    if (!filter) return;

    const config = {
      headers: {}
    };

    // Temporarily remove the Authorization header
    let tempAuthHeader = null;
    if (axios.defaults.headers.common["Authorization"]) {
      tempAuthHeader = axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers.common["Authorization"];
    }

    return axios.get(URL.GEOCODE_POLYGON + filter, config)
      .then((result) => {
        // Restore the Authorization header
        if (tempAuthHeader) {
          axios.defaults.headers.common["Authorization"] = tempAuthHeader;
        }
        console.log(result.data);
        return result.data.features.map(obj => ({
          key: JSON.stringify(obj.place_id + Math.random()),
          title: obj.display_name,
          description: `${obj.properties.lat}, ${obj.lon}`,
          coordinates: fromLonLat([parseFloat(obj.lon), parseFloat(obj.lat)])
        }));
      })
      .catch(err => {
        // Restore the Authorization header
        if (tempAuthHeader) {
          axios.defaults.headers.common["Authorization"] = tempAuthHeader;
        }
        throw err;
      });
  }


  //PROFILE DASHBOARD
  deleteFarm(id) {
    const auth = {
      headers: { Authorization: 'Bearer ' + $cookies.get('token') }
    }
    return axios.delete(URL.FARM + id + '/', { ...auth })
      .then(result => result.data)
      .catch(err => { throw err })
  }

  updateFarm(id, model) {
    const auth = {
      headers: { Authorization: 'Bearer ' + $cookies.get('token') }
    }
    return axios.put(URL.FARM + id + '/', model, { ...auth })
      .then(result => result.data)
      .catch(err => { throw err })
  }

  updateFarmPhotos(id, image, option) {
    const auth = {
      headers: { Authorization: 'Bearer ' + $cookies.get('token') }
    }
    if (option === 1) {
      return axios.post(URL.FARM + id + '/photos/', image, { ...auth })
        .then(result => result.data)
        .catch(err => { throw err })
    }
    if (option === 2) {
      return axios.delete(URL.FARM + `${id}/photos/${image}`)
        .then(result => result.data)
        .catch(err => { throw err })
    }
  }

  getFarmCropCategories() {
    const instance = axios.create();
    instance.CancelToken = axios.CancelToken;
    let src = instance.CancelToken.source();
    src.cancel('cancel');
    src = instance.CancelToken.source();
    const auth = {
      headers: { Authorization: 'Bearer ' + $cookies.get('token') }
    }
    
    return axios.get(URL.CCATEGORY, { ...auth, cancelToken: src.token })
      .then(result => {
        return result.data
      })
      .catch(err => { throw err })

  }
}

export default new $data();