import React, { useState, useEffect, useContext, useRef } from 'react';
import 'ol/ol.css';
import { View, Map } from "ol";
import { fromLonLat, transform } from 'ol/proj';
import { defaults as defaultInteractions } from 'ol/interaction';
import _ from 'lodash';
import { AppContext } from '../../../services/$contextProvider';
import $map from '../../../services/$map';
import useMergeState from '../../../services/$mergeState';

// MapComponent
export const MapComponent = (props) => {
  const ref = useRef(null);
  const mapRef = useRef(null);
  const [state, setState] = useMergeState({ base: 'map', query: '', query_coordinates: false, zoom: 1 });
  let basemaps = null


  useEffect(() => {
    if (ref.current && !mapRef.current) {
      mapInit()
    }
  }, [ref, mapRef]);

  useEffect(() => {
    if (props.location !== null)
      handleLocationOptionsChange(props.location)
  }, [props.location]);

  const mapInit = () => {
    basemaps = $map.createBasemaps(state.base);
    mapRef.current = new Map({
      target: ref.current,
      interactions: defaultInteractions({ altShiftDragRotate: false, pinchRotate: false }),
      layers: _.concat(basemaps),
      view: new View({
        zoom: 5,
        center: fromLonLat([9.1733136, 55.1324482])
      })
    });
    mapRef.current.on('singleclick', onClickMap);
  }

  const handleLocationOptionsChange = (geojsonLocations) => {
    $map.removeLocationLayer(mapRef.current);

    if (geojsonLocations && geojsonLocations.geojson && geojsonLocations.geojson.coordinates) {
      const locationLayer = $map.createLocationLayer(geojsonLocations);
      mapRef.current.addLayer(locationLayer);

      const extent = locationLayer.getSource().getExtent();

      mapRef.current.getView().fit(extent, { duration: 1000 });
    }
  };

  const onClickMap = (event) => {
    $map.removeLocationLayer(mapRef.current);
    const pinLayer = $map.dropCoordinatesPin(event.coordinate)
    mapRef.current.addLayer(pinLayer);

    const lonLat = transform(event.coordinate, 'EPSG:3857', 'EPSG:4326');
    const lonLatString = lonLat[1].toFixed(6) + ', ' + lonLat[0].toFixed(6);
    props.handleMapClickInput(lonLatString, true)
  }


  return (
    <div className='map-container' id="map" ref={ref}></div>
  );
};

// OptionsRenderer
export const OptionsRenderer = (props) => {
  const { $t } = useContext(AppContext);

  const isActive = (option) => {
    try {
      if (props.location !== null) {
        if (option === props.location.place_id) {
          return true
        } else return false
      }
    } catch (e) {
      console.log(e)
    }
  }
  const getCoordinates = (type) => {
    const name = type === 'Point' ? 'coordinates' : 'poly'
    return name
  }

  return <div className='options-container'>
    {props.options && props.options.length > 0 ? props.options.map((option, index) => (
      <div key={index} onClick={() => props.onOptionSelect({ location: option, [getCoordinates(option.geojson.type)]: option.geojson.coordinates })} className={isActive(option.place_id) ? 'location-accordion active' : 'location-accordion'}>
        {option.display_name}
      </div>
    )) : <div className='no-data-container'>
        {$t.no_location_data}
      </div>}
  </div>
};